import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CLink,
	CTooltip,
	CInputGroup,
	CInputGroupText,
	CButton,
	CListGroup,
	CListGroupItem,
	CBadge,
	CFormTextarea,
	CImage,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu, 
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookupapi,
	MyDialoglookup,
	MyDialogform,
	MyPage500,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Kasirtokomain = (props) => {
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate			= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uRunningTime,setRunningTime]		= React.useState("");
	const [uTabelRowSelect,setTabelRowSelect]	= React.useState(-1);
	const [uIsDataUpdate,setDataUpdate]		= React.useState(false);

	const [uDatamainObj,setDatamainObj]		= React.useState({});
	//const uDataitemArr						= uDatamainObj.items_arr || [];
	const [uDataitemtambahObj,setDataitemtambahObj]	= React.useState({});
	const [uDataitemeditObj,setDataitemeditObj]		= React.useState({});//->{editidx:"0",editfield:""}
	const [uNonotaload,setNonotaload]		= React.useState("");

	//console.log("uDataitemArr => "+JSON.stringify(uDataitemArr));
	
	//---DIHAPUS_SAJA--/
	const [uIsShowRegisterform,setShowRegisterform]		= React.useState(false);
	const [uInpFormCustomernama,setInpFormCustomernama]	= React.useState("");
	const [uInpFormCustomertelp,setInpFormCustomertelp]	= React.useState("");
	//---END DIHAPUS_SAJA--/

	const [uDialogHeader,setDialogHeader] 		= React.useState(null);

	//--LOOKUP--/
	const [uIsDlglookupshow,setDlglookupshow] 	= React.useState(false);
	const [uInpDlglookupkwd,setInpDlglookupkwd]	= React.useState("");
	const [uDatalookupArr,setDatalookupArr]		= React.useState([]);
	const [uDlglookupjenis,setDlglookupjenis]	= React.useState("");

	const [uDatacustomerArr,setDatacustomerArr]	= React.useState([]);//-*/
	const [uDatabarangArr,setDatabarangArr]		= React.useState([]);//-*/
	const [uDatapaymentArr,setDatapaymentArr]	= React.useState([]);
	//--END LOOKUP--/

	//---DIALOG_SIMPANMAIN--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uIsFormupdate,setFormupdate]		= React.useState(false);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(true);
	//---END DIALOG_SIMPANMAIN--/

	const [uIsCalculate,setCalculate]		= React.useState(false);

	//--DLG_API--/
	const [uISDlglookupapishow,setDlglookupapishow]	= React.useState(false);
	const [uISDlglookupapitrigger,setDlglookupapitrigger]= React.useState(false);
	const [uDlglookupapiObj,setDlglookupapiObj]= React.useState({});
	//--END DLG_API--/

	//---CETAK--/
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const [uDataidentitas,setDataidentitas]	= React.useState({});
	const [uDatasetting,setDatasetting]		= React.useState({});
	const [uDataCetakObj,setDataCetakObj]	= React.useState(null);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	//---END CETAK--/

	//--DOM--/
	const [uElDlgsimpan,setElDlgsimpan] 	= React.useState(document.getElementById("btnDialogSimpan"));
	/*const uElCardcustomer					= document.getElementById("idcardcustomer");
	const uElCardsummary					= document.getElementById("idcardsummary");
	const uElCardpayment					= document.getElementById("idcardpayment");
	const uElCardcatatan					= document.getElementById("idcardcatatan");
	
	const uHeightcustomer 	= uElCardcustomer && (uElCardcustomer.offsetHeight);
	const uHeightsummary 	= uElCardsummary && (uElCardsummary.offsetHeight);
	const uHeightpayment 	= uElCardpayment && (uElCardpayment.offsetHeight);
	const uHeightcatatan 	= uElCardcatatan && (uElCardcatatan.offsetHeight);//-*/
	//--END DOM--/

	//---HANDEL--/
	const hdlToggleDlglookup=()=>{ setDlglookupshow(false); }
	const hdlToggleDlgform=()=>{ setDlgformshow(false); }
	const hdlKlikDlglookuppilih=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uDatalookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		let vElsFocus;
		if(uDlglookupjenis==="PAYMENT") {
			vElsFocus	= document.getElementById("inpbiayanontunai"); 
			uDatamainObj.id_payment_method 	= (uDatalookupArr[vIdx].id)
			uDatamainObj.nama_metode 		= (uDatalookupArr[vIdx].caption)
		} else {
			vElsFocus	= document.getElementById("btnItemtambahadd"); 
			uDataitemtambahObj.item_id 		= (uDatalookupArr[vIdx].id);
			uDataitemtambahObj.item_caption = (uDatalookupArr[vIdx].caption);
			uDataitemtambahObj.item_qty 	= (1);
			uDataitemtambahObj.item_harga 	= (uDatalookupArr[vIdx].harga_satuan);
			uDataitemtambahObj.item_satuan 	= (uDatalookupArr[vIdx].satuan);
		}
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikDlgLookupapipilih = (_SELECTEDSTR) => {
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		//if(_SELECTEDSTR==="" || _SELECTEDSTR === "{}") return;
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		uDatamainObj.id_customer 	= (vLookupObj.id||"0");
		uDatamainObj.nama_customer 	= (vLookupObj.caption||"");
		uDatamainObj.alamat_customer= (vLookupObj.alamat||"");
		uDatamainObj.telp_customer	= (vLookupObj.telp||"");
		uDatamainObj.sisa_dp		= (vLookupObj.sisa_dp||"0");

		setDlglookupapishow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElsFocus	= document.getElementById("inpcustomertelp");
			vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikDlgformsimpan=()=>{
		if(!uIsDlgformshow) return;
		if(!uIsFormupdate) return;

		let vElfocus 	
		//---VALIDASI--/
		const vPiutang = parseInt(uDatamainObj.nilai_piutang)||0;
		if(vPiutang > 0)
			if((uDatamainObj.nama_customer||"") === "") {
				showToast((uBahasaObj.pesan_piutangharuspilihcustomer||"pesan_piutangharuspilihcustomer"));
				return;
			}

		const vDataitemArr	= uDatamainObj.items_arr || [];
		if(vDataitemArr.length <= 0){
			showToast((uBahasaObj.pesan_itemstillempty||"pesan_itemstillempty"));
			return;
		}

		vElfocus 	= document.getElementById("inppaymentcaption");
		if((parseFloat(uDatamainObj.jml_bayar)||0) > 0 && (parseInt(uDatamainObj.id_payment_method)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast(
				"<b>Metode Pembayaran Harus DIPILIH terlebih Dahulu</b>.."+
				"<br/>Mohon Diperbaiki !"+
				"");return;	
		}
		//---END VALIDASI--/

		apiSimpanmain();
	}

	const hdlKlikBack=async()=>{
		const vProsesBack=()=>{
			localStorage.removeItem("kasirtoko");
			uNavigate("/copynota");
		}
		if(uIsFormupdate) {
			if(await(Konfirm(
				"<strong>"+(uBahasaObj.pesan_formisupdate||"pesan_formisupdate")+"..</strong>"+
				"<BR><BR>"+(uBahasaObj.confirm_kembali||"confirm_kembali")+
				""))) vProsesBack();

			return;
		}
		vProsesBack();
	}
	const hdlKlikBayar=()=>{
		if(!uIsFormupdate) return;
		if(uIsFormprocess) return;

		//---VALIDASI--/
		const vDataitemArr	= uDatamainObj.items_arr || [];
		let vElsFocus = document.getElementById("inpitemtambahcaption"); 
		if(vDataitemArr.length <= 0){
			vElsFocus && vElsFocus.focus();
			showToast((uBahasaObj.pesan_itemstillempty||"pesan_itemstillempty"));
			return;
		}
		/*vElsFocus = document.getElementById("inppaymentcaption"); 
		if(parseInt(uDatamainObj.id_payment_method||0) <= 0) {
			vElsFocus && vElsFocus.focus();
			showToast((uBahasaObj.caption_paymentmethod||"caption_paymentmethod")+" "+
				(uBahasaObj.caption_mustchoose||"caption_mustchoose")+" !"
				);
			return;
		}//-*/
		//---END VALIDASI--/

		/*if((uDatamainObj.nama_metode||"").toUpperCase()==="TUNAI") 
			uDatamainObj.ket_kartu = "";//-*/

		setDialogHeader((uBahasaObj.word_pembayaran||"word_pembayaran")+
			" "+(uBahasaObj.menus_kasirtoko||"menus_kasirtoko")+
			((uDatamainObj.nama_customer||"")!==""?": "+(uDatamainObj.nama_customer||"").toUpperCase():"")
			);
		setDlgformshow(true);
	}
	const hdlKlikCustomerBaru=()=>{
		uDatamainObj.isCustomernew = !(uDatamainObj.isCustomernew||false)

		if((uDatamainObj.isCustomernew||false)===true) {
			uDatamainObj.id_customer 	= undefined;
			uDatamainObj.nama_customer	= undefined;
			uDatamainObj.telp_customer 	= undefined;
			uDatamainObj.alamat_customer= undefined;
			uDatamainObj.sisa_dp 		= undefined;
		}
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			document.getElementById("inpcustomerbaru") &&
				document.getElementById("inpcustomerbaru").focus()
		},350)
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			if(uIsFormprocess) return;

			_EV.preventDefault();
			hdlKlikBayar();
		}
	}
	const hdlKlikReset=async()=>{
		if(!uIsFormupdate) {
			apiLoadinit();
			return;
		}

		const vKonfirm	=
			""+(uBahasaObj.petunjuk_resetform||"petunjuk_resetform")+".."+
			"<BR><BR>"+
			"<strong>"+(uBahasaObj.confirm_reset||"confirm_reset")+"</strong>"+
		"";
		if(await Konfirm(vKonfirm)) setMainreset();
	}
	const hdlKlikLookupCustomer=()=>{
		//console.log("(Kasirtokomain - hdlKlikLookupCustomer) uDatacustomerArr = "+JSON.stringify(uDatacustomerArr));
		uDlglookupapiObj.inputvalue = uDatamainObj.nama_customer||"";
		uDlglookupapiObj.headers = (uBahasaObj.word_lookup||"word_lookup")+": "+
			(uBahasaObj.word_customer||"word_customer");
		setDlglookupapishow(true);
	}
	const hdlKlikLookupItemtambah=()=>{
		const vTmpArr	= uDatabarangArr.map((vItems)=>{
			return {
				id:vItems.id,
				caption:vItems.caption,
				harga_satuan:vItems.harga_satuan,
			}
		})

		setDlglookupjenis("ITEM");
		setDatalookupArr(vTmpArr);
		setInpDlglookupkwd(uDataitemtambahObj.item_caption||"");
		setDialogHeader((uBahasaObj.word_lookup||"word_lookup")+": "+(uBahasaObj.word_barang||"word_barang"));
		setDlglookupshow(true);
	}
	const hdlKDownCustomer=(_EV)=>{
		if(_EV.keyCode !== 13) {
			uDatamainObj.id_customer 	= undefined;
			uDatamainObj.telp_customer 	= undefined;
			uDatamainObj.alamat_customer= undefined;
			uDatamainObj.sisa_dp 		= undefined;
			setEfekview(!uEfekview);
			return;
		}
		if((uDatamainObj.nama_customer||"").trim()==="") { 
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		setDlglookupapitrigger(true)
		hdlKlikLookupCustomer();
		return;
	}
	const hdlKDownItemtambah=(_EV)=>{
		setItemtambahreset();
		if(_EV.keyCode !== 13) { return; }
		if((uDataitemtambahObj.item_caption||"").trim()==="") {
			return;
		}
		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatabarangArr.filter((vItem)=>
			vItem.caption.toUpperCase().includes((uDataitemtambahObj.item_caption||"").toUpperCase()));
		//console.log("(Kasirtokomain - hdlKDownItemtambah) vArrCariMaster = "+JSON.stringify(vArrCariMaster));
		
		//console.log("(Kasirtokomain - hdlKDownItemtambah) vArrCariMaster = "+vArrCariMaster.length);
		if (vArrCariMaster.length === 1) {
			const vItemID	= vArrCariMaster[0].id || "0";
			const vItemNama	= vArrCariMaster[0].caption || "";
			const vItemStok	= parseInt(vArrCariMaster[0].stok) || 0;
			const vDataitemArr = uDatamainObj.items_arr || [];

			setItemtambahvalue("item_caption",vItemNama);

			if(vItemStok <= 0) {
				showToast((uBahasaObj.pesan_stoksisakurang||"pesan_stoksisakurang"));
				return;
			}

			//---CEK_ITEM_EXISTS--/
			let vIdx = UFunc.getObjectIndeks(vDataitemArr,vItemID,"id_item");
			if(vIdx >= 0) {
				showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
				return;
			}
			//---END CEK_ITEM_EXISTS--/

			const vItemQty		= 1;
			const vItemHarga	= vArrCariMaster[0].harga_satuan || "0";
			const vItemSatuan	= vArrCariMaster[0].satuan || "PCS";

			/*uDataitemtambahObj.item_id 		= vItemID
			uDataitemtambahObj.item_caption = vItemNama
			uDataitemtambahObj.item_qty 	= vItemQty;
			uDataitemtambahObj.item_harga 	= parseInt(vItemHarga)||0
			uDataitemtambahObj.item_satuan 	= vItemSatuan;
			//-*/

			const vItemObj	= {
				id_item : vItemID,
				nama 	: vItemNama,
				qty 	: vItemQty,
				satuan 	: vItemSatuan,
				hsatuan : parseInt(vItemHarga)||0
			}
			uDatamainObj.items_arr = [vItemObj,...vDataitemArr];

			setItemtambahvalue("item_caption","");
			//document.getElementById("btnItemtambahadd").focus();
		} else {
			hdlKlikLookupItemtambah();
		}
	}
	const hdlKlikPaymentlookup = () => {
		setDialogHeader((uBahasaObj.word_pilih||"word_pilih")+
			" "+(uBahasaObj.caption_paymentmethod||"caption_paymentmethod"))

		const vTmpArr	= uDatapaymentArr.map((vItems)=>{
			return {id:vItems.id,caption:vItems.caption}
		})
		setDlglookupjenis("PAYMENT")
		setDatalookupArr(vTmpArr);
		uDatamainObj.id_payment_method 	= 0;
		uDatamainObj.nama_metode 		= "";
		setDlglookupshow(true)
	}
	const hdlKlikItemTambah=()=>{
		//console.log("(Kasirtokomain - hdlKlikItemTambah) uDataitemtambahObj.item_id = "+uDataitemtambahObj.item_id);
		if((parseInt(uDataitemtambahObj.item_id)||0) <= 0) return;
		if((parseInt(uDataitemtambahObj.item_qty)||0) <= 0) return;

		//---CEK_ITEM_EXISTS--/
		const vDataitemArr = uDatamainObj.items_arr || [];
		let vIdx = UFunc.getObjectIndeks(vDataitemArr,(uDataitemtambahObj.item_id||"0"),"id_item");
		if(vIdx >= 0) {
			showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
			return;
		}
		//---END CEK_ITEM_EXISTS--/
		
		vIdx = UFunc.getObjectIndeks(uDatabarangArr,(uDataitemtambahObj.item_id||"0"),"id");
		const vItemStok	= parseInt(uDatabarangArr[vIdx].stok) || 0;
		if(vItemStok <= 0) {
			showToast((uBahasaObj.pesan_stoksisakurang||"pesan_stoksisakurang"));
			return;
		}

		const vItemObj	= {
			id_item : uDataitemtambahObj.item_id||"0",
			nama 	: uDataitemtambahObj.item_caption||"",
			qty 	: parseInt(uDataitemtambahObj.item_qty)||0,
			satuan 	: uDataitemtambahObj.item_satuan||"PCS",
			hsatuan : parseInt(uDataitemtambahObj.item_harga)||0
		}
		uDatamainObj.items_arr = [vItemObj,...vDataitemArr];
		setDataitemtambahObj({})
		setEfekview(!uEfekview)

		document.getElementById("inpitemtambahcaption").focus();
	}
	const hdlKlikItemTambahmobile = () => {}
	const hdlKlikItemtabelhapus=async(_IDXKEY)=>{
		_IDXKEY = parseInt(_IDXKEY); if(_IDXKEY < 0) return;
		//console.log("(Kasirtokomain - hdlKlikItemtabelhapus) _IDXKEY =>"+_IDXKEY);

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDXKEY);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusitem||"caption_hapusitem")+
			": <B>"+((uDatamainObj.items_arr||[])[_IDXKEY].nama||"").toUpperCase()+"</B>"+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) setItemtabelhapus(_IDXKEY);
	}
	const hdlKlikItemtabeleditmobile = (_IDXKEY,_FIELDEDIT) => {}
	const hdlKlikItemtabeledit=(_IDXKEY,_FIELDEDIT)=>{
		_IDXKEY = parseInt(_IDXKEY); if(_IDXKEY < 0) return;

		setDataitemtambahObj({});

		const vIdx 		= _IDXKEY;
		const vDataitemArr = uDatamainObj.items_arr || [];
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDXKEY);
		setTabelRowSelect(vRowIdx);

		let vElementID
		let vValue
		if(_FIELDEDIT.toUpperCase()==="QTY") {
			vElementID = "inptabeleditqty";
			vValue = (vDataitemArr[vIdx].qty||1);
		} else  {
			vElementID = "inptabeledithsatuan";
			vValue = (vDataitemArr[vIdx].hsatuan||0);
		}
		setDataitemeditObj({
			editidx:_IDXKEY,
			editfield:_FIELDEDIT,
			value: vValue,
		});
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(function(e) {
			clearTimeout(vTimeout);
			document.getElementById(vElementID) &&
				document.getElementById(vElementID).focus();
		},350);
	}
	const hdlKlikItemtabeleditProses=(_IDXKEY,_FIELDEDIT)=>{
		_IDXKEY = parseInt(_IDXKEY); if(_IDXKEY < 0) { setItemeditreset(); return; }
		const vDataitemArr = uDatamainObj.items_arr || [];

		//--PROSES_EDITNYA--/
		const vIdx 		= _IDXKEY;
		const vValue 	= parseInt(uDataitemeditObj.value) || 0;
		if(_FIELDEDIT.toUpperCase()==="QTY") {
			if(vValue <= 0) {
				showToast((uBahasaObj.word_qty||"word_qty")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			vDataitemArr[vIdx].qty 	= vValue;
		} else {
			if(vValue < 0) {
				showToast((uBahasaObj.caption_hargasatuan)+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			vDataitemArr[vIdx].hsatuan 	= vValue;
		}
		//--END PROSES_EDITNYA--/
		setItemeditreset();
	}
	const hdlKDownItemEdit=(_EV,_IDXKEY,_FIELDEDIT)=>{
		if(_EV.which !== 27 && _EV.which !== 13) return;

		_EV.preventDefault();
		if(_EV.which === 27) setItemeditreset();
		else if(_EV.which === 13) hdlKlikItemtabeleditProses(_IDXKEY,_FIELDEDIT);
	}
	//---END HANDEL--/

	//--API--/
	const apiLoadinit = () => {
		uFuncname = apiLoadinit.name;
		setHTMLError500();

		setLoading(true);
		setIsFormprocess(true);

		/*//--TESTING_FRONTEND--/
		console.log("apiLoadinit = LEWAT");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirtoko/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Kasirtokomain - apiLoadinit) output_string : "+JSON.stringify(output_string));
			if(output_string.tampil) {
				setIsFormprocess(false);
				if(output_string.newnota) {
					setNonotaload(output_string.newnota);
					setMainvalue("no_nota",output_string.newnota);
				}

				//setDatacustomerArr(JSON.parse(output_string.customerobject||"[]"));
				setDatabarangArr(JSON.parse(output_string.itemobject||"[]"));
				setDatapaymentArr(JSON.parse(output_string.paymentlist||"[]"));
				setDataidentitas(JSON.parse(output_string.dataidentitas||"[]"));
				setDatasetting(JSON.parse(output_string.datasetting||"[]"));
			} else if(output_string.info) {
				setIsFormprocess(false);
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirtokomain - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kasirtokomain - apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain = () => {
		uFuncname = apiSimpanmain.name;

		const vElDlgsimpan	= uElDlgsimpan
		vElDlgsimpan && (vElDlgsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--//
		return;
		//--END TESTING_FRONTEND--//*/

		const vDataSendStr	= JSON.stringify(uDatamainObj);
		const vDATAS		= JSON.stringify({
			send_datalist : vDataSendStr,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirtoko/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElDlgsimpan && (vElDlgsimpan.disabled = false)
			setLoading(false);

			if(output_string.sukses) {
				if((uDatamainObj.isCustomernew||false)===true) {
					const vCustomerid= output_string.newcustomerid||"0";
					const vTmpObj = {
						id: vCustomerid,
						caption: UFunc.capitalize(uDatamainObj.nama_customer||"UNDF")+" - "+
							UFunc.leadZero(vCustomerid,4)+
							"",
						telp: uDatamainObj.telp_customer||"",
						alamat: uDatamainObj.alamat_customer||"",
						sisa_dp: 0,
					}
					setDatacustomerArr([...uDatacustomerArr,vTmpObj]);
				}

				setNonotaload(output_string.newnota||uNonotaload);
				hdlToggleDlgform();
				if(output_string.datanota)
					setDataCetakObj(JSON.parse(output_string.datanota));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirtokomain --"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				hdlToggleDlgform();
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			hdlToggleDlgform();
			vElDlgsimpan && (vElDlgsimpan.disabled = false)
			setLoading(false);

			console.log("(Kasirtokomain --"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	//--PROSESFUNCTION--/
	const setMainreset=()=>{
		setDatamainObj({});
		setDataitemtambahObj({});
		setDataitemeditObj({});
		setDataCetakObj(null);
		setEfekview(!uEfekview)
	}
	const setItemtambahreset=()=>{
		uDataitemtambahObj.item_id		= undefined;
		uDataitemtambahObj.item_qty		= undefined;
		uDataitemtambahObj.item_satuan	= undefined;
		uDataitemtambahObj.item_harga	= undefined;
		setEfekview(!uEfekview);
	}
	const setItemtabelhapus=(_IDXKEY)=>{
		_IDXKEY = parseInt(_IDXKEY);if(_IDXKEY < 0) return;

		const vObjdx	= _IDXKEY
		if(vObjdx >= 0) (uDatamainObj.items_arr||[]).splice(vObjdx,1);
		setTabelRowSelect(-1);
	}
	const setItemeditreset=()=>{
		setDataitemeditObj({}); 
		setEfekview(!uEfekview) 
	}
	const setMainvalue = (_NAMAFIELD,_VALUE) => {
		uDatamainObj[_NAMAFIELD] = _VALUE; setEfekview(!uEfekview);
	}
	const setItemtambahvalue = (_NAMAFIELD,_VALUE) => {
		uDataitemtambahObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const prosesCetakNota=()=>{
		var vContent 	= initCetakNota();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setMainreset();
		setDataCetakObj(null);
	}
	//--END PROSESFUNCTION--/

	//---INIT--/
	const contentMain=()=>{
		if(uHTMLError500) return ""

		return( 
		<>
		<CForm>
		<CRow className="align-items-stretch mb-md-3">
		<CCol md="6" className="mb-3 mb-md-0">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 d-flex justify-content-between classfontsmaller">
				<small className="text-muted">{uBahasaObj.word_customer}</small>
				<small className="classpetunjuk">*) Jika Tidak HUTANG, boleh DIKOSONGI</small>
			</CCardHeader>

			<CCardBody className="py-1">{contentCustomer()}</CCardBody>
			</CCard>
		</CCol>

		<CCol className="d-none d-md-block">
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller">
				<small className="text-muted">{uBahasaObj.word_total}</small>
			</CCardHeader>

			<CCardBody className="py-1">{contentSummary()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CCard className="classcardbg mb-3" id="idcardtabel">
			<CCardHeader className="py-1 d-flex justify-content-between align-items-center align-items-md-end classfontsmaller">
				<div className="d-none d-md-flex w-75">{contentItemtambah()}</div>
				<CLink className="d-md-none classcursorpointer classikon classikontambah"/>
				<small className="text-muted">{uBahasaObj.caption_itembarang}</small>
			</CCardHeader>
			
			<CCardBody className="px-0">{contentItemtabel()}</CCardBody>
		</CCard>

		<CRow className="align-items-stretch mb-md-3">
		<CCol md="6" className="">
			<CCard className="classcardbg h-100 d-none">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				<small className="">{uBahasaObj.caption_paymentmethod}</small>
			</CCardHeader>
			<CCardBody className="py-1">{contentPayment()}</CCardBody>
			</CCard>
		</CCol>

		<CCol>
			<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
				<small>{uBahasaObj.caption_catcustomer}</small>
			</CCardHeader>
			<CCardBody className="py-0">{contentCatcustomer()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</CForm>
		</>
		)//>
	}
	const contentCustomer = () => {
		if(uHTMLError500) return ""

		return (
		<>
		<CRow className="my-2">
		<CCol xs="4" className="classfontsmaller">
			{(uDatamainObj.isCustomernew||false)===false ? (
				<>{uBahasaObj.word_customer}</>
			):(
				<b className="text-danger">{uBahasaObj.word_customer} BARU</b>
			)}
		</CCol>
		<CCol className="text-end">
			{(uDatamainObj.isCustomernew||false)===false ? (
			<CInputGroup size="sm">
				<CFormInput size="sm"
					placeholder={"--Keyword CUSTOMER Lama.."}
					value={uDatamainObj.nama_customer||""}
					onKeyDown={(e)=>hdlKDownCustomer(e)}
					onChange={(e)=>{setMainvalue("nama_customer",e.target.value);uDatamainObj.id_customer = ("0")}}
					name="inpcustomercaption" id="inpcustomercaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikLookupCustomer()} 
						id="btncustomerlookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					<CTooltip content={(uBahasaObj.word_customer||"word_customer")+(" BARU").toUpperCase()}>
					<CButton size="sm" color="dark"
						className="border-start border-start-light"
						onClick={()=>hdlKlikCustomerBaru()} 
						id="btncustomerbaru">
						<CIcon icon="cibAddthis"/>
					</CButton>
					</CTooltip>
			</CInputGroup>
			) : (
			<CInputGroup size="sm">
				<CFormInput size="sm" maxLength={100}
					placeholder={"--Customer BARU.."}
					value={uDatamainObj.nama_customer||""}
					onChange={(e)=>{setMainvalue("nama_customer",e.target.value);}}
					name="inpcustomerbaru" id="inpcustomerbaru"/>
					<CInputGroupText className="py-0 bg-danger">
					<CTooltip content={((uBahasaObj.word_customer||"word_customer")+" LAMA")}>
					<CLink onClick={()=>hdlKlikCustomerBaru()} className="classcursorpointer">
						<CIcon icon="cilTouchApp" className="text-white"/>
					</CLink>
					</CTooltip>
					</CInputGroupText>
			</CInputGroup>
			)}
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="classfontsmaller">{uBahasaObj.caption_telphp}</CCol>
		<CCol>
			<CFormInput size="sm" maxLength={50}
				value={uDatamainObj.telp_customer||""}
				onChange={(e)=>{setMainvalue("telp_customer",e.target.value);}}
				id="inpcustomertelp"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="classfontsmaller">{uBahasaObj.word_alamat}</CCol>
		<CCol>
			<CFormTextarea size="xs" rows={2}
				value={uDatamainObj.alamat_customer||""}
				onChange={(e)=>{setMainvalue("alamat_customer",e.target.value);}}
				id="inpcustomeralamat"/>
		</CCol>
		</CRow>

		<div className="my-3 d-md-none classborderbottom"/>
		<CRow className="my-2 d-md-none">
		<CCol xs="4" className="classfontsmaller">{uBahasaObj.word_nonota||"No.Nota"}</CCol>
		<CCol><b>{uNonotaload}</b></CCol>
		</CRow>
		</>
		)//>
	}
	const contentSummary = () => {
		if(uHTMLError500) return ""

		const vTotalnilai	= uDatamainObj.nilai_bruto || 0;

		return (
		<>
		<CRow className="my-2">
		<CCol md="4" className="classfontsmaller">{uBahasaObj.word_nonota}</CCol>
		<CCol className="text-end"><b>{uDatamainObj.no_nota||""}</b></CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4">&nbsp;</CCol>
		<CCol className="text-end">
			<small className="me-1">Rp</small>
			<big style={{fontSize:"200%"}}
				className="align-top fw-bolder text-primary">
				<big>{UFunc.formatAngka(vTotalnilai)}</big>
			</big>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentItemtambah = () => {
		if(uHTMLError500) return ""

		return (
		<>
		<div className="px-1">
		<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder={"--Keyword Nama/Kode Barang.."}
				value={uDataitemtambahObj.item_caption||""}
				onKeyDown={(e)=>hdlKDownItemtambah(e)}
				onChange={(e)=>setItemtambahvalue("item_caption",e.target.value)}
				style={{minWidth:350,maxWidth:"auto",width:"50% !important"}}
				id="inpitemtambahcaption"/>
			<CInputGroupText className="p-0 bg-dark">
			<CTooltip content="--Tambah Item Barang..">
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikLookupItemtambah()}
				id="btnitemtambahlookup"> 
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CTooltip>
			</CInputGroupText>
		</CInputGroup>
		</div>

		<div className="px-1">
			<CButton size="sm" color="info" 
				onClick={()=>hdlKlikItemTambah()}
				id="btnItemtambahadd">
				<CIcon icon="cibAddthis" /> 
			</CButton>
		</div>
		</>
		)//>
	}
	const contentItemtabel = () => {
		if(uHTMLError500) return ""

		const vItemsArr	= uDatamainObj.items_arr || [];

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead className="text-center">
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width={35} className="px-1"><small>No</small></th>
			<th className="px-1">
			<small>
			<CRow className="mx-0">
				<CCol md="5" lg="6" className="text-start">
					{uBahasaObj.caption_namabarang||"caption_namabarang"}
				</CCol>
				<CCol md="1" className="">&middot;</CCol>
				<CCol md="2" lg="1" className="">{uBahasaObj.word_qty||"QTY"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_hargasatuan||"caption_hargasatuan"} (Rp)</CCol>
				<CCol md="2" className="">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
			</CRow>
			</small>
			</th>
			</tr>

			<tr style={{maxHeight:2}} className="d-md-none align-top text-center classfontsmaller">
			<th width={35} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vItemsArr.map((vItems,vKeys)=>{
			const {
				id_item,nama,qty,hsatuan,satuan
			} = vItems;

			const vCaptionUpper	= (nama||"").toUpperCase();
			const vEditId		= uDataitemeditObj.editidx || "-1";
			const vEditField	= uDataitemeditObj.editfield || "";

			return (
			<tr id={"idtr"+vKeys} 
				className={"align-top text-end"+(uTabelRowSelect===(vKeys)?" classrowselect":"")}
				key={vKeys}>
			<td className="px-1">{vKeys+1}.</td>
			<td className="px-1">
				<CRow className="mx-0">
					<CCol xs="12" md="5" lg="6" className="px-1 text-start d-flex justify-content-between align-items-start">
						<div className="text-info">{(nama||"")}</div>

						<CDropdown className="d-md-none">
							<CDropdownToggle caret={false} color="transparent" className="p-0">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
							</CDropdownToggle>

							<CDropdownMenu className="py-0">
							<CDropdownItem>
								<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>
								Edit Item
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikItemtabelhapus(vKeys)}>
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								Hapus Item
							</CDropdownItem>
							</CDropdownMenu>
						</CDropdown>
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol md="1" className="px-1 d-none d-md-block text-md-center text-info">
					<CTooltip content={(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": "+vCaptionUpper}>
						<CLink 
							onClick={()=>hdlKlikItemtabelhapus(vKeys)}
							className="classcursorpointer classikontabel classikonhapus"/>
					</CTooltip>
					</CCol>

					<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_qty||"Qty"}</CCol>
					<CCol xs="7" md="2" lg="1" className="px-1 px-md-0 text-md-center">
						{(parseInt(vKeys)===parseInt(uDataitemeditObj.editidx) && uDataitemeditObj.editfield==="QTY") ? (
						<CInputGroup size="sm">
						<CFormInput size="sm"
							type="number"
							min="1"
							className="text-end"
							onFocus={(e)=>e.target.select()}
							value={uDataitemeditObj.value || 0}
							onKeyDown={(e)=>hdlKDownItemEdit(e,vKeys,"QTY")}
							onChange={(e)=>{ uDataitemeditObj.value = (e.target.value); setEfekview(!uEfekview)}}
							id="inptabeleditqty"/>
							<CInputGroupText className="p-0 bg-dark">
								<CButton onClick={()=>hdlKlikItemtabeleditProses(vKeys,"QTY")} 
									size="sm"
									color="dark"
									id="btncheckqty">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroupText>
						</CInputGroup>
						) : (
						<b>
						<CLink id="idtditemqtyview"
							onClick={()=>hdlKlikItemtabeledit(vKeys,"QTY")} 
							className="d-none d-md-inline text-dark text-decoration-none classcursorpointer">
							{UFunc.formatAngka(qty||0)} {satuan||""}
						</CLink>
						
						<span className="d-md-none">
							{UFunc.formatAngka(qty||0)} {satuan||""}
						</span>
						</b>
						)}
					</CCol>

					<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_hargasatuan||"H.Satuan"} (Rp)</CCol>
					<CCol xs="7" md="2" className="px-1 text-success">
						{(parseInt(vKeys)===parseInt(uDataitemeditObj.editidx) && uDataitemeditObj.editfield==="HARGA") ? (
						<CInputGroup size="sm">
							<NumberFormat 
								value={uDataitemeditObj.value||"0"}
								displayType={"text"} 
								thousandSeparator={"."} 
								decimalSeparator={","}
								allowNegative={false}
								onValueChange={(values) => uDataitemeditObj.value=(values.value)}
								renderText={values => (
									<CFormInput
										value={values}
										onChange={(e)=>{uDataitemeditObj.value=(e.target.value);setEfekview(!uEfekview);}}
										onKeyDown={(e)=>hdlKDownItemEdit(e,vKeys,"HARGA")}
										size="sm"
										className="text-end"
										onFocus={(e)=>e.target.select()}
									id="inptabeledithsatuan"/>
								)}
								prefix={""}/>
								<CButton onClick={()=>hdlKlikItemtabeleditProses(vKeys,"HARGA")} 
									size="sm"
									color="dark"
									id="btncheckharga">
								<CIcon icon="cilCheckAlt"/>
								</CButton>
						</CInputGroup>
						) : (
						<b>
						<CLink 
							className="text-success d-none d-md-inline text-decoration-none classcursorpointer"
							onClick={()=>hdlKlikItemtabeledit(vKeys,"HARGA")}>
							{UFunc.formatAngka(hsatuan||0)}
						</CLink>
						
						<span className="d-md-none">
							{UFunc.formatAngka(hsatuan||0)}
						</span>
						</b>
						)}
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_total||"Total"} (Rp)</CCol>
					<CCol xs="7" md="2" className="px-1 text-primary">
					<b>
					{UFunc.formatAngka((parseInt(hsatuan)||0) * (parseInt(qty)||0))}
					</b>
					</CCol>
				</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentPayment = () => {
		if(uHTMLError500) return ""
		
		return (
		<>
		<CRow className="my-2">
		<CCol xs="4" className="classfontsmaller"><small>{uBahasaObj.caption_paymentmethod}</small></CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder={"--"+(uBahasaObj.caption_silahkanpilih||"caption_silahkanpilih")+".."}
					className="text-uppercase fw-bolder"
					value={uDatamainObj.nama_metode ||""}
					onClick={()=>hdlKlikPaymentlookup()}
					id="inppaymentcaptionx"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikPaymentlookup()} 
						id="btnpaymentlookup">
						<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		<CRow className="my-2">
		<CCol className="text-end">
			<CFormInput size="sm" 
				maxLength={255}
				className="text-capitalize"
				value={uDatamainObj.ket_kartu||""}
				onChange={(e)=>{setMainvalue("ket_kartu",e.target.value);setEfekview(!uEfekview)}}
				placeholder={"--"+(uBahasaObj.caption_ketnontunai||"caption_ketnontunai")+".."}
				id="inppaymentketx"/>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentCatcustomer = () => {
		if(uHTMLError500) return ""
		
		return (
		<>
		<CRow className="my-2">
		<CCol className="text-end">
			<CFormTextarea size="sm"
				rows={3}
				value={uDatamainObj.catatan_pasien||""}
				onChange={(e)=>{setMainvalue("catatan_pasien",e.target.value);setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnBayarmain")}
				id="inpnotacatatan"/>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contenDlgform = () => {
		if (!uIsDlgformshow) return (<></>)//>

		return (
		<CForm>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3 mb-lg-0">
		<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted"><small>Summary</small></CCardHeader>
			<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_jmlbruto||"caption_jmlbruto"}</small>
				<div className="text-end w-50">
				<CFormInput 
					value={"Rp"+UFunc.formatAngka(uDatamainObj.nilai_bruto)}
					size="sm"
					readOnly={true}
					className="text-end fw-bolder"
				id="inpmainjmlbruto"/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>
				{uBahasaObj.caption_dpdipakai||"DP Dipakai"}
				<span className="ms-1 text-primary">(Rp{UFunc.formatAngka(uDatamainObj.sisa_dp)})</span>
				</small>
			
				<div className="w-50 text-end">
				<CFormInput 
					value={"Rp"+UFunc.formatAngka(uDatamainObj.dp_awal)}
					size="sm"
					readOnly={true}
					className="text-end text-danger"
				id="inpmainpakaidp"/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.word_diskon||"word_diskon"}</small>
				<div className="w-50 text-end">
				<NumberFormat 
					value={uDatamainObj.diskon||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => setMainvalue("diskon",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end text-danger"
							onChange={(e)=>setMainvalue("diskon",e.target.value)}
							onFocus={(e)=>e.target.select()}
						id="inpmaindiskon"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				</div>
			</div>

			<div className="my-3 classborderbottom"/>
	
			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_paymentmethod||"caption_paymentmethod"}</small>
				<div className="text-end text-primary text-uppercase w-50">
				<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uDatamainObj.nama_metode}
						placeholder="--Pilih Metode"
						onClick={()=>hdlKlikPaymentlookup()}
						id="inppaymentcaption"/>
					<CInputGroupText className="bg-dark">
						<CLink onClick={()=>hdlKlikPaymentlookup()}
							className="classcursorpointer text-white">
						<CIcon icon="cilSearch"/>
						</CLink>
					</CInputGroupText>
				</CInputGroup>
				<b className="d-none">{uDatamainObj.nama_metode}</b>
				</div>
			</div>
			
			{((uDatamainObj.nama_metode||"TUNAI").toUpperCase() !== "TUNAI") && (
			<>
				<div className="d-flex justify-content-between my-1">
				<small>B.Non Tunai (Trf)</small>
				<div className="w-50 text-end">
				<NumberFormat 
					value={uDatamainObj.b_kartu||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => setMainvalue("b_kartu",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setMainvalue("b_kartu",e.target.value)}
							onFocus={(e)=>e.target.select()}
						id="inpbiayanontunai"/>
					)} 
				prefix={isMobile ? "" : "Rp"}/>
				</div>
				</div>

				<div className="my-2" style={{borderBottom:"1px dashed #dcdcdc"}}/>
				
				<div className="my-1">
					<small className="d-block fst-italic">{uBahasaObj.caption_ketpayment||"Ket.Pembayaran"}</small>
					<CFormInput size="sm"
						value={uDatamainObj.ket_kartu||""}
						onChange={(e)=>{uDatamainObj.ket_kartu=e.target.value;setEfekview(!uEfekview)}}
						id="inppaymentket"/>
				</div>
			</>
			)}
			</CCardBody>
			</CCard>
		</CCol>

		<CCol>
		<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller text-muted"><small>Pembayaran</small></CCardHeader>
			<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_totalnetto||"caption_nilainetto"}</small>

				<div className="text-end w-50" style={{}}>
				<CFormInput 
					value={"Rp"+UFunc.formatAngka(uDatamainObj.nilai_netto||"0")}
					size="sm"
					readOnly={true}
					className="text-end text-primary fw-bolder"
				id="inpmainnetto"/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_jmlbayar||"caption_jmlbayar"}</small>
				<div className="text-end w-50" style={{}}>
				<NumberFormat 
					value={uDatamainObj.jml_bayar||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => setMainvalue("jml_bayar",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setMainvalue("jml_bayar",e.target.value)}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e) => {
								if(parseInt(uDatamainObj.nilai_piutang||"0") <= 0)
									UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
							}}
						id="inpmainjmlbayar"/>
					)} 
				prefix={isMobile ? "" : "Rp"}/>
				</div>
			</div>

			<div className="my-3 classborderbottom"/>

			<div className="d-flex justify-content-between my-1">
				<big className={"fw-bolder "+((parseInt(uDatamainObj.nilai_piutang||"0") > 0) ? "text-danger" : "text-success")}>
					{(parseInt(uDatamainObj.nilai_piutang)||0) > 0 ? (uBahasaObj.caption_sisapiutang||"caption_sisapiutang")
					: (uBahasaObj.caption_kembalian||"caption_kembalian") }
				</big>

				<div className="text-end" style={{}}>
				<big className={"fw-bolder "+((parseInt(uDatamainObj.nilai_piutang||"0") > 0) ? "text-danger" : "text-success")}>
				Rp{UFunc.formatAngka(Math.abs(uDatamainObj.nilai_piutang||"0"))}
				</big>
				</div>
			</div>

			<div className="d-flex justify-content-between mt-2">
				<small>{uBahasaObj.caption_tgljt||"caption_tgljt"}</small>

				<div className="text-end w-50" style={{}}>
				{parseInt(uDatamainObj.nilai_piutang||"0") > 0 ? (
					<CFormInput size="sm" type="date"
						value={uDatamainObj.tgl_jt || ""}
						onChange={(e)=>setMainvalue("tgl_jt",e.target.value)}
						onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpmaintgljt"/>
				) : (
					<big className="fw-bolder">
					<CIcon icon="cilInfinity" height={25}/>
					</big>
				)}
				</div>
			</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const initCetakNota=()=>{
		//console.log("uDataCetakObj = "+JSON.stringify(uDataCetakObj||{}));
		if(uDataCetakObj === null) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uDatasetting.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= uDatasetting.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDataCetakObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uDataidentitas.telp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				//(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uDataidentitas.telp || "")+
			`</td>
			</tr>
			`;
		//---END TELP--/

		//--TAGLINE--/
		const vTagline	=  uDataidentitas.slogan !== "" 
			? `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				UFunc.capitalize(uDataidentitas.slogan || "")+
			`</td>
			</tr>
			` : ``;
		//--END TAGLINE--/

		const vFooternota = (uDatasetting.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><HR></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uDatasetting.footer_nota||"")+`</td>
			</tr>
			` : ``;
		
		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uDataidentitas.nama_perusahaan || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uDataidentitas.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_FOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,vTagline);

		return vHTMLs;
	}
	//--END INIT--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	/*React.useEffect(()=>{
		if(!uHeightcustomer || !uHeightsummary) return;

		if(uHeightcustomer > uHeightsummary)
			uElCardsummary && (uElCardsummary.style.height = uHeightcustomer+"px");
		else if(uHeightcustomer < uHeightsummary)
			uHeightcustomer && (uHeightcustomer.style.height = uHeightsummary+"px");
	},[uHeightcustomer,uHeightsummary]);
	React.useEffect(()=>{
		console.log("uHeightpayment = "+uHeightpayment);
		console.log("uHeightcatatan = "+uHeightcatatan);
		if(!uHeightpayment || !uHeightcatatan) return;

		if(uHeightpayment > uHeightcatatan)
			uElCardcatatan && (uElCardcatatan.style.height = uHeightpayment+"px");
		else if(uHeightpayment < uHeightcatatan)
			uElCardpayment && (uElCardpayment.style.height = uHeightcatatan+"px");
	},[uHeightcatatan,uHeightpayment]);//-*/
	React.useEffect(()=>{
		if(!uIsEOMExecuted) {
			hdlKlikBack();
			return;
		}
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		let vTimerrun = setInterval(() => {
			const vDates = new Date();

			const vTimestr	= UFunc.leadZero(vDates.getHours())+":"+
				UFunc.leadZero(vDates.getMinutes())+":"+
				UFunc.leadZero(vDates.getSeconds())+
			"";

			setRunningTime(vTimestr);
		}, 1000);
		
		apiLoadinit();

		return () => {
			setDlgformshow(false)
			clearInterval(vTimerrun); // Return a funtion to clear the timer so that it will stop being called on unmount
			setDatacustomerArr([]);
			setDatabarangArr([]);
			setDatapaymentArr([]);
			setDatalookupArr([]);
			setDatasetting({})
			setDataidentitas({})

			//setDatamainObj({});
			setDataitemtambahObj({})
			setDataitemeditObj({})
		}
	},[]);
	React.useEffect(()=>{
		if(!uIsDlgformshow) {
			document.getElementById("btnBayarmain").disabled = false;
			return;
		}
		document.getElementById("btnBayarmain").disabled = true;
		//uDatamainObj.jml_bayar = (uDatamainObj.nilai_netto);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setElDlgsimpan(document.getElementById("btnDialogSimpan"))

			const vElfocus = document.getElementById("inpmainjmlbayar");
			if(!isMobile)
				vElfocus && vElfocus.focus();
		},350);
	},[uIsDlgformshow]);
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setDatalookupArr([]);
			setInpDlglookupkwd("");

			if(uDlglookupjenis==="ITEM") {
				if(parseInt((uDatamainObj.items_arr||[]).item_id||"0") <= 0) {
					document.getElementById("inpitemtambahcaption").focus()
				}
			} else if(uDlglookupjenis==="CUSTOMER") {
				if(parseInt(uDatamainObj.id_customer||"0") <= 0) {
					document.getElementById("inpcustomercaption").focus()
				}
			}
			return;
		}
	},[uIsDlglookupshow]);
	React.useEffect(()=>{
		if(!uISDlglookupapishow) 
			{ setDlglookupapitrigger(false); return; }
	},[uISDlglookupapishow]);

	React.useEffect(()=>{
		if(uDatamainObj.tgl_bayar === undefined) 
			uDatamainObj.no_nota 	= (UFunc.DbDate());
		if(uDatamainObj.tgl_bayar === undefined) 
			uDatamainObj.no_nota 	= uNonotaload; 
		if((parseInt(uDatamainObj.id_customer)||0) <= 0) 
			uDatamainObj.dp_awal = 0;
	},[uDatamainObj,
	uDatamainObj.tgl_bayar,
	uDatamainObj.no_nota,
	uDatamainObj.id_customer
	]);
	React.useEffect(()=>{
		const vNilaiBruto	= (uDatamainObj.items_arr||[]).reduce((a,b)=>{
			return a + ( parseFloat(b.qty || 0) * parseFloat(b.hsatuan || 0) )
		},0);		

		uDatamainObj.nilai_bruto = vNilaiBruto;
		setEfekview(!uEfekview);
	},[uDatamainObj,
	(uDatamainObj.items_arr||[]).length,
	uDataitemeditObj]);
	React.useEffect(()=>{ uDatamainObj.dp_awal = (uDatamainObj.sisa_dp); },[uDatamainObj.sisa_dp]);
	React.useEffect(()=>{
		if((uDatamainObj.nama_metode||"TUNAI") === "TUNAI") {
			uDatamainObj.b_kartu 	= undefined;
			uDatamainObj.ket_kartu 	= undefined;
			setEfekview(!uEfekview);
			return;
		} 
	},[uDatamainObj,uDatamainObj.nama_metode]);
	React.useEffect(()=>{
		if((parseInt(uDatamainObj.dp_awal)||0) > (parseInt(uDatamainObj.sisa_dp)||0)) {
			uDatamainObj.dp_awal = uDatamainObj.sisa_dp; 
			setEfekview(!uEfekview);
			return;
		}

		//console.log("[uDatamainObj.nilai_bruto] uDatamainObj.dp_awal = "+uDatamainObj.dp_awal);
		const vNetto0 	= (parseFloat(uDatamainObj.nilai_bruto)||0) + (parseFloat(uDatamainObj.b_kartu)||0)
			- (parseFloat(uDatamainObj.dp_awal)||0);

		//console.log("[uDatamainObj.nilai_bruto] vNetto0 = "+vNetto0);
		if(vNetto0 < 0) {
			uDatamainObj.nilai_netto= 0;
			uDatamainObj.diskon 	= 0;
			setCalculate(!uIsCalculate);
			return;
		}

		const vNetto1 	= vNetto0 - (parseFloat(uDatamainObj.diskon)||0);
		//console.log("[uDatamainObj.nilai_bruto] vNetto0 = "+vNetto1);
		if(vNetto1 < 0) {
			uDatamainObj.diskon = 0;
			setEfekview(!uEfekview);
			return;	
		} 

		uDatamainObj.nilai_netto = vNetto1;
		setEfekview(!uEfekview);
	},[uDatamainObj.nilai_bruto,
	uDatamainObj.dp_awal,
	uDatamainObj.diskon,
	uDatamainObj.b_kartu]);
	React.useEffect(()=>{
		/*if((parseInt(uDatamainObj.jml_bayar)||0) < 0) {
			uDatamainObj.jml_bayar = (uDatamainObj.jml_bayar * -1); return;
		}//-*/

		let vNilainetto	= (parseInt(uDatamainObj.nilai_netto)||0);
		if(uDatamainObj.dp_awal > 0) {
			vNilainetto = (parseInt(uDatamainObj.nilai_bruto)||0) + (parseInt(uDatamainObj.b_kartu)||0)
			- (parseInt(uDatamainObj.dp_awal)||0) - (parseInt(uDatamainObj.diskon)||0);
		}
		let vPiutang	= vNilainetto - (parseInt(uDatamainObj.jml_bayar)||0);
		if(vPiutang <= 0) {
			uDatamainObj.tgl_jt = undefined;
		}
		uDatamainObj.nilai_piutang = (vPiutang);
	},[
	uDatamainObj.jml_bayar,
	uDatamainObj.nilai_netto,
	uIsCalculate]);

	React.useEffect(()=>{
		if((parseInt(uDatamainObj.id_customer)||0) > 0 
			||(parseInt(uDatamainObj.id_payment_method)||0) > 0
			||(parseFloat(uDatamainObj.nilai_bruto)||0) > 0
			||(parseFloat(uDatamainObj.diskon)||0)>0
			||(parseFloat(uDatamainObj.b_kartu)||0)>0
			||(uDatamainObj.nama_customer||"").trim() !== ""
			||(uDatamainObj.catatan_pasien||"").trim() !== ""
			||(uDatamainObj.ket_kartu||"").trim() !== ""
			||(uDatamainObj.tgl_jt||"").trim() !== ""
			||(parseFloat(uDatamainObj.jml_bayar)||0)>0
			||(uDatamainObj.items_arr||[]).length > 0
			) setFormupdate(true);
		else setFormupdate(false);
	},[uDatamainObj.id_customer,
	uDatamainObj.nama_customer,
	uDatamainObj.id_payment_method,
	uDatamainObj.diskon,
	uDatamainObj.b_kartu,
	uDatamainObj.ket_kartu,
	uDatamainObj.nilai_bruto,
	uDatamainObj.catatan_pasien,
	uDatamainObj.jml_bayar,
	uDatamainObj.tgl_jt,
	uDatamainObj.items_arr
	]);
	React.useEffect(()=>{
		if(uDataCetakObj === null) return;
		//console.log("uDataCetakObj = "+JSON.stringify(uDataCetakObj));
		prosesCetakNota();
	},[uDataCetakObj]);
	React.useEffect(()=>{
		if(uHTMLError500) setIsFormprocess(true)
	},[uHTMLError500]);
	//---END EFFECT---/

	//console.log("(Kasirtokomain -) Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-2">
		<CContainer fluid className="classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_kasirtoko||"menus_kasirtoko").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<span className="classfontsmaller d-none d-md-inline">
					<small>{UFunc.TglAngka(uDatamainObj.tgl_bayar)}{" "}{uRunningTime}</small>
				</span>
				<span className="mx-1 d-none d-md-inline">&middot;</span>
				<CLink
					className="classikon classikonreset classcursorpointer"
					onClick={()=>hdlKlikReset()}/>

				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<div className="py-3"><MyPage500 content={uHTMLError500} {...props}/></div>
				) : (
					<>{contentMain()}</>
				)}
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				className=""
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikBayar()}
				id="btnBayarmain">
				<CIcon className="classikon classikonbayar" height={27}/>{uBahasaObj.word_bayar} [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uInpDlglookupkwd}
			dataItemArr={uDatalookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialoglookupapi
			show={uISDlglookupapishow}
			toggle={()=>setDlglookupapishow(false)}
			dialogHeader={uDlglookupapiObj.headers}
			inputValue={uDlglookupapiObj.inputvalue}
			onKlikPilihItem={hdlKlikDlgLookupapipilih}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uISDlglookupapitrigger}
			id="iddialoglookupapi"
			{...props}/>

		<MyDialogform
			options={{size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleDlgform}
			renderContent={contenDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none">
			{(uDatasetting.url_image_nota||"")!=="" && (
			<CImage src={uDatasetting.url_image_nota} id="idimglogo" />
			)}
		</iframe>
		</>
	)//>
}

export default Kasirtokomain;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormSelect,
	CBadge,
	CImage,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index';
//import queryString from 'query-string';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const User = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah		= uHeaderActionObj.isHeaderTambah || false;

	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData							= (uSettingObj.jml_master || 20);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	const uInitIDselectbefore  = parseInt(useSelector(state => state.gInitTabelIDBefore))||0;

	const uKeywordObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uHakAksesArr,setHakAksesArr]		= React.useState([{
		id:"0",caption:"--"+(uBahasaObj.caption_dataempty||"caption_dataempty").toUpperCase()+"--"}
	]);

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpUsername,setInpUsername]		= React.useState("");
	const [uInpUsercaption,setInpUsercaption]= React.useState("");
	const [uInpUserPasswd1,setInpUserpasswd1]= React.useState("");
	const [uInpUserPasswd2,setInpUserpasswd2]= React.useState("");
	const [uInpUserHak,setInpUserHak]		= React.useState("ENTRI");

	const [uElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const vElJmlData	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//console.log("(User) props: "+JSON.stringify(props));

	//---HANDLE--/
	const hdlToggleForm	= () => { setShowForm(false); }
	const hdlKDownHak = (EV)=>{ if(EV.which === 13) {
		EV.preventDefault();
		uElBtnSimpan.focus();
	}}
	const hdlKDownPasswd2 = (EV)=>{
		if(uInpUserHak!=="FREEROLE") return;
		if(EV.which === 13) {
			EV.preventDefault();
			uElBtnSimpan.focus();
		}
	}
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		if(uInpUsername.trim() === "") {
			showToast(uBahasaObj.word_username+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpusername").focus();
			return;
		}
		if(uInpUsercaption.trim() === "") {
			showToast(uBahasaObj.caption_namauser+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpusercaption").focus();
			return;
		}
		if(uTabelIDPilih <= 0) {
			if(uInpUserPasswd1 === "") {
				showToast(uBahasaObj.word_passwd+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
				document.getElementById("inppasswd1").focus();
				return;
			}
			if(uInpUserPasswd2 === "") {
				showToast((uBahasaObj.caption_passwdconfirm||"caption_passwdconfirm")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
				document.getElementById("inppasswd2").focus();
				return;
			}
		}
		if(uInpUserPasswd1 !== uInpUserPasswd2) {
			showToast(uBahasaObj.word_passwd+" / "+(uBahasaObj.caption_passwdconfirm||"caption_passwdconfirm")+" "+
				uBahasaObj.caption_mustsame+".."); 
			document.getElementById("inppasswd1").focus();
			return;
		}
		if(uInpUserHak.trim() === "") {
			showToast(uBahasaObj.caption_hakakses_akses+" "+(uBahasaObj.caption_mustchoosed||"caption_mustchoosed")+".."); 
			document.getElementById("inpusercaption").focus();
			return;
		}
		//---END VALIDASI--/
		//--END VALIDASI_SIMPAN--/

		apiProsessimpan();
	}
	const hdlKlikEdit=async(_IDTABEL)=>{

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpUsername(uDatamainArr[vObjdx].username);
		setInpUsercaption(uDatamainArr[vObjdx].usercaption);
		setInpUserHak(uDatamainArr[vObjdx].userhak);
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].usercaption || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].username.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiProseshapus(_IDTABEL);
	}
	const hdlKlikRole=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;

		const vDatetimeFake = new Date();
		const vFakeID = vDatetimeFake.getFullYear() + UFunc.leadZero(vDatetimeFake.getMonth() + 1) + 
			UFunc.leadZero(vDatetimeFake.getDate()) +
			UFunc.leadZero(vDatetimeFake.getHours()) +
			UFunc.leadZero(vDatetimeFake.getMinutes()) +
			UFunc.leadZero(vDatetimeFake.getSeconds()) +
			UFunc.leadZero(vDatetimeFake.getMilliseconds());

		uDispatch({type: "set", gInitTabelIDBefore: _IDTABEL});
		uNavigate("/user/rolesetting/"+_IDTABEL+"/?_sgdfyw="+vFakeID);
	}
	const hdlKlikToOffline=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vConfirm = uBahasaObj.confirm_yakin || "confirm_yakin";
		if(await Konfirm(vConfirm)) apiProsesoffline(_IDTABEL);
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initFormAwal=()=>{
		setTabelRowSelect(-1);
		setTabelIDPilih(0)
		
		setInpUsername("");
		setInpUsercaption("");
		setInpUserpasswd1("");
		setInpUserpasswd2("");
		setInpUserHak("ENTRI");
	}
	//---END Init_LOAD--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_user || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,vMax);

		return (
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="d-none d-md-table-row text-center classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th width={40} className="px-1"/>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="4" lg="5" className="text-start">{uBahasaObj.caption_namauser||"caption_namauser"}</CCol>
				<CCol md="3" className="text-start">{uBahasaObj.word_username||"word_username"}</CCol>
				<CCol md="3" lg="2" className="text-start text-md-center">{uBahasaObj.caption_hakakses||"caption_hakakses"}</CCol>
				<CCol md="2">&middot;</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none text-center classfontsmaller">
				<th width={35} className="p-0"/>
				<th width={40} className="p-0"/>
				<th className="p-0"/>			
			</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys)=>{
				const {
					id,username,armoduls,usercaption,userhak,initonline
				} = vItems;

				vNumber++;
				const vCaptionUpper	= (username||"").toUpperCase();

				const vUsercaption	= vKeyword==="" ? usercaption 
					: usercaption.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				const vUsername= vKeyword==="" ? username 
					: username.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				let vUserhak  	= uBahasaObj["hakuser_"+userhak.toLowerCase()];
				vUserhak= vKeyword==="" ? vUserhak 
					: vUserhak.replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
				
				return (
				<tr id={"idtr"+id} 
					className={"text-end align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")}
					key={vKeys}>
					<td className="px-1 pe-0">{(vNumber)}.</td>
					<td className="text-center px-1">
						<CTooltip content={"--"+(uBahasaObj.caption_statusonline||"caption_statusonline")+": "+
							vCaptionUpper+" ("+(uBahasaObj.petunjuk_settooffline||"petunjuk_settooffline")+")"}>
							<CLink
								className={"classblinking classikontabel classikononline mx-auto"+(initonline<=0?" d-none":"")}
								onClick={()=>hdlKlikToOffline(id)} />
						</CTooltip>
						<CTooltip content={"--"+(uBahasaObj.caption_statusoffline||"caption_statusoffline")+": "+vCaptionUpper}>
							 <div className={"classikontabel classikonoffline mx-auto"+(initonline>0?" d-none":"")}/>
						</CTooltip>
					</td>

					<td className="px-1">
						<CRow className="mx-0">
						<CCol xs="12" md="4" lg="5" className="px-1 text-start d-flex justify-content-between">
							<div>
							{UFunc.renderHTML(vUsercaption)}

							{(armoduls||"")!=="" && (
								<CBadge shape="pill" style={{fontSize:"0.6em"}} className="align-top" color="danger" size="sm">&#10003;</CBadge> 
							)}
							</div>

							<CDropdown className="d-md-none">
							<CDropdownToggle caret={false} color="transparent" className="p-0 m-0">
								<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
							</CDropdownToggle>

							<CDropdownMenu>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikontabel classikonedit mx-0 me-2" height={25}/>
								{uBahasaObj.caption_editdata||"caption_editdata"}
							</CDropdownItem>

							{(uIsAllowedhapus) && (
							<>
							<CDropdownDivider/>
							
							{userhak !== "FREEROLE" && (
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikRole(id)}>
								<CImage src={pjson.homepage+"api/images/menus_role.png"} className="align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_aturrole||"caption_aturrole"}
							</CDropdownItem>
							)}
							
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikontabel classikonhapus mx-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"caption_hapusdata"}
							</CDropdownItem>
							</>
							)}
							</CDropdownMenu>
							</CDropdown>
						</CCol>
						
						<CCol xs="12" className="d-md-none my-3 classborderbottom"/>

						<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_username}</CCol>
						<CCol xs="8" md="3" className="text-md-start text-primary fw-bolder text-uppercase">
							{UFunc.renderHTML(vUsername)}
						</CCol>
						
						<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_hakakses}</CCol>
						<CCol xs="8" md="3" lg="2" className="text-md-center fw-bolder">
							{UFunc.renderHTML(vUserhak)}
						</CCol>
						
						<CCol xs="12" className="d-md-none my-1 mt-3 classborderbottom"/>
						
						<CCol md="2" className="text-start text-md-center d-none d-md-block">
							<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionUpper}>
							<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer">
							</CLink>
							</CTooltip>

							{(uIsAllowedhapus) && (
							<>
							<CDropdown>
							<CDropdownToggle caret={false} color="transparent" className="p-0 m-0">
								<CIcon icon="cilChevronBottom" className="classikoncoreui" height={23}/>
							</CDropdownToggle>
							<CDropdownMenu>
							
							{userhak !== "FREEROLE" && (
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikRole(id)}>
								<CImage src={pjson.homepage+"api/images/menus_role.png"} className="me-2 align-middle" height="25" />
								{uBahasaObj.caption_aturrole||"caption_aturrole"}
							</CDropdownItem>
							)}

							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikontabel classikonhapus me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"caption_hapusdata"}
							</CDropdownItem>
							</CDropdownMenu>
							</CDropdown>
							</>
							)}
						</CCol>
						</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
			</table>
		)//>
	}
	const contentMainForm = () => {
		if(!isShowForm) return (<></>)//>

		return (
			<>
			<CForm className="mx-lg-5">
			<CRow className="my-2">
				<CCol xs="4" className="">
					{uBahasaObj.word_username||"word_username"}
				</CCol>
				<CCol>
				<CFormInput maxLength="30"
					type="text"
					size="sm"
					className="form-control"
					value={uInpUsername}
					onChange={(e) => setInpUsername(e.target.value)}
					id="inpusername" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="">
					{uBahasaObj.caption_namauser||"caption_namauser"}
				</CCol>
				<CCol>
					<CFormInput maxLength="50"
						type="text"
						size="sm"
						className="form-control"
						value={uInpUsercaption}
						onChange={(e) => setInpUsercaption(e.target.value)}
						id="inpusercaption" />
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="">
					<span className="d-none d-lg-block">{uBahasaObj.word_passwd||"word_passwd"}</span>
					<small className="d-lg-none">{uBahasaObj.word_passwd||"word_passwd"}</small>
				</CCol>
				<CCol xs="8" lg="4">
					<CFormInput
						type="password"
						className="form-control"
						size="sm"
						value={uInpUserPasswd1}
						onChange={(e) => setInpUserpasswd1(e.target.value)}
						id="inppasswd1" />
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4" className="">
					<span className="d-none d-lg-block">{uBahasaObj.caption_passwdconfirm||"caption_passwdconfirm"}</span>
					<small className="d-lg-none">{uBahasaObj.caption_passwdconfirm||"caption_passwdconfirm"}</small>
				</CCol>
				<CCol xs="8" lg="4">
					<CFormInput
						type="password"
						size="sm"
						value={uInpUserPasswd2}
						onChange={(e) => setInpUserpasswd2(e.target.value)}
						onKeyDown={(e) => {
							!document.getElementById("inpuserhak") &&
								UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
						}}
						id="inppasswd2"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol xs="4" className="">
					{uBahasaObj.caption_hakakses||"caption_hakakses"}
				</CCol>
				<CCol xs="8" lg="4">
					{((uTokenObj.userhak!=="FREEROLE" && uTokenObj.userhak!=="ADMIN")) 
					? (<b>{uBahasaObj["hakuser_"+(uInpUserHak||"").toLowerCase()]}</b>) 
					: (
					<CFormSelect name="inpuserhak" size="sm"
						value={uInpUserHak}
						onChange={(e)=>setInpUserHak(e.target.value)} 
						onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpuserhak">
						{uHakAksesArr.map((vItems,vKeys)=>{
							return(
							<option key={vKeys} value={vItems.value}>{vItems.caption}</option>
							)
						})}
					</CFormSelect>
					)}
				</CCol>
			</CRow>
			</CForm>
			</>
		)//>
	}
	//--END CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_user || "";
		setJmlData(0);
		setDatamainArr([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);

		setLoading(true);

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstLoad,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/ld_data";

		setTimeElapsed(0); const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatamainArr(JSON.parse(output_string.dataobject || "[]"));
				
				if(uFirstLoad === "YA") {
					setHakAksesArr(JSON.parse(output_string.haklist || "[]"));
					setFirstLoad("TIDAK");
				}

				if(uInitIDselectbefore > 0) {
					let vTimeout = setTimeout(()=>{
						clearTimeout(vTimeout);
						setInitbeforeprocess(true)
					},250);
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		setLoading(true);
		uElBtnSimpan && (uElBtnSimpan.disabled = true);

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_username : uInpUsername.trim(),
			send_usercaption : uInpUsercaption.trim(),
			send_passwd1 : uInpUserPasswd1,
			send_passwd2 : uInpUserPasswd2,
			send_hak : uInpUserHak,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			uElBtnSimpan && (uElBtnSimpan.disabled = false);

			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				if(parseInt(uTabelIDPilih) <= 0) {
					const vTmpObject	= {
						id: output_string.tabelid || "255",
						username : uInpUsername.trim(),
						usercaption : UFunc.capitalize(uInpUsercaption.trim()),
						userhak : uInpUserHak,
						armoduls: "",				
						initonline : "0",				
					}
					setDatamainArr([vTmpObject,...uDatamainArr]);
					setJmlData(uJmlData+1);

					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				} else {
					const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

					uDatamainArr[vObjdx].username 	= uInpUsername.trim();
					uDatamainArr[vObjdx].usercaption = uInpUsercaption.trim();
					uDatamainArr[vObjdx].userhak 	= uInpUserHak;
					hdlToggleForm();
				}
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			uElBtnSimpan && (uElBtnSimpan.disabled = false);
			console.log("(User-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0; if(_IDTABEL <=0) return;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setJmlData(uJmlData-1);
				setTabelRowSelect(-1);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesoffline = (_IDTABEL) => {
		uFuncname 	= apiProsesoffline.name;
		_IDTABEL 	= parseInt(_IDTABEL) || 0;if(_IDTABEL <= 0) return;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_statusonline";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr[vObjdx].initonline = 
					uDatamainArr[vObjdx].initonline.toString()==="1" ? 0 : 1; 
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		setShowForm(false);
		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();

		return ()=>{ setDatamainArr([]); setHakAksesArr([]); }
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) { return; }

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(isShowForm) return;
		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));
			
			const vElFocus = document.getElementById("inpusername");
			vElFocus && vElFocus.focus();
		},350);
	},[isShowForm]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length]);
	React.useEffect(()=>{
		console.log("(User - [uJmlData]) uJmlData => "+uJmlData);
		const vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		vElJmlData && (vElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		//console.log("(User-[uInitbeforeprocess]) uInitbeforeprocess = "+(uInitbeforeprocess));
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+uInitIDselectbefore);
		setTabelRowSelect(vRowIdx);
		
		const vElscroll	= document.getElementById("idtr"+(uInitIDselectbefore||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))

		uDispatch({type: "set", gInitTabelIDBefore: undefined});
		setInitbeforeprocess(false);
	},[uInitbeforeprocess]);
	//---END EFFECT--/

	//console.log("(User) Checked..");

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_user || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none">{uBahasaObj.caption_listdata||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlHal > 1 && uJmlData >= uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentMainForm()}/>
		</>
	)//>
}
	
export default User;

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormSelect,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyDialoglookup,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 		= require('../../package.json')
const Lappenyusutan= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uSortedObj,setSortedObj]			= React.useState({});
	const [uDataasetArr,setDataasetArr]		= React.useState([]);

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//---DLGFORM--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLGFORM--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlDlgformToggle = () => { setDlgformshow(false) }
	const hdlKlikAsetlookup = () => {
		uDlglookupObj.dlg_header	= "Lookup: JENIS ASET";		
		uDlglookupObj.data_arr		= uDataasetArr.map(vItems=>{
			return {
				id: vItems.id,
				caption: vItems.caption,
			}
		})		
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		const vArridx 	= UFunc.getObjectIndeks(uDataasetArr,_IDITEM);

		//console.log("(hdlKlikDlglookuppilih) uDataasetArr => "+JSON.stringify(uDataasetArr[vArridx]||{}));
		uDlgformObj.aset_caption 	= (uDataasetArr[vArridx]||{}).caption;
		uDlgformObj.aset_id 		= _IDITEM;

		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			document.getElementById("btnDialogSimpan") &&
				document.getElementById("btnDialogSimpan").focus()
		},350)
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}

		apiLoadview();
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		contentExcel();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vColspan		= 14;
		
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));
		const vDatalastObj	= uDataviewArr.at(-1);

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		let vJenisasetidbefore	= 0;
		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:1200,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={35} className="border" style={vStylekolfixed}>No</th>
			<th width={150} className="border d-md-none" style={{...vStylekolfixed,left:35}}>{uBahasaObj.word_tanggal||"Tanggal"}</th>
			<th className="border d-md-none">{uBahasaObj.caption_namaaset||"Nama Aset/Inventaris"}</th>
			<th className="text-start border d-none d-md-table-cell" style={{...vStylekolfixed,left:35,minWidth:300}}>{uBahasaObj.caption_namaaset||"Nama Aset/Inventaris"}</th>
			<th width={100} className="border d-none d-md-table-cell">{uBahasaObj.word_tanggal||"Tanggal"}</th>
			
			<th width={85} className="border">{uBahasaObj.caption_hperolehn||"H.Prlhn"}<small className="d-block">(Rp)</small></th>
			<th width={37} className="border">{uBahasaObj.caption_nekonomi||"N.Eko"}<small className="d-block">(Bln)</small></th>
			<th width={37} className="border">{uBahasaObj.word_tarif||"Tarif"}<small className="d-block">(%)</small></th>
			<th width={37} className="border">{uBahasaObj.word_pbltn||"Pmbltn"}</th>
			<th width={85} className="border">{uBahasaObj.caption_athn||"A.Thn"}<small className="d-block">(Rp)</small></th>
			<th width={80} className="border">{uBahasaObj.caption_abln||"A.Bln"}<small className="d-block">(Rp)</small></th>
			<th width={80} className="border">{uBahasaObj.caption_1bln||"1 Bln"}<small className="d-block">(Rp)</small></th>
			<th width={80} className="border">{uBahasaObj.caption_sdblnini||"s.d. Bln Ini"}<small className="d-block">(Rp)</small></th>
			<th width={85} className="border">{uBahasaObj.caption_sdthnini||"s.d. Thn Ini"}<small className="d-block">(Rp)</small></th>
			<th width={85} className="border">{uBahasaObj.caption_nbuku||"N.Buku"}<small className="d-block">(Rp)</small></th>
			<th width={37} className="border">{uBahasaObj.caption_sbulan||"Sisa Bln"}</th>
			</tr>
			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDataviewArr)}</th></tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id_aset_jenis,nama_aset,tgl_beli,nilai_barang,penyusutan,tarif,
					pembulatan_susut,a_tahun,a_bulan,bulan_1,sisa_habis,id,jenis_aset,
				} = vItems;

				vNumber++;

				const vJenisidrunning 	= parseInt(id_aset_jenis) || 0;
				const vJeniscaption 	= (jenis_aset||"").toUpperCase();
				const vISchangejenis	= vJenisidrunning !== vJenisasetidbefore;

				const vHTMLRowcaption	= () => {
					if(!vISchangejenis) return "";
					if(parseInt(uDataloadedObj.aset_id)||0 > 0) return "";

					return (
					<tr className="align-top classfontsmaller bg-secondary text-white">
						<td colSpan={2} className="px-1 text-center fw-bolder bg-secondary text-white" style={vStylekolfixed}>{jenis_aset}</td>
						<td className="px-1" colSpan={12}/>
					</tr>
					)
				}
				const vHTMLRowsubtotal	= () => {
					if(parseInt(uDataloadedObj.aset_id)||0 > 0) return "";

					const vJenisidnext	= parseInt((vDataviewArr[vKeys+1]||{}).id_aset_jenis) || 0
					const vSubtotalObj 	= uDataloadedObj["subtotal_"+vJenisidrunning] || {};
					
					if(vJenisidrunning !== vJenisidnext) return (
					<tr className="align-top classfontsmaller fw-bolder bg-info text-white">
						<td colSpan={2} className="px-1 text-center bg-info text-white" style={vStylekolfixed}>SUB TOTAL</td>
						<td className="px-1"/>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.nilai_barang)}</td>
						<td className="px-1 text-start" colSpan={2}/>
						<td className="px-1 text-center text-white">{UFunc.formatAngka(vSubtotalObj.pembulatan_susut)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.a_tahun)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.a_bulan)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.bulan_1)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.sd_bulan_ini)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.sd_tahun_ini)}</td>
						<td className="px-1 text-end text-white">{UFunc.formatAngka(vSubtotalObj.nilai_buku)}</td>
						<td className="px-1 text-start"/>
					</tr>
					)
				}

				vJenisasetidbefore 	= parseInt(id_aset_jenis)||0;

				const vNilaibarang	= (parseFloat(nilai_barang)||0);
				const vSdbulanini	= (parseFloat(a_bulan)||0) + (parseFloat(bulan_1)||0);
				const vSdtahunini	= (parseFloat(a_tahun)||0) + vSdbulanini;
				const vNilaibuku	= vNilaibarang - vSdtahunini < 0 ? 0 : vNilaibarang - vSdtahunini;

				return (
				<>
				{vHTMLRowcaption()}
				<tr className="align-top classfontsmaller" key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>
				<td className="px-1 text-start d-md-none" style={{...vStylekolfixed,left:35}}>
					{UFunc.TglAngka(tgl_beli)}
				</td>
				<td className="px-1 text-start d-md-none text-capitalize">{nama_aset||"UNDF Nama"}</td>
				
				<td className="px-1 text-start d-none d-md-table-cell text-capitalize" style={{...vStylekolfixed,left:35}}>{nama_aset||"UNDF Nama"}</td>
				<td className="px-1 text-center d-none d-md-table-cell">{UFunc.TglAngka(tgl_beli)}</td>
				
				<td className="text-end px-1">{UFunc.formatAngka(vNilaibarang)}</td>
				<td className="text-center px-1">{UFunc.formatAngka(penyusutan)}</td>
				<td className="text-center px-1">{UFunc.formatAngka(tarif)}</td>
				<td className="text-center px-1">{UFunc.formatAngka(parseInt(pembulatan_susut))}</td>
				<td className="text-end px-1">{UFunc.formatAngka(a_tahun)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(a_bulan)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(bulan_1)}</td>

				<td className="text-end px-1">{UFunc.formatAngka(vSdbulanini)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(vSdtahunini)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(vNilaibuku)}</td>

				<td className="text-center px-1">{UFunc.formatAngka(sisa_habis)}</td>
				</tr>
				{vHTMLRowsubtotal()}
				</>
				)
			})}
			</tbody>

			<tfoot>
			<tr className="align-top classfontsmaller fw-bolder bg-dark text-white">
				<td colSpan={2} className="px-1 text-center bg-dark text-white" style={vStylekolfixed}>JUMLAH TOTAL</td>
				<td className="px-1"/>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_barang)}</td>
				<td className="px-1 text-start" colSpan={2}/>
				<td className="px-1 text-center">{UFunc.formatAngka(uDataloadedObj.sum_pembulatan_susut)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_a_tahun)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_a_bulan)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_bulan_1)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_sd_bulan_ini)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_sd_tahun_ini)}</td>
				<td className="px-1 text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_buku)}</td>
				<td className="px-1 text-start"/>
			</tr>
			</tfoot>
			</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		return (
		<CForm>
		<CRow className="my-2 mx-0">
		<CCol>Periode</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="mx-1">&nbsp;</span>

			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		
		<CRow className="my-2 mx-0">
		<CCol>Jenis Inventaris</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
				<CFormInput size="sm"
					readOnly={true}
					value={uDlgformObj.aset_caption||""}
					onClick={hdlKlikAsetlookup}
					placeholder="--SEMUA JENIS INVENTARIS--"
					id="inpasetcaption"/>
				{(parseInt(uDlgformObj.aset_id)||0) > 0 && (
				<CButton onClick={()=>{
						uDlgformObj.aset_caption= "";
						uDlgformObj.aset_id 	= 0;
						setEfekview(!uEfekview);
					}}
					className="border border-danger" 
					style={{backgroundColor:"#FF000033"}} 
					size="sm" id="btnasetbatal">
					<CIcon icon="cilX" className="text-danger"/>
				</CButton>
				)}
				<CButton onClick={hdlKlikAsetlookup} color="dark" size="sm" id="btnasetlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		
		</CForm>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lappenyusutan - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 14;
		const vDataviewArr	= uDataviewArr;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			(parseInt(uDataloadedObj.aset_id) > 0 
				? "<div><small>"+uTitelObj.subtitel+"</small></div>"
				: "")+
			"";
		const vJudulsub		= `<div>Periode <b>`+uTitelObj.periode+`</b></div>`+
			((parseInt(uDataloadedObj.aset_id)||0) <= 0 
				? "<div><small>"+uTitelObj.subtitel+"</small></div>"
				: "")+
		``;

		const vTabelheader	= `
			<th width="3%">No</th>
			<th align="left">`+(uBahasaObj.caption_namaaset||"Nama Aset")+`</th>
			<th width="7%" align="left">Tanggal</th>
			<th width="7%">H.Prlhn <small>(Rp)</small></th>
			<th width="3%">`+(uBahasaObj.caption_nekonomi||"N.Eko")+`</th>
			<th width="3%">`+(uBahasaObj.word_tarif||"Tarif")+`</th>
			<th width="3%">`+(uBahasaObj.word_pembltn||"Pmbltn")+`</th>
			<th width="7%">A.Thn <small>(Rp)</small></th>
			<th width="5%">A.Bln <small>(Rp)</small></th>
			<th width="5%">1 Bln <small>(Rp)</small></th>
			<th width="7%">s.d.Bln Ini <small>(Rp)</small></th>
			<th width="7%">s.d.Thn Ini <small>(Rp)</small></th>
			<th width="7%">N.Buku <small>(Rp)</small></th>
			<th width="3%">`+(uBahasaObj.caption_sbulan||"Sisa Bln")+`</th>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		let vJenisidbefore		= 0;
		vTabelbody	+= vDataviewArr.map((vItems,vKeys) => {
			const {
				id_aset_jenis,nama_aset,tgl_beli,nilai_barang,penyusutan,tarif,
				pembulatan_susut,a_tahun,a_bulan,bulan_1,sisa_habis,id,jenis_aset,
			} = vItems;

			vNumber++;

			const vISchangejenis 	= vJenisidbefore !== (parseInt(id_aset_jenis)||0) &&
				(parseInt(uDataloadedObj.aset_id)||0) <= 0 ;
			const vJenisidnext		= parseInt((vDataviewArr[vKeys+1]||{}).id_aset_jenis) || 0
			const vTmpsubtotalObj 	= (uDataloadedObj["subtotal_"+id_aset_jenis]||{})

			const vNilaibarang 		= parseFloat(nilai_barang)||0;
			const vSdbulanini 		= (parseFloat(a_bulan)||0) + (parseFloat(bulan_1)||0);
			const vSdtahunini 		= (parseFloat(a_tahun)||0) + vSdbulanini;
			const vNilaibuku 		= vNilaibarang - vSdtahunini < 0 ? 0 : vNilaibarang - vSdtahunini;

			vJenisidbefore 			= parseInt(id_aset_jenis);

			return ( 
				(vISchangejenis 
					? `
					<tr valign="top" style="font-size:smaller;">
					<td colspan="3" align="center" style="border-top:1px solid black;border-bottom:1px solid black;">
					<b>`+(jenis_aset||"JENIS").toUpperCase()+`</b>
					</td>
					<td colspan="`+(vColspan-3)+`" style="border-top:1px solid black;border-bottom:1px solid black;">&nbsp;</td>
					</tr>
					` 
					: ``)+`
				<tr valign="top" key=`+vKeys+` style="font-size:smaller;">
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+(nama_aset||"UNDF NAMA")+`</td>
				<td align="left">`+UFunc.TglAngka(tgl_beli)+`</td>
				<td align="right">`+UFunc.formatAngka(vNilaibarang)+`</td>
				<td align="center">`+UFunc.formatAngka(penyusutan)+`</td>
				<td align="center">`+UFunc.formatAngka(tarif)+`</td>
				<td align="center">`+UFunc.formatAngka(pembulatan_susut)+`</td>
				<td align="right">`+UFunc.formatAngka(a_tahun)+`</td>
				<td align="right">`+UFunc.formatAngka(a_bulan)+`</td>
				<td align="right">`+UFunc.formatAngka(bulan_1)+`</td>

				<td align="right">`+UFunc.formatAngka(vSdbulanini)+`</td>
				<td align="right">`+UFunc.formatAngka(vSdtahunini)+`</td>
				<td align="right">`+UFunc.formatAngka(vNilaibuku)+`</td>
				
				<td align="center">`+UFunc.formatAngka(sisa_habis)+`</td>
				</tr>`+
				((vJenisidnext !== parseInt(id_aset_jenis) && 
					(parseInt(uDataloadedObj.aset_id)||0) <= 0 )
					? `  
					<tr valign="top" style="font-size:smaller;">
					<td align="center" colspan="3" style="border-top:1px solid gray;">Sub Total</td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.nilai_barang)+`</b></td>
					<td colspan="2" style="border-top:1px solid gray;">&nbsp;</td>
					<td align="right" style="border-top:1px solid gray;">`+UFunc.formatAngka(vTmpsubtotalObj.pembulatan_susut)+`</td>
					<td align="right" style="border-top:1px solid gray;">`+UFunc.formatAngka(vTmpsubtotalObj.a_tahun)+`</td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.a_bulan)+`</b></td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.bulan_1)+`</b></td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.sd_bulan_ini)+`</b></td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.sd_tahun_ini)+`</b></td>
					<td align="right" style="border-top:1px solid gray;"><b>`+UFunc.formatAngka(vTmpsubtotalObj.nilai_buku)+`</b></td>
					<td style="border-top:1px solid gray;">&nbsp;</td>
					</tr>
					`
					: ``)+`
			`);
		}).join("");
		
		vTabelbody += `
			<tr valign="top" style="font-size:smaller;">
			<td align="center" colspan="3" style="border-top:3px double black;border-bottom:1px solid black;">JUMLAH TOTAL</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_nilai_barang)+`</b></td>
			<td colspan="2" style="border-top:3px double black;border-bottom:1px solid black;">&nbsp;</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_pembulatan_susut)+`</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;">`+UFunc.formatAngka(uDataloadedObj.sum_a_tahun)+`</td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_a_bulan)+`</b></td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_bulan_1)+`</b></td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_sd_bulan_ini)+`</b></td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_sd_tahun_ini)+`</b></td>
			<td align="right" style="border-top:3px double black;border-bottom:1px solid black;"><b>`+UFunc.formatAngka(uDataloadedObj.sum_nilai_buku)+`</b></td>
			<td style="border-top:3px double black;border-bottom:1px solid black;">&nbsp;</td>
			</tr>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,"");

		return vHTMLs;
	}
	const contentExcel = () => {
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";
		const vAsetcaption	= (uDataloadedObj||{}).aset_caption;

		const vColspan 	= 14;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;
		let vJenisidbefore	= 10;

		let vTmpObj	= {
			no : "",
			nama_aset: "",
			tgl_beli: "",
			nilai_barang: "",
			penyusutan: "",
			tarif: "",
			pembulatan_susut: "",
			a_tahun: "",
			a_bulan: "",
			bulan_1: "",
			sd_bulan_ini: "",
			sd_tahun_ini: "",
			nilai_buku: "",
			sisa_habis: "",
		}
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		//console.log("uDataviewArr => "+JSON.stringify(uDataviewArr));
		uDataviewArr.map((vItems,vKeys)=>{
			const {
				id_aset_jenis,nama_aset,tgl_beli,nilai_barang,penyusutan,tarif,
				pembulatan_susut,a_tahun,a_bulan,bulan_1,sisa_habis,jenis_aset,
				id,
			} = vItems;

			vColposwidth = (nama_aset||"").length > vColposwidth 
				? (nama_aset||"").length : vColposwidth; 

			const vISchangejenis 	= vJenisidbefore !== (parseInt(id_aset_jenis)||0) &&
				(parseInt(uDataloadedObj.aset_id)||0) <= 0;
			const vJenisidnext		= parseInt((uDataviewArr[vKeys+1]||{}).id_aset_jenis) || 0;
			const vTmpsubtotalObj 	= (uDataloadedObj["subtotal_"+id_aset_jenis]||{});

			const vNilaibarang 		= parseFloat(nilai_barang)||0;
			const vSdbulanini 		= (parseFloat(a_bulan)||0) + (parseFloat(bulan_1)||0);
			const vSdtahunini 		= (parseFloat(a_tahun)||0) + vSdbulanini;
			const vNilaibuku 		= vNilaibarang - vSdtahunini < 0 ? 0 : vNilaibarang - vSdtahunini;

			if(vISchangejenis) {
				vTmpObj	= {
					no : "",
					nama_aset: { v:(jenis_aset||"Undf Jenis").toUpperCase(), t:"s", s:{alignment:{horizontal:"left"},font:{bold:true}} },
					tgl_beli: "",
					nilai_barang: "",
					penyusutan: "",
					tarif: "",
					pembulatan_susut: "",
					a_tahun: "",
					a_bulan: "",
					bulan_1: "",
					sd_bulan_ini: "",
					sd_tahun_ini: "",
					nilai_buku: "",
					sisa_habis: "",
				}
				vDatasetsArr.push(vTmpObj);
				vRowcount++;				
			} 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				nama_aset: (nama_aset||"Undf Aset"),
				tgl_beli: { v:tgl_beli, t:"d", s:{alignment:{horizontal:"center"}} },
				nilai_barang: { v:vNilaibarang, t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				penyusutan: { v:parseFloat(penyusutan), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
				tarif: { v:parseFloat(tarif), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
				pembulatan_susut: { v:parseInt(pembulatan_susut), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
				a_tahun: { v:parseFloat(a_tahun), t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				a_bulan: { v:parseFloat(a_bulan), t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				bulan_1: { v:parseFloat(bulan_1), t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				sd_bulan_ini: { v:vSdbulanini, t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				sd_tahun_ini: { v:vSdtahunini, t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				nilai_buku: { v:vNilaibuku, t:"n", s:{alignment:{horizontal:"right"},numFmt: "#,##0"} },
				sisa_habis: { v:parseInt(sisa_habis), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
			vRowcount++;

			if(vJenisidnext !== parseInt(id_aset_jenis) && 
				(parseInt(uDataloadedObj.aset_id)||0) <= 0 ) {
				vTmpObj	= {
					no : "",
					nama_aset: "SUB TOTAL",
					tgl_beli: "",
					nilai_barang: { v:vTmpsubtotalObj.nilai_barang, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					penyusutan: "",
					tarif: "",
					pembulatan_susut: { v:vTmpsubtotalObj.pembulatan_susut, t:"n", s:{alignment:{horizontal:"center"},font:{bold:true},numFmt: "#,##0"} },
					a_tahun: { v:vTmpsubtotalObj.a_tahun, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					a_bulan: { v:vTmpsubtotalObj.a_bulan, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					bulan_1: { v:vTmpsubtotalObj.bulan_1, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					sd_bulan_ini: { v:vTmpsubtotalObj.sd_bulan_ini, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					sd_tahun_ini: { v:vTmpsubtotalObj.sd_tahun_ini, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					nilai_buku: { v:vTmpsubtotalObj.nilai_buku, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
					sisa_habis: "",
				}
				vDatasetsArr.push(vTmpObj);
				vRowcount++;

				vTmpObj	= {
					no : "",
					nama_aset: "",
					tgl_beli: "",
					nilai_barang: "",
					penyusutan: "",
					tarif: "",
					pembulatan_susut: "",
					a_tahun: "",
					a_bulan: "",
					bulan_1: "",
					sd_bulan_ini: "",
					sd_tahun_ini: "",
					nilai_buku: "",
					sisa_habis: "",
				}
				vDatasetsArr.push(vTmpObj);
				vRowcount++;
			}

			vJenisidbefore 			= parseInt(id_aset_jenis);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no: { v:"JUMLAH TOTAL", t:"s", s:{alignment:{horizontal:"center"},font:{bold:true}} },
			nama_aset: "",
			tgl_beli: "",
			nilai_barang: { v:uDataloadedObj.sum_nilai_barang, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			penyusutan: "",
			tarif: "",
			pembulatan_susut: { v:uDataloadedObj.sum_pembulatan_susut, t:"n", s:{alignment:{horizontal:"center"},font:{bold:true},numFmt: "#,##0"} },
			a_tahun: { v:uDataloadedObj.sum_a_tahun, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			a_bulan: { v:uDataloadedObj.sum_a_bulan, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			bulan_1: { v:uDataloadedObj.sum_bulan_1, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			sd_bulan_ini: { v:uDataloadedObj.sum_sd_bulan_ini, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			sd_tahun_ini: { v:uDataloadedObj.sum_sd_tahun_ini, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			nilai_buku: { v:uDataloadedObj.sum_nilai_buku, t:"n", s:{alignment:{horizontal:"right"},font:{bold:true},numFmt: "#,##0"} },
			sisa_habis: "",
		}
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:47}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
			{wpx:90},
			{wpx:100},
			{wpx:65},
			{wpx:65},
			{wpx:65},
			{wpx:100},
			{wpx:100},
			{wpx:100},
			{wpx:100},
			{wpx:100},
			{wpx:100},
			{wpx:65},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Lappenyusutan_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
			"\r\n "+uTitelObj.subtitel+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center",vertical:"center"},font:{bold:true}}}, 
			{v:"Nama Aset",s:{alignment:{horizontal:"left",vertical:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"center",vertical:"center"},font:{bold:true}}}, 
			{v:"H.Perolehan\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"N.Ekonomi\r\n(Bln)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Tarif\r\n(%)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Pmbltn",s:{alignment:{horizontal:"center",vertical:"center"},font:{bold:true}}}, 
			{v:"Awl Tahun\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Awl Bulan\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"1 Bulan\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"S.d.Bulan Ini\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"S.d.Thn Ini\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"N.Buku\r\n(Rp)",s:{alignment:{horizontal:"center",wrapText: true},font:{bold:true}}}, 
			{v:"Sisa Bln",s:{alignment:{horizontal:"center",vertical:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500(); 
		setDataloadedObj();
		setSortedObj({});
		setDataasetArr([]);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappenyusutan/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Lappenyusutan-apiLoadinit) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Lappenyusutan-apiLoadinit) output_string.dataidentitas : "+(output_string.dataidentitas));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
				setDataasetArr(JSON.parse(output_string.dataaset || "[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappenyusutan-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lappenyusutan-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappenyusutan/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Lappenyusutan-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				vTmpArr.map(vItems=>{
					const vJenisasetidx = UFunc.getObjectIndeks(uDataasetArr,vItems.id_aset_jenis);

					vItems.jenis_aset 	= (uDataasetArr[vJenisasetidx]||{}).caption;
					vItems.urutan 		= (uDataasetArr[vJenisasetidx]||{}).urutan;
				})
				setDatavieworiArr([{firstrow:1,urutan:0},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				hdlDlgformToggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappenyusutan-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Lappenyusutan - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlDlgformToggle();setDlglookupshow(false);
			setDataloadedObj(false);
			setDatavieworiArr([]);
			setSortedObj({});
			setDataidentitasObj({}); setDataasetArr([]);
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		uDatavieworiArr.sort((a,b)=> a.urutan - b.urutan);

		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		uDataasetArr.map(vItems => {
			const vDatafilterArr = uDataviewArr.filter(vItemfilter => 
				parseInt(vItemfilter.id_aset_jenis) === parseInt(vItems.id));

			if(vDatafilterArr.length > 0) {
				const vSumnilaibarang = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseFloat(vItemfilter.nilai_barang)||0)
					,0);
				const vSumpembulatan = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseInt(vItemfilter.pembulatan_susut)||0)
					,0);
				const vSumAtahun = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseFloat(vItemfilter.a_tahun)||0)
					,0);
				const vSumAbulan = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseFloat(vItemfilter.a_bulan)||0)
					,0);
				const vSumbulan1 = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseFloat(vItemfilter.bulan_1)||0)
					,0);
				const vSumsdbulan = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + 
						(parseFloat(vItemfilter.a_bulan)||0) + (parseFloat(vItemfilter.bulan_1)||0)
					,0);
				const vSumsdtahun = vDatafilterArr.reduce((vSUM,vItemfilter)=>
					vSUM + (parseFloat(vItemfilter.a_tahun)||0) + 
						(parseFloat(vItemfilter.a_bulan)||0) + 
						(parseFloat(vItemfilter.bulan_1)||0)
					,0);
				const vSumnilaibuku = vDatafilterArr.reduce((vSUM,vItemfilter)=> {
					const vNilaibarang 	= (parseFloat(vItemfilter.nilai_barang)||0);
					const vSdbulanini 	= (parseFloat(vItemfilter.a_bulan)||0) + 
						(parseFloat(vItemfilter.bulan_1)||0);
					const vSdtahunini	= vSdbulanini + (parseFloat(vItemfilter.a_tahun)||0);

					return vSUM + (vNilaibarang - vSdtahunini < 0 ? 0 : vNilaibarang - vSdtahunini);
				},0);

				uDataloadedObj["subtotal_"+vItems.id] = {
					nilai_barang : vSumnilaibarang,
					pembulatan_susut : vSumpembulatan,
					a_tahun : vSumAtahun,
					a_bulan : vSumAbulan,
					bulan_1 : vSumbulan1,
					sd_bulan_ini : vSumsdbulan,
					sd_tahun_ini : vSumsdtahun,
					nilai_buku : vSumnilaibuku,
				}
			}
		});

		const vSumnilaibarang = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + (parseFloat(vItemfilter.nilai_barang)||0)
			,0);
		const vSumpembulatan = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + (parseInt(vItemfilter.pembulatan_susut)||0)
			,0);
		const vSumAtahun = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + (parseFloat(vItemfilter.a_tahun)||0)
			,0);
		const vSumAbulan = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + (parseFloat(vItemfilter.a_bulan)||0)
			,0);
		const vSumbulan1 = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + (parseFloat(vItemfilter.bulan_1)||0)
			,0);
		const vSumsdbulan = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + 
				(parseFloat(vItemfilter.a_bulan)||0) + (parseFloat(vItemfilter.bulan_1)||0)
			,0);
		const vSumsdtahun = uDataviewArr.reduce((vSUM,vItemfilter)=>
			vSUM + 
				(parseFloat(vItemfilter.a_tahun)||0) + 
				(parseFloat(vItemfilter.a_bulan)||0) + (parseFloat(vItemfilter.bulan_1)||0)
			,0);
		const vSumnilaibuku = uDataviewArr.reduce((vSUM,vItemfilter)=> {
			const vNilaibarang 	= (parseFloat(vItemfilter.nilai_barang)||0);
			const vSdbulanini 	= (parseFloat(vItemfilter.a_bulan)||0) + 
				(parseFloat(vItemfilter.bulan_1)||0);
			const vSdtahunini	= vSdbulanini + (parseFloat(vItemfilter.a_tahun)||0);

			return vSUM + (vNilaibarang - vSdtahunini < 0 ? 0 : vNilaibarang - vSdtahunini);
		},0);

		uDataloadedObj && (uDataloadedObj.sum_nilai_barang 	= vSumnilaibarang);
		uDataloadedObj && (uDataloadedObj.sum_pembulatan_susut 	= vSumpembulatan);
		uDataloadedObj && (uDataloadedObj.sum_a_tahun 		= vSumAtahun);
		uDataloadedObj && (uDataloadedObj.sum_a_bulan 		= vSumAbulan);
		uDataloadedObj && (uDataloadedObj.sum_bulan_1 		= vSumbulan1);
		uDataloadedObj && (uDataloadedObj.sum_sd_bulan_ini 	= vSumsdbulan);
		uDataloadedObj && (uDataloadedObj.sum_sd_tahun_ini 	= vSumsdtahun);
		uDataloadedObj && (uDataloadedObj.sum_nilai_buku	= vSumnilaibuku);
		//-*/
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.aset_id === undefined)
			uDlgformObj.aset_id = uDataloadedObj ? uDataloadedObj.aset_id : 0;
		if(uDlgformObj.aset_caption === undefined)
			uDlgformObj.aset_caption = uDataloadedObj ? uDataloadedObj.aset_caption : "";

		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpbulanid");
			vElfocus && vElfocus.focus();
		},350);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{setDlglookupObj({}); return}
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 		= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		uTitelObj.subtitel 		= (parseInt(uDataloadedObj.aset_id)||0) > 0 
			? uDataloadedObj.aset_caption : "SEMUA JENIS INVENTARIS";
	},[uDataloadedObj]);
	React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);//*/
	
	//console.log("Lappenyusutan - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			{uTitelObj.subtitel && (
				<span className="text-primary classfontsmaller"><small>{uTitelObj.subtitel}</small></span>
			)}
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialoglookup
			show={uISDlglookupshow}
			dialogHeader={uDlglookupObj.dlg_header||""}
			inputValue={uDlglookupObj.dlg_keyword||""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			toggle={()=>setDlglookupshow(false)}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlDlgformToggle}
			renderContent={contentDlgform()}/>
		<iframe id="ifmcontentstoprint" className="d-none"/>
	</>
	)//>
}	

export default Lappenyusutan
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormSelect,
  CButton,
  CImage,
  CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'
import { isMobile } from "react-device-detect";

const pjson = require('../../package.json');
const Login = (props) => {
	const {
		setToken,setLoading, showToast,setSessionaktif,
	} = props; 

	//console.log("Login - props => "+JSON.stringify(props));
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaob  		= useSelector(state => state.listBahasa);
	const uRouteProfileArr	= useSelector(state => state.profileRoute); 
	const uBranchcode		= useSelector(state => state.branchCode); 

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const [uDatacabangArr,setDatacabangArr]	= React.useState(JSON.parse(localStorage.getItem("cabanglist") || "[]"));
	const [uInpUsername,setUsername]  		= React.useState(localStorage.getItem("lastusername") || "");
	const [uInpPasswd,setInpPasswd]   		= React.useState('');
	const [uBtnDisabled,setBtnDisabled]		= React.useState(true);

	const initBrandname	= () =>{
		const vIcon = (
			<CImage
				src={"images/logo192.png"}
				className="me-1 d-md-none" height={40}/>
		)

		const vNameArr = pjson.name;
		return (
			<>
			{vIcon}
			<span className="classtitlelogin align-middle" style={{}}>
				{vNameArr}
			</span>
			<small className="ms-2">v{pjson.version}</small>
			</>
		)//>
	}

	const hdlKlikLogin = () => {
		//---VALIDASI--/
		if(uInpUsername.trim() === "" || uInpPasswd === "") return;
		//---END VALIDASI--/

		apiProseslogin();
	}

	const apiProseslogin = () => {
		setLoading(true);
		setBtnDisabled(true);

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_username : uInpUsername,
			send_passwd : uInpPasswd,
			post_tokenauth : uInpPasswd,
		});
		const vURLs	= pjson.homepage+"api/api_login/pr_login";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		})
		.then((response)=> {
			if (response.status === 200) return response.json();
		})
		.then((output_string) => {
			setLoading(false);
			setBtnDisabled(false);

			//console.log("(Login - apiProseslogin) output_string : "+JSON.stringify(output_string));
			if(output_string.langs) {
				uDispatch({type: "set", listBahasa: JSON.parse(output_string.langs)});
			}//-*/
			if(output_string.sukses) {
				setSessionaktif(true);
				uDispatch({type: "set", gSystemsetting:JSON.parse(output_string.systemsetting||"{}")});
				if(output_string.nav) {
					uDispatch({type: "set", listNav: JSON.parse(output_string.nav||"[]")});
				}//-*/
				if(output_string.routes) {
					const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
					const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
					localStorage.setItem("routeslist", JSON.stringify(vNewRoutes));
				}//-*/

				uDispatch({type: "set", gIsEOMExecuted: ((output_string.status_eom||"YA")==="YA" ? true : false)});
				uDispatch({type: "set", gEOMLabel: (output_string.status_eom_label||"") });
				if(output_string.kasobject) {
					localStorage.setItem("kasgllist",output_string.kasobject);
				}
				localStorage.setItem("layananlist", (output_string.layananlist||"[]"));
				 
				setBtnDisabled(true);
				localStorage.setItem("lastusername",(uInpUsername||"").trim());
				setToken(JSON.parse(output_string.token||"{}"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Login - apiProseslogin) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
			}
		})
		.catch((error) =>{
			setBtnDisabled(false);
			setLoading(false);
			console.log("(Login - apiProseslogin) catch-error: "+error);
			uNavigate("/error500");
		});
	}

	React.useEffect(() => {
		let vElfocus = document.getElementById("inpusername")
		if(uInpUsername.trim() !== "")
			vElfocus = document.getElementById("inppasswd")

		if(!isMobile) {
			vElfocus && vElfocus.focus()
		}

		return ()=>{}
	},[])
	React.useEffect(() => {
		setBtnDisabled(true);
		if(uInpUsername.trim() !== "" 
			&& uInpPasswd !== "") setBtnDisabled(false);
	},[uInpUsername,uInpPasswd])
	
	if(!UFunc.isEmpty(uTokenObj)) { window.location.replace("/"); return (<></>); }

	return (
		<>
		<div className="classbglogintop" style={{
			backgroundImage:"url(images/bg_gradient_bottom.png)"
			}}/>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
			<div className="body flex-grow-1 px-3 d-flex align-items-center">
			<CRow className="justify-content-center w-100 mx-auto">
			<CCol md="3" xs="11" className="d-none d-md-flex flex-row align-items-center">
				<div align="center">
				<CImage src={"images/logo192.png"} height="175" className="m-auto " />
				</div>
			</CCol>
			<CCol md="1" lg="0" className="border-end"></CCol>
			<CCol md="5" xs="11" className="px-sm-2 px-lg-5 text-center">
			<div className="" style={{paddingBottom:"0"}}>
				{initBrandname()}
			</div>
			<div className="classfontsmaller">
				{UFunc.renderHTML(uBahasaob.petunjuk_login || "Silahkan Login untuk Masuk dan<br />Menggunakan Aplikasi Ini")}
			</div>

			<CForm className="px-md-4 px-lg-5">
				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilUser" className="text-danger"/>
					</CInputGroupText>
					<CFormInput type="text" 
						onChange={(e)=>setUsername(e.target.value)} 
						placeholder={(uBahasaob.word_username||"Username")+".."} 
						autoComplete="off" 
						value={uInpUsername}
						id="inpusername"
						className="bg-white"
						required/>
				</CInputGroup>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilLockLocked" className="text-danger"/>
					</CInputGroupText>
					<CFormInput type="password" 
						onChange={(e)=>{setInpPasswd(e.target.value);}} 
						placeholder={(uBahasaob.word_passwd||"Password")+".."} 
						className="bg-white"
						autoComplete="current-password" required
						id="inppasswd"/>
				</CInputGroup>

				<CInputGroup className="my-2 my-md-4">
				<MyButton
					iconname="cilLockUnlocked"
					onClick={()=>hdlKlikLogin()}
					disabled={uBtnDisabled}
					id="btnLogin">L o g i n</MyButton>
				</CInputGroup>
				<div className="text-center">
					<p className="classpetunjuk">
					{uBahasaob.caption_lupapasswd||"Lupa Password"}?{" "}
					<strong className="text-dark">{uBahasaob.caption_hubsupervisor||"Hubungi Supervisor"}</strong>
					</p>
				</div>
			</CForm>
			</CCol>
			</CRow>
			</div>
		</div>	
		</>
	)
}

export default Login

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormSelect,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyDialoglookup,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 		= require('../../package.json')
const Laphistoristok= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uSortedObj,setSortedObj]			= React.useState({});
	const [uDatabarangArr,setDatabarangArr]	= React.useState([]);

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//---DLGFORM--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLGFORM--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlDlgformToggle = () => { setDlgformshow(false) }
	const hdlKlikBaranglookup = () => {
		uDlglookupObj.dlg_header	= "Lookup: BARANG";		
		uDlglookupObj.data_arr	= uDatabarangArr.map(vItems=>{
			return {
				id: vItems.id,
				caption: vItems.caption,
			}
		})		
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		const vArridx 	= UFunc.getObjectIndeks(uDatabarangArr,_IDITEM);

		console.log("(hdlKlikDlglookuppilih) uDatabarangArr => "+JSON.stringify(uDatabarangArr[vArridx]||{}));
		uDlgformObj.barang_caption 	= (uDatabarangArr[vArridx]||{}).caption;
		uDlgformObj.barang_id 		= _IDITEM;

		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			document.getElementById("btnDialogSimpan") &&
				document.getElementById("btnDialogSimpan").focus()
		},350)
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}

		vElfocus	= document.getElementById("inpbarangcaption");
		if((parseInt(uDlgformObj.barang_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nama Barang harus Dipilih !"); return
		} 

		apiLoadview();
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		contentExcel();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vColspan		= 7;
		
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));
		const vDatalastObj	= uDataviewArr.at(-1);

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:800,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={35} className="border" style={vStylekolfixed}>No</th>
			<th width={125} className="text-start border" style={{...vStylekolfixed,left:35}}>{uBahasaObj.word_tanggal||"Tanggal"}</th>
			<th width={125} className="border">{uBahasaObj.caption_noref||"No.Ref"}</th>
			<th className="text-start border" style={{minWidth:250}}>{uBahasaObj.word_keterangan||"Keterangan"}</th>
			<th width="10%" className="border">{uBahasaObj.caption_statusmutasi||"Status Mutasi"}</th>
			<th width="10%" className="border">{uBahasaObj.caption_jmlmutasi||"Jml"}</th>
			<th width="11%" className="border">{uBahasaObj.word_saldo||"Saldo"}</th>
			</tr>
			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDataviewArr)}</th></tr>
			</thead>
			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,tgl_input,tgl_proses,tgl_inputs,ket,status,qty,
					jml_saldo,noref,
				} = vItems;

				vNumber++;

				return (
				<tr className="align-top" key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>
				<td className="px-1 text-start" style={{...vStylekolfixed,left:35}}>
					{(parseInt(id)||0) > 0 ? (
					<>
						{(tgl_proses||"")!=="" 
						? (
						<>
							<div>{UFunc.TglAngka(tgl_proses)}</div>
							<div className="classfontsmaller text-danger">
								<small>(Input: {UFunc.WaktuAngka(tgl_input)})</small>
							</div>
						</>
						) : (<>{UFunc.TglAngka(tgl_inputs)}</>)}
					</>
					) : (<>&nbsp;</>)}
				</td>
				
				<td className="text-center px-1">
					{(parseInt(id)||0) > 0 ? (
					<>{noref||"-"}</>
					) : (<>&middot;</>)}
				</td>

				<td className="text-start px-1 fst-italic">
					<span className={vKeys<=0 ? "fw-bolder" : ""}>
					{UFunc.renderHTML(ket||"Undf")}
					</span>
				</td>
				
				<td className="text-center px-1">
					{(parseInt(id)||0) > 0 ? (
					<>
						{(status||"")==="MASUK" ? (
						<b className="text-primary">{status}</b>
						) : (
						<span className="text-success">{status}</span>
						)}
					</>
					) : (<>&middot;</>)}
				</td>
				
				<td className="text-center px-1 fw-bolder">
					{(parseInt(id)||0) > 0 ? (
					<>
						{UFunc.formatAngka(qty)}
					</>
					) : (<>&middot;</>)}
				</td>

				<td className="text-center px-1">
					{vKeys <= 0 || vDatalastObj.id === id  ? (
						<big className="fw-bolder">{UFunc.formatAngka(jml_saldo)}</big>
					) : (
						<span>{UFunc.formatAngka(jml_saldo)}</span>
					)}
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		console.log("(hdlKlikDlglookuppilih) contentDlgform => "+uDlgformObj.barang_caption);

		return (
		<CForm>
		<CRow className="my-2 mx-0">
		<CCol>Periode</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="mx-1">&nbsp;</span>

			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		
		<CRow className="my-2 mx-0">
		<CCol>Nama Barang</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
				<CFormInput size="sm"
					readOnly={true}
					value={uDlgformObj.barang_caption||""}
					onClick={hdlKlikBaranglookup}
					placeholder="--Pilih Nama Barang.."
					id="inpbarangcaption"/>
				<CButton onClick={hdlKlikBaranglookup} color="dark" size="sm" id="btnbaranglookup">
					<CIcon icon="cilSearch"/>
				</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		
		</CForm>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Laphistoristok - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 7;
		const vDataviewArr	= uDataviewArr;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"<div><small>"+uTitelObj.subtitel+"</small></div>"+
			"";
		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b></div>
		`;

		const vTabelheader	= `
			<th width="5%">No</th>
			<th width="11%" align="left">Tanggal</th>
			<th width="10%">No.Ref</th>
			<th align="left">`+(uBahasaObj.word_keterangan||"Keterangan")+`</th>
			<th width="10%">`+(uBahasaObj.word_status||"Status")+`</th>
			<th width="7%">`+(uBahasaObj.word_jml||"JML")+`</th>
			<th width="8%">`+(uBahasaObj.word_saldo||"Saldo")+`</th>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,tgl_proses,tgl_inputs,ket,status,qty,
				jml_saldo,noref,
			} = vItems;
			vNumber++;

			vTabelbody += `
				<tr valign="top" key=`+vKeys+`>
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+
					(parseInt(id)>0 
						? ((tgl_proses||"")!=="" 
							? `<div>`+UFunc.TglAngka(tgl_proses)+`</div>
							<div><small>(Input: {UFunc.WaktuAngka(tgl_input)})</small></div>`
							: UFunc.TglAngka(tgl_inputs))
						: "&nbsp;")+
				`</td>
				<td align="center">`+(parseInt(id)>0 ? (noref||"-") : "&nbsp;")+`</td>
				<td align="left">`+(ket||"-")+`</td>
				<td align="center">`+(parseInt(id)>0 ? (status||"-") : "&nbsp;")+`</td>
				<td align="center">`+(parseInt(id)>0 ? UFunc.formatAngka(qty) : "&nbsp;")+`</td>
				<td align="center">`+
					((vKeys <= 0 || vKeys >= uDataviewArr.length-1)  
						? "<b>"+UFunc.formatAngka(jml_saldo)+"<b>"
						: UFunc.formatAngka(jml_saldo))+
				`</td>
				</tr>
			`;
		}).join("");
		
		const vTabelfooter 	= ``;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	const contentExcel = () => {
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";
		const vBarangcaption	= (uDataloadedObj||{}).barang_caption;

		const vColspan 	= 7;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;

		let vTmpObj	= {
			no : "",
			tgl_mutasi: "",
			noref: "",
			keterangan: "",
			status_mutasi: "",
			qty: "",
			jml_saldo: "",
		}
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		//console.log("uDataviewArr => "+JSON.stringify(uDataviewArr));
		uDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_input,tgl_proses,tgl_inputs,ket,status,qty,
				jml_saldo,noref,
			} = vItems;

			vColposwidth = (ket||"").length > vColposwidth 
				? (ket||"").length : vColposwidth; 

			if(parseInt(id) <= 0)
				vTmpObj	= {
					no : {v:vKeys+1,t:"n"},
					tgl_mutasi: "",
					noref: "",
					keterangan: (ket||"Undf"),
					status_mutasi: "",
					qty: "",
					jml_saldo: { v:parseInt(jml_saldo), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
				}
			else
				vTmpObj	= {
					no : {v:vKeys+1,t:"n"},
					tgl_mutasi: { v:tgl_inputs, t:"d", s:{alignment:{horizontal:"left"}} },
					noref: { v:(noref||"-"), t:"s", s:{alignment:{horizontal:"center"}} },
					keterangan: (ket||"Undf"),
					status_mutasi: { v:status, t:"s", s:{alignment:{horizontal:"center"}} },
					qty: { v:parseInt(qty), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
					jml_saldo: { v:parseInt(jml_saldo), t:"n", s:{alignment:{horizontal:"center"},numFmt: "#,##0"} },
				}
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:47}]
		const wscols	= [
			{wpx:40},
			{wpx:100},
			{wpx:120},
			{wch:vColposwidth},
			{wpx:90},
			{wpx:75},
			{wpx:75},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Laphistoristok_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
			"\r\n "+uTitelObj.subtitel+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"No.Ref",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Keterangan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Status Mutasi",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Jml",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Saldo",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500(); 
		setDataloadedObj();
		setSortedObj({});
		setDatabarangArr([]);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laphistoristok/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Laphistoristok-apiLoadinit) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Laphistoristok-apiLoadinit) output_string.dataidentitas : "+(output_string.dataidentitas));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laphistoristok-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Laphistoristok-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laphistoristok/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Laphistoristok-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				hdlDlgformToggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laphistoristok-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Laphistoristok - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlDlgformToggle();setDlglookupshow(false);
			setDataloadedObj(false);
			setDatavieworiArr([]);
			setSortedObj({});
			setDataidentitasObj({}); setDatabarangArr([]);
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		/*const vSumnilaibefore = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return vNilaistored + parseFloat(vItems.nilai_before||"0")
		}, 0);
		const vSumnilaitambah = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return vNilaistored + parseFloat(vItems.nilai_tambah||"0")
		}, 0);
		const vSumnilaikurang = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return vNilaistored + parseFloat(vItems.nilai_kurang||"0")
		}, 0);
		const vSumnilaisaldo = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return vNilaistored + parseFloat(vItems.nilai_saldo||"0")
		}, 0);

		uDataloadedObj && (uDataloadedObj.sum_nilai_before 	= vSumnilaibefore);
		uDataloadedObj && (uDataloadedObj.sum_nilai_tambah 	= vSumnilaitambah);
		uDataloadedObj && (uDataloadedObj.sum_nilai_kurang 	= vSumnilaikurang);
		uDataloadedObj && (uDataloadedObj.sum_nilai_saldo 	= vSumnilaisaldo);//-*/
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.barang_id === undefined)
			uDlgformObj.barang_id = uDataloadedObj ? uDataloadedObj.barang_id : 0;
		if(uDlgformObj.barang_caption === undefined)
			uDlgformObj.barang_caption = uDataloadedObj ? uDataloadedObj.barang_caption : "";

		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpbulanid");
			vElfocus && vElfocus.focus();
		},350);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{setDlglookupObj({}); return}
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 		= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		uTitelObj.subtitel 		= uDataloadedObj.barang_caption;
	},[uDataloadedObj]);
	/*React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);//*/
	
	//console.log("Laphistoristok - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			{uTitelObj.subtitel && (
				<span className="text-primary classfontsmaller"><small>{uTitelObj.subtitel}</small></span>
			)}
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialoglookup
			show={uISDlglookupshow}
			dialogHeader={uDlglookupObj.dlg_header||""}
			inputValue={uDlglookupObj.dlg_keyword||""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			toggle={()=>setDlglookupshow(false)}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlDlgformToggle}
			renderContent={contentDlgform()}/>
		<iframe id="ifmcontentstoprint" className="d-none"/>
	</>
	)//>
}	

export default Laphistoristok
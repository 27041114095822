import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderNav,CHeaderToggler,CHeaderDivider,CHeaderBrand,CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,CFormCheck,CFormSwitch,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,CAvatar,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import NumberFormat from 'react-number-format';
import {
	MyButton,
	MyDialogform,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Userrole = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 				= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uUserid 					= parseInt(id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uIsEOMExecuted			= useSelector(state => state.gIsEOMExecuted);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uTimeelapsed,setTimeelapsed]		= React.useState(0); 

	const [uProsesloading,setProsesloading] = React.useState(false);
	const [uDatamainObj,setDatamainObj]		= React.useState({})

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = async() => {
		if(uHTMLError500) apiLoaddata();
		else {
			if(await(Konfirm("<b>Refresh Tampilan Data..</b>"+
				"<br/>Apakah Anda Yakin ?"+
				""))) apiLoaddata();
		}
	}
	const hdlKlikBack = () => {
		if(uUrlbefore === "") { uNavigate(-1); return }
		uNavigate(uUrlbefore,{replace:true})
	}
	const hdlChangedefault = (_VALUE) => {
		if(_VALUE === "CUSTOM") {
			const vMenuitemArr 		= uDatamainObj.module_arr || [];
			uDatamainObj.roleid_arr = vMenuitemArr.map(vItems=>vItems.id);
			setEfekview(!uEfekview);
		} else {
			apiSettodefault();
		}
	}
	const hdlChangerole = (_EV) => {
		const vValueID = parseInt(_EV.target.value)||0; if(vValueID<=0) return;

		const vISChecked = _EV.target.checked;
		apiSetcustom(vValueID,vISChecked);
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentHeadersub = () => {
		if(isMobile) return "";
		if(uProsesloading) return (<><div/><small className="fst-italic text-primary classblinking">Loading..</small><div/></>)//>

		if(uHTMLError500) return (
		<>
			<b>Page 500</b>
			<CTooltip content="--Refresh..">
			<CLink 
				onClick={()=>hdlKlikReset()}
				className="classcursorpointer classikon classikonreset"/>
			</CTooltip>
		</>
		)//>

		return (
		<>
		<div>
			<CAvatar src={pjson.homepage+"api/images/img_"+(uDatamainObj.userhak||"ENTRI").toLowerCase()+".png"} height={16}/>
			<big className="fw-bolder text-info ms-2">{uDatamainObj.usercaption || "UNDF"}</big>
			<span className="d-none d-md-inline">
				<span className="mx-2">&middot;</span>
				(<span className="fst-italic">{uDatamainObj.username}</span>)
			</span>
			<span className="mx-2">&middot;</span>
			<small className="text-danger">[{uBahasaObj["hakuser_"+(uDatamainObj.userhak||"").toLowerCase()]||"Front Officex"}]</small>
		</div>
		<CTooltip content="--Refresh..">
		<CLink 
			onClick={()=>hdlKlikReset()}
			className="classcursorpointer classikon classikonreset"/>
		</CTooltip>
		</>
		)//>
	}
	const contentMain = () => {
		if(isMobile || uProsesloading) return "";

		if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>
		
		const vMenuitemArr		= uDatamainObj.module_arr || [];
		const vRoleidselectArr	= uDatamainObj.roleid_arr || [];

		const vISDefaultrole	= vRoleidselectArr.length <= 0;
		return (
		<CCard className="mx-lg-5 classbgcard mb-3">
		<CCardHeader className="d-flex justify-content-between">
			<small>Atur Individual Role</small>
			<span className="classfontsmaller text-muted"><small>{UFunc.formatAngka(uTimeelapsed)}ms</small></span>
		</CCardHeader>
		
		<CCardBody className="px-0">
		<div className="d-none">{vISDefaultrole}</div>
		<table className="table table-borderless" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="">&nbsp;</th>
			<th className="align-top px-1 text-start">Nama Menu</th>
			<th width="30%" className="align-top px-1">
				<CFormCheck size="sm"
					button={{ color: 'primary', variant: 'outline' }} 
					type="radio" 
					name="inpjenisrole" 
					id="inpjenisroledefault"
					value="DEFAULT"
					onChange={(e)=>hdlChangedefault(e.target.value)}
					checked={vISDefaultrole}
					label="Default Role"/>
			</th>
			<th width="30%" className="align-top px-1">
				<CFormCheck size="sm"
					button={{ color: 'primary', variant: 'outline' }} 
					type="radio" 
					name="inpjenisrole" 
					id="inpjenisrolecustom"
					value="CUSTOM"
					onChange={(e)=>hdlChangedefault(e.target.value)}
					checked={!vISDefaultrole}
					label="Custom Role"/>
			</th>
			</tr>
		</thead>
		<tbody>
		{vMenuitemArr.map((vItems,vKeys)=>{
			const {
				id, nama, levels
			} = vItems;

			const vLevels = parseInt(levels)||1;
			const vClassadd = vLevels < 2 ? "fw-bold text-uppercase"
				: "ps-3 "+(vLevels > 2 ? "ps-5 classfontsmaller " : "text-info "); 

			return (
			<tr className="classborderbottom" key={vKeys}>
			<td className="text-center">&nbsp;</td>
			<td className={"align-middle text-start px-0 "+vClassadd}>{uBahasaObj["menus_"+(nama||"").toLowerCase()]||(nama||"")}</td>

			<td className="text-center">
				<CIcon icon="cilCheckAlt" className={vISDefaultrole?"text-primary":"text-muted"} height={25}/>
			</td>

			<td className="text-center">
				<CFormSwitch size="lg"
					checked={vRoleidselectArr.includes(vItems.id)}
					className="d-inline-block"
					disabled={vISDefaultrole}
					value={vItems.id}
					onChange={(e)=>hdlChangerole(e)}
					name="inprole"
					id={"inprole"+vItems.id}/>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</CCardBody>
		</CCard>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setDatamainObj({})
		setLoading(true);setProsesloading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Userrole - apiLoaddata) LEWAT ");
		let vTmpObj	= {}
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); setLoading(false);setProsesloading(false);
			setDatamainObj(vTmpObj);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_userid : uUserid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/ld_role";

		setTimeelapsed(0); const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);setProsesloading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"{}")
				setDatamainObj(vTmpObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Userrole - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);setProsesloading(false);
			console.log("(Userrole - apiLoaddata) catch-error : "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSettodefault = () => {
		setLoading(true)
		const vEls = document.getElementsByName("inpjenisrole");
		vEls && (vEls.disabled = true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			vEls && (vEls.disabled = false);

			uDatamainObj.roleid_arr = [];
			setEfekview(!uEfekview)
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_userid : uUserid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_roledefault";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vEls && (vEls.disabled = false);
			if(output_string.sukses) {
				uDatamainObj.roleid_arr = [];
				setEfekview(!uEfekview)
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Userrole - apiSettodefault) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			setLoading(false);
			vEls && (vEls.disabled = false);

			console.log("(Userrole - apiSettodefault) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSetcustom = (_VALUE,_ISCHECKED) => {
		_VALUE = parseInt(_VALUE)||0; if(_VALUE<=0) return;
		const vISChecked = _ISCHECKED || false;

		setLoading(true)
		const vEls = document.getElementsByName("inprole");
		vEls && (vEls.disabled = true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_userid : uUserid,
			send_modulid: _VALUE,
			send_jeniscustom: vISChecked ? "TAMBAH" : "KURANG",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_rolecustom";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vEls && (vEls.disabled = false);
			if(output_string.sukses) {
				if(!vISChecked) {
					const vArridx = (uDatamainObj.roleid_arr||[]).indexOf(_VALUE.toString());
					if(vArridx >= 0)
						(uDatamainObj.roleid_arr||[]).splice(vArridx,1);
				} else {
					(uDatamainObj.roleid_arr||[]).push(_VALUE.toString());
				}
				setEfekview(!uEfekview);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Setrole - apiSetcustom) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			setLoading(false);
			vEls && (vEls.disabled = false);

			console.log("(Setrole - apiSetcustom) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		//setDlgviewshow(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
    		setDatamainObj({});
    	}
	},[])
	//---END EFFECT--/

	//console.log("(Userrole) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain border-bottom" style={{minHeight:64}}>
			<CHeaderToggler className="px-1"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-1" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="me-auto d-md-block">
				<div className="my-0"><b>{uBahasaObj.caption_setrole||"Set Individual Role"}</b></div>
			</CHeaderNav>

			<CHeaderNav className="ms-3">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white p-2 px-md-3 align-items-center" style={{minHeight:52}}>
			{contentHeadersub()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2">
		<CContainer fluid className="px-0 px-lg-5">
			{contentMain()}
		</CContainer>
		</div>
		</div>
		</>
	)//>
}	

export default Userrole
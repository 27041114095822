import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CSubheader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CFormTextarea,
	CLink,
	CTooltip,
	CInputGroup,
	CInputGroupText,
	CButton,
	CListGroup,
	CListGroupItem,
	CBadge,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CSpinner,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyButton,
	MyDialoglookup,
	MyDialogform,
	MyDialogview,
	MyProfileDetil,
} from '../../components/index';
import { UFunc,cKeycodebypass } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

let pjson    			 = require('../../../package.json');
const Kasirklinikmain 	= (props) => {
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 
	const { customer_id } 	= useParams(); 

	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uDefaultSetting	= useSelector(state => state.gListDefaultSetting);
	const uURLbefore		= useSelector(state => state.gUrlbefore);
	const uHTMLstylenota	= useSelector(state => state.gHTMLstyleNota);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]			= React.useState(false);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	
	const uCustomerID						= parseInt(customer_id) || 0;

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	const uDataKasGLArr						= JSON.parse(localStorage.getItem("kasgllist")||"[]");

	//--MAIN_FORM--/
	const [uDataloadoriObj,setDataloadoriObj]= React.useState({});
	const [uDatapaymentArr,setDatapaymentArr]= React.useState([]);
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	const [uDataongoingObj,setDataongoingObj]= React.useState(null);
	const [uDataidentitas,setDataidentitas]	= React.useState({});
	const [uDatasetting,setDatasetting]		= React.useState({});
	const [uDataitemArr,setDataitemArr]		= React.useState([]);
	const [uDatasupplierArr,setDatasupplierArr]	= React.useState([]);

	const [uDataCetakObj,setDataCetakObj]	= React.useState(null);
	const [uTabelIDselect,setTabelIDselect] = React.useState(0);
	const [uTabelRowselect,setTabelRowselect]= React.useState(-1);
	const [uRunningtime,setRunningtime]		= React.useState("");
	const [uIsLoadawal,setLoadawal]			= React.useState(false);
	const [uIsFormupdated,setFormupdated]	= React.useState(false);
	//--END MAIN_FORM--/

	//--TABEL_VARS--/
	const [uFormtabelObj,setFormtabelObj]	= React.useState({});
	//--END TABEL_VARS--/

	//--DIALOG_FORM--/
	const [uIsDlgformshow,setIsDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	
	const uUrllogoDefault	= pjson.homepage+"api//images/header_nota.png";
	const uELDlgsimpan		= document.getElementById("btnDialogSimpan");
	//--ENDDIALOG_FORM--/

	//--DIALOG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DIALOG_LOOKUP--/

	//--DIALOG_VIEW--/
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewheader,setDlgviewheader]	= React.useState("");
	//--END DIALOG_VIEW--/

	//--DOM_MAIN--/
	const uELBtnsimpanmain	= document.getElementById("btnBayar");
	//--END DOM_MAIN--/

	//---HANDEL---/
	const hdlToggleDlgform = () => {setIsDlgformshow(false)}
	const hdlToggleLookup = () => {setDlglookupshow(false)}
	const hdlToggleDlgview = () => {setDlgviewshow(false)}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.jenis === "MAIN") 
			{ prosesMainsimpan(); return }
		else if(uDlgformObj.jenis === "TAMBAHTABEL") 
			{ prosesTambahtabel(); return }
	}
	const hdlKlikDLglookuppilih = (_IDITEM) => {
		//_IDITEM = parseInt(_IDITEM);
		const vDatalookupArr = uDlglookupObj.data_arr || [];
		const vObjidx = UFunc.getObjectIndeks(vDatalookupArr,_IDITEM,"id");
		if(vObjidx < 0) return;

		let vElfocus;
		if(uDlglookupObj.jenis === "PAYMENT") {
			vElfocus = document.getElementById("inpnilaibtransfer");
			uFormdataObj.payment_id		= parseInt(vDatalookupArr[vObjidx].id)||0;
			uFormdataObj.payment_caption= (vDatalookupArr[vObjidx].caption);
		} else if(uDlglookupObj.jenis === "DLGFORMPASIEN") {
			uDlgformObj.pasien_id		= parseInt(vDatalookupArr[vObjidx].id)||0;
			uDlgformObj.pasien_caption	= (vDatalookupArr[vObjidx].caption);

			vElfocus = document.getElementById("inpdlgformitemcaption");
		} else if(uDlglookupObj.jenis === "DLGFORMITEM") {
			//console.log("vDatalookupArr =>"+JSON.stringify(vDatalookupArr[vObjidx]));
			uDlgformObj.item_id			= vDatalookupArr[vObjidx].idx;
			uDlgformObj.item_caption	= vDatalookupArr[vObjidx].caption;
			uDlgformObj.item_nama		= vDatalookupArr[vObjidx].nama;
			uDlgformObj.item_satuan		= vDatalookupArr[vObjidx].satuan;
			uDlgformObj.item_qty		= 1;
			uDlgformObj.item_harga		= vDatalookupArr[vObjidx].harga_satuan;
			uDlgformObj.item_jenis		= vDatalookupArr[vObjidx].jenis;//--BARANG_JASA
			uDlgformObj.item_pihaklain	= vDatalookupArr[vObjidx].pihaklain;//--YA_TIDAK
			uDlgformObj.item_pihaklainlunas	= "HUTANG";//--LUNAS-HUTANG
			setEfekview(!uEfekview);

			if((vDatalookupArr[vObjidx].pihaklain||"TIDAK") !== "YA")
				vElfocus = document.getElementById("inpdlgformitemqty");
			else
				vElfocus = document.getElementById("inpdlgformitemplainlunas");
		} else if(uDlglookupObj.jenis === "DLGFORMSUPPLIER") {
			uDlgformObj.item_pihaklainsupplierid		= parseInt(vDatalookupArr[vObjidx].id)||0;
			uDlgformObj.item_pihaklainsuppliercaption	= (vDatalookupArr[vObjidx].caption);

			if((uDlgformObj.item_pihaklainlunas||"BELUM") === "LUNAS")
				vElfocus = document.getElementById("inpdlgformitemkascaption");
			else
				vElfocus = document.getElementById("inpdlgformitemqty");
		} else if(uDlglookupObj.jenis === "DLGFORMKAS") {
			uDlgformObj.item_pihaklainkasid		= parseInt(vDatalookupArr[vObjidx].id)||0;
			uDlgformObj.item_pihaklainkascaption= (vDatalookupArr[vObjidx].caption);

			vElfocus = document.getElementById("inpdlgformitemketbayar");
		} 

		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			if(!isMobile)
				vElfocus && vElfocus.focus();
		},550)
	}
	const hdlKDownDocument = (_EVN) => {
		/*if(_EVN.which === 113) {
			_EVN.preventDefault();
			_EVN.stopPropagation();
			uELBtnsimpanmain && uELBtnsimpanmain.click(); return
			document.getElementById("btnPaymentlookup") &&
				document.getElementById("btnPaymentlookup").click(); return 
		}//-*/
		if(_EVN.which === 120) { 
			_EVN.preventDefault();
			_EVN.stopPropagation();
			uELBtnsimpanmain && uELBtnsimpanmain.click(); return
		}
		//console.log("(Kasirklinikmain - hdlKDownDocument) _EVN.which = "+_EVN.which)
	}
	const hdlKlikBack = async() => { 
		if(uIsFormupdated)
			{ if(await(Konfirm(
				"<b>Form Pembayaran Telah Berubah..</b>"+
				"<br/>Apakah Yakin Akan Kembali ?"+
				""))) uNavigate(-1)}
		else uNavigate(-1) 
	}
	const hdlKlikrefresh = async() => {
		if(uHTMLError500) 
			{ apiLoaddata(); return }

		if(uIsFormupdated)
			{ if(await(Konfirm(uBahasaObj.confirm_reset))) prosesMainreset() }
		else apiLoaddata();
	}
	const hdlKlikViewtreatment = (_ONGOINGID) => {
		_ONGOINGID = parseInt(_ONGOINGID)||0
		if(_ONGOINGID <= 0) return

		const vOngoingArr 	= uDataloadoriObj.ongoing_arr || []
		const vDataArr		= vOngoingArr.filter(vItems => 
			vItems.ongoing_id.toString() === _ONGOINGID.toString());
		const vDataonhoingobj	= (vDataArr.length > 0) ? vDataArr[0].ongoing_obj : null

		setDlgviewheader(uBahasaObj.word_pemeriksaan+": "+
			vDataArr[0].pasien_caption+
			" ("+(vDataArr[0].layanan_caption||"").toUpperCase()+")")

		//console.log("(Kasirklinikmain - hdlKlikViewtreatment) vDataonhoingobj : "+JSON.stringify(vDataonhoingobj))
		if((vDataonhoingobj||null)!== null) 
			{ setDataongoingObj(vDataonhoingobj); setDlgviewshow(true); return }

		apiLoadongoing(_ONGOINGID)
	}
	const hdlKlikViewfoto = (_ONGOINGID) => {}
	const hdlKliktabeltambah = () => {
		uDlgformObj.jenis	= "TAMBAHTABEL";
		uDlgformObj.headers = (uBahasaObj.caption_itemtreatment||"Item Treatment")+" Tambahan";

		/*const vFirstObj		= (uDataloadoriObj.ongoing_arr || []).filter(
			(_,vKeys)=> vKeys<=0 )[0];
		console.log("(hdlKliktabeltambah) vFirstObj => "+JSON.stringify(vFirstObj));//-*/

		uDlgformObj.pasien_caption 	= (uDataloadoriObj.ongoing_arr || [])[0].pasien_caption; 
		uDlgformObj.pasien_id 		= (uDataloadoriObj.ongoing_arr || [])[0].pasien_id;
		setIsDlgformshow(true);
	}
	const hdlKlikDlgformpasienlookup = () => {
		const vOngoingArr	= uDataloadoriObj.ongoing_arr || [];

		uDlglookupObj.headers = ((uBahasaObj.word_pilih||"word_pilih")+
			" "+(uBahasaObj.word_pasien||"Pasien"))

		const vLookupArr	= vOngoingArr.map(vItems=>{
			return {id:vItems.pasien_id,caption:vItems.pasien_caption+" ("+vItems.pasien_id+")"}
		});
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.jenis		= "DLGFORMPASIEN";
		setDlglookupshow(true);
	}
	const hdlKlikDlgformitemlookup = () => {
		let vLookupArr =  uDataitemArr.map((vItems,vKeys)=>{
			return {
				id:vItems.id.toString()+(vItems.jenis||"B").charAt(0),
				idx:vItems.id,
				caption:vItems.caption,
				nama:vItems.nama,
				jenis:vItems.jenis,
				harga_satuan:vItems.h_satuan,
				satuan:vItems.satuan,
				pihaklain: vItems.pihaklain
			};
		})
		if((parseInt(uDlgformObj.pasien_id)||0) <= 0) {
			vLookupArr = vLookupArr.filter(vItems =>  (vItems.jenis||"").toUpperCase() === "BARANG");
		} 
		//console.log("(Kasirklinikmain - hdlKlikDlgformitemlookup) vLookupArr => "+JSON.stringify(vLookupArr.filter(vItems=>vItems.jenis==="JASA")));
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.jenis		= "DLGFORMITEM";
		uDlglookupObj.inputvalue= uDlgformObj.item_caption||"";
		uDlglookupObj.headers 	= ((uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.caption_itemterapi||"caption_itemterapi"));
		setDlglookupshow(true);
	}
	const hdlKDownDlgformitemcaption = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDlgformObj.item_id 		= undefined;
			uDlgformObj.item_nama 		= undefined;
			uDlgformObj.item_qty 		= undefined;
			uDlgformObj.item_harga 		= undefined;
			uDlgformObj.item_satuan 	= undefined;
			uDlgformObj.item_jenis 		= undefined;
			uDlgformObj.item_pihaklain 	= undefined;
		}

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDlgformObj.item_caption||"").trim()==="") { setEfekview(!uEfekview); return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		let vArrCariMaster 	= uDataitemArr.filter((vItems)=>
			(vItems.caption||"").toUpperCase().includes((uDlgformObj.item_caption||"").toUpperCase())
		);
		if((parseInt(uDlgformObj.pasien_id)||0) <= 0) {
			vArrCariMaster = vArrCariMaster.filter(vItems =>  (vItems.jenis||"").toUpperCase() === "BARANG");
		}
		
		if (vArrCariMaster.length === 1) {
			//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
			uDlgformObj.item_id			= vArrCariMaster[0].id;
			uDlgformObj.item_caption	= vArrCariMaster[0].caption;
			uDlgformObj.item_nama		= vArrCariMaster[0].nama;
			uDlgformObj.item_satuan		= vArrCariMaster[0].satuan;
			uDlgformObj.item_qty		= 1;
			uDlgformObj.item_harga		= vArrCariMaster[0].h_satuan;
			uDlgformObj.item_jenis		= vArrCariMaster[0].jenis;//--BARANG_JASA
			uDlgformObj.item_pihaklain	= vArrCariMaster[0].pihaklain;//--YA_TIDAK
			uDlgformObj.item_pihaklainlunas	= "";//--LUNAS-HUTANG

			setEfekview(!uEfekview);

			let vElfocus;
			if(vArrCariMaster[0].pihaklain !== "YA")
				vElfocus = document.getElementById("inpdlgformitemqty");
			else
				vElfocus = document.getElementById("inpdlgformitemplainlunas");

			let vTimeout = setTimeout(function(){
				clearTimeout(vTimeout);
				vElfocus && vElfocus.focus();
			},350);

			return;
		} 

		hdlKlikDlgformitemlookup();
	}
	const hdlKlikDlgformsupplierlookup = () => {
		const vLookupArr	=  uDatasupplierArr.map((vItems,vKeys)=>{
			return {
				id:vItems.id,
				caption:vItems.caption_supplier,
				nama:vItems.nama_supplier,
			};
		})
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.jenis		= "DLGFORMSUPPLIER";
		uDlglookupObj.inputvalue= uDlgformObj.item_pihaklainsuppliercaption||"";
		uDlglookupObj.headers 	= ((uBahasaObj.word_lookup||"word_lookup")+
			": "+(uBahasaObj.caption_supplierplain||"Supplier Pihak Lain"));
		setDlglookupshow(true);
	}
	const hdlKDownDlgformsuppliercaption = (_EV) => {
		if(!cKeycodebypass.includes(_EV.keyCode)) {
			uDlgformObj.item_pihaklainsupplierid 	= undefined;
		}

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
		if((uDlgformObj.item_pihaklainsuppliercaption||"").trim()==="") { setEfekview(!uEfekview); return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			(vItems.caption_supplier||"").toUpperCase().includes((uDlgformObj.item_pihaklainsuppliercaption||"").toUpperCase())
		);
		
		//console.log("vArrCariMaster =>"+JSON.stringify(vArrCariMaster));
		if(vArrCariMaster.length === 1) {
			uDlgformObj.item_pihaklainsupplierid		= vArrCariMaster[0].id;
			uDlgformObj.item_pihaklainsuppliercaption	= vArrCariMaster[0].caption_supplier;
			setEfekview(!uEfekview);

			let vElfocus;
			let vTimeout = setTimeout(function(){
				clearTimeout(vTimeout);
				if((uDlgformObj.item_pihaklainlunas||"BELUM") === "LUNAS")
					vElfocus = document.getElementById("inpdlgformitemkascaption");
				else
					vElfocus = document.getElementById("inpdlgformitemqty");

				if(!isMobile) vElfocus && vElfocus.focus();
			},450);

			return;
		} 

		hdlKlikDlgformsupplierlookup();
	}
	const hdlKlikDlgformkaslookup = () => {
		const vLookupArr	=  uDataKasGLArr.map((vItems,vKeys)=>{
			return {
				id:vItems.value,
				caption:vItems.caption,
			};
		})
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.jenis		= "DLGFORMKAS";
		uDlglookupObj.inputvalue= "";
		uDlglookupObj.headers 	= ((uBahasaObj.word_lookup||"word_lookup")+
			": KAS PEMBAYARAN");
		setDlglookupshow(true);
	}

	const hdlKliktabeledit = (_IDXTABEL,_ITEMFIELD) => {
		_IDXTABEL 		= _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL);
		_ITEMFIELD 		= (_ITEMFIELD||"").trim().toUpperCase();
		if((uFormtabelObj.isProcessing||false)===true) return

		if(_IDXTABEL < 0 || _ITEMFIELD==="") {
			prosesToggletabel()
			return
		}

		const vItemArr 	= uFormdataObj.items_arr || []

		let vElsfocus ;
		if(_ITEMFIELD === "ITEMCAPTION") {
			vElsfocus = "inptabelitemcaption";
			uFormtabelObj.value = (vItemArr[_IDXTABEL].item_cetak)
		} else if(_ITEMFIELD === "ITEMQTY") {
			vElsfocus = "inptabelitemqty";
			uFormtabelObj.value = (vItemArr[_IDXTABEL].item_qty)
		} else if(_ITEMFIELD === "ITEMHARGA") {
			vElsfocus = "inptabelitemharga";
			uFormtabelObj.value = (vItemArr[_IDXTABEL].item_harga)
		}
		uFormtabelObj.init_field 	= _ITEMFIELD;
		uFormtabelObj.init_idx		= _IDXTABEL;
		setEfekview(!uEfekview)
		//console.log("(Kasirklinikmain - hdlKliktabeledit) _ITEMFIELD => "+_ITEMFIELD)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout); 
			document.getElementById(vElsfocus) && 
				document.getElementById(vElsfocus).focus();
		},350)
	}
	const hdlKDowntabelproses = (_IDXTABEL,_ITEMFIELD,_EVN) => {
		_IDXTABEL = _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL);
		_ITEMFIELD = (_ITEMFIELD||"").trim();
		if(_IDXTABEL < 0 || _ITEMFIELD==="") return

		if(_EVN.which === 13) {
			hdlKliktabelproses(_IDXTABEL,_ITEMFIELD); return
		} 
		if(_EVN.which === 27) {
			prosesToggletabel(); return
		}
	}
	const hdlKliktabelproses = (_IDXTABEL,_ITEMFIELD) => {
		_IDXTABEL 	= _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL);
		_ITEMFIELD 	= (_ITEMFIELD||"").trim();
		console.log("(Kasirklinikmain - hdlKliktabelproses) _ITEMFIELD => "+_ITEMFIELD)
		console.log("(Kasirklinikmain - hdlKliktabelproses) _IDXTABEL => "+_IDXTABEL)
		if(_IDXTABEL < 0 || _ITEMFIELD==="") return

		//---VALIDASI--/
		if(_ITEMFIELD==="ITEMCAPTION") {
			if((uFormtabelObj.value||"").trim()==="") {
				document.getElementById("inptabelitemcaption") && document.getElementById("inptabelitemcaption").focus()
				showToast(uBahasaObj.caption_itemtreatment+" "+uBahasaObj.caption_mustfilled+"..!"); return
			}
		}
		if(_ITEMFIELD==="ITEMQTY") {
			if((parseInt(uFormtabelObj.value)||0) <=0 ) {
				document.getElementById("inptabelitemqty") && document.getElementById("inptabelitemqty").focus()
				showToast(uBahasaObj.word_qty+" "+uBahasaObj.caption_invalid+"..!"); return
			}
		}
		if(_ITEMFIELD==="ITEMHARGA") {
			if((parseInt(uFormtabelObj.value)||0) < 0 ) {
				document.getElementById("inptabelitemharga") && document.getElementById("inptabelitemharga").focus()
				showToast(uBahasaObj.caption_hsatuan+" "+uBahasaObj.caption_invalid+"..!"); return
			}
		}
		//---END VALIDASI--/

		//--PROSES_DISINI--/
		//prosesToggletabel(); return;
		apiTabelproses(_IDXTABEL,_ITEMFIELD)
		//--END PROSES_DISINI--/
	}
	const hdlKlikTabelhapus = async(_IDXTABEL) => {
		_IDXTABEL 	= _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL);
		if((uFormtabelObj.isProcessing||false)===true) return

		if(_IDXTABEL < 0) { prosesToggletabel(); return }

		const vItemArr 	= uFormdataObj.items_arr || []
		const vObjidx	= _IDXTABEL;//UFunc.getObjectIndeks(vItemArr,_IDXTABEL);

		const vPesanhapus	=
		(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			"<b>"+(vItemArr[vObjidx].item_caption||"").toUpperCase()+"</b>.."+
		"<br/>"+(uBahasaObj.petunjuk_hapus)+
		"<br/>"+
		"<br/><b>"+uBahasaObj.confirm_hapus+"</b>"+
		"";

		if((parseInt(vItemArr[vObjidx].id)||0) <= 0) {
			if(await(Konfirm(vPesanhapus))) {
				vItemArr.splice(vObjidx,1)

				prosesTotalnilai()
				uDataloadoriObj.items_arr 	= vItemArr;
				uFormdataObj.items_arr 		= vItemArr;

				prosesToggletabel()
			}
			return;
		}

		if(await(Konfirm(vPesanhapus))) apiTabelhapus(_IDXTABEL)
	}
	const hdlKlikPaymentlookup = () => {
		uDlglookupObj.headers = ((uBahasaObj.word_pilih||"word_pilih")+
			" "+(uBahasaObj.caption_paymentmethod||"caption_paymentmethod"))

		const vLookupArr	= uDatapaymentArr.map((vItems)=>{
			return {id:vItems.id,caption:vItems.caption}
		})
		uDlglookupObj.data_arr 	= vLookupArr;
		uDlglookupObj.jenis		= "PAYMENT";

		uFormdataObj.payment_id 		= undefined;
		uFormdataObj.payment_caption 	= undefined;
		setDlglookupshow(true);
	}
	const hdlKDownPaymentlookup = (_EVN) => {
		/*if(_EVN.which === 13) {
			_EVN.preventDefault()
			_EVN.stopPropagation()

			hdlKlikPaymentlookup()
			return
		}//-*/
	}
	const hdlKlikBayar = () => {
		const vItemArr	= uFormdataObj.items_arr || [];

		//--VALIDASI_INPUT--/
		let vElsfocus
		if(vItemArr.length <= 0) {
			showToast((uBahasaObj.caption_itemtreatment)+" "+(uBahasaObj.caption_notexists||"caption_notexists")+".."); return
		}
		//--CEK_ITEM_DG_PASIEN--/
		const vItemwithpasienArr	= vItemArr.filter(vItems=>(parseInt(vItems.pasien_id)||0) > 0);
		if(vItemwithpasienArr.length <= 0) {
			showToast("Item Treatment Harus Merujuk ke Pasien.."); return;
		}
		//--END CEK_ITEM_DG_PASIEN--/

		/*vElsfocus = document.getElementById("inppaymentcaption")
		if((parseInt(uFormdataObj.payment_id)||0) <= 0) {
			vElsfocus && vElsfocus.focus()
			showToast(uBahasaObj.caption_paymentmethod+" "+uBahasaObj.caption_mustchoose+"..");return
		}
		if((uFormdataObj.payment_caption||"").toUpperCase() === "TUNAI")
			{ uFormdataObj.payment_ket = ""; setEfekview(!uEfekview); }//-*/
		//--END VALIDASI_INPUT--/

		uDlgformObj.jenis	= "MAIN";
		uDlgformObj.headers = (uBahasaObj.caption_pembayarankasir||"caption_pembayarankasir")
		setIsDlgformshow(true)
	}
	//---END HANDEL---/

	//---PROSESFUNCTION--/
	const prosesMainreset = () => {
		setFormdataObj({...uDataloadoriObj,customer_id:uCustomerID});
		prosesToggletabel();
	}
	const prosesTotalnilai = () => {
		const vItemArr 	= uFormdataObj.items_arr || []
		const vNilaiarr	= vItemArr.map(vItems => parseInt(vItems.item_qty)*parseInt(vItems.item_harga) )
		const vTotals	= vNilaiarr.reduce((a,b)=>parseInt(a)+parseInt(b),0)
		uFormdataObj.nilai_bruto	= vTotals; setEfekview(!uEfekview)
	}
	const prosesToggletabel = () => {
		setFormtabelObj({});
		setEfekview(!uEfekview)
	}
	const prosesTambahtabel = () => {
		if(uDlgformObj.jenis !== "TAMBAHTABEL") return; 

		//---VALIDASI--/
		let vElsfocus 	= document.getElementById("inpdlgformitemcaption")
		if((parseInt(uDlgformObj.item_id)||0) <= 0) {
			vElsfocus && vElsfocus.focus()
			showToast("Item Treatment "+uBahasaObj.caption_mustchoose+".."); return
		}
		const vArridx	= uDataitemArr.findIndex(vItems => {
			return (parseInt(vItems.id) === parseInt(uDlgformObj.item_id)) &&
				(vItems.jenis||"").toUpperCase()===(uDlgformObj.item_jenis||"").toUpperCase();
		});
		if(vArridx < 0) {
			vElsfocus && vElsfocus.focus()
			showToast("Item Treatment "+uBahasaObj.caption_invalid+".."); return
		}
		//--CEK_ITEM_SUDAH_ADA--/
		//console.log("uFormdataObj.items_arr => "+JSON.stringify(uFormdataObj.items_arr||[]));
		//console.log("uDlgformObj => "+JSON.stringify(uDlgformObj));
		const vItemexistedArr = (uFormdataObj.items_arr || []).filter(vItems => {
			return ((parseInt(vItems.pasien_id)||0) === parseInt(uDlgformObj.pasien_id))
				&& ((parseInt(vItems.item_id)||0) === parseInt(uDlgformObj.item_id))
		});
		if(vItemexistedArr.length > 0) {
			vElsfocus && vElsfocus.focus()
			showToast((uBahasaObj.pesan_itemisexists||"pesan_itemisexists"));return;
		}
		//--END CEK_ITEM_SUDAH_ADA--/

		if((uDlgformObj.item_pihaklain||"TIDAK").toUpperCase()==="YA") {
			vElsfocus = document.getElementById("inpdlgformitemplainlunas");
			if((uDlgformObj.item_pihaklainlunas||"")!=="LUNAS"
				&& (uDlgformObj.item_pihaklainlunas||"")!=="HUTANG"
				) {
				vElsfocus && vElsfocus.focus()
				showToast("Status Pelunasan Pihak Lain "+uBahasaObj.caption_mustchoose+".."+
					""); return
			}

			vElsfocus = document.getElementById("inpdlgformsuppliercaption");
			if((parseInt(uDlgformObj.item_pihaklainsupplierid)||0) <= 0) {
				vElsfocus && vElsfocus.focus()
				showToast("Supplier Pihak Lain "+uBahasaObj.caption_mustchoose+".."); return
			}
		
			if((uDlgformObj.item_pihaklainlunas||"")==="LUNAS") {
				vElsfocus = document.getElementById("inpdlgformitemkascaption");
				if((parseInt(uDlgformObj.item_pihaklainkasid)||0) <= 0) {
					vElsfocus && vElsfocus.focus()
					showToast("Asal Kas Pihak Lain "+uBahasaObj.caption_mustchoose+".."); return
				}
			}
		}

		vElsfocus = document.getElementById("inpdlgformitemqty")
		if((parseInt(uDlgformObj.item_qty)||0) <= 0) {
			vElsfocus && vElsfocus.focus()
			showToast("QTY Item Treatment "+uBahasaObj.caption_invalid+".."); return
		}
		if((uDlgformObj.item_jenis||"").toUpperCase()==="BARANG")
			if(parseInt(uDataitemArr[vArridx].stok) < parseInt(uDlgformObj.item_qty)) {
				vElsfocus && vElsfocus.focus();
				showToast("STOK Item Treatment Tidak Mencukupi (Sisa: "+
					UFunc.formatAngka(uDataitemArr[vArridx].stok)+").."); return
			}
		vElsfocus = document.getElementById("inpdlgformitemharga")
		if((parseFloat(uDlgformObj.item_harga)||0) <= 0) {
			vElsfocus && vElsfocus.focus()
			showToast("Harga Satuan Item Treatment "+uBahasaObj.caption_invalid+".."+
				"<br/>Kemungkinan Anda memasukkan Nilai Selain Angka."+
				"<br/>(Nilai = "+uDlgformObj.item_harga+")"+
				"<br/><br/><b>Mohon Ulangi untuk Menginput ANGKA dengan BENAR</b> !"+
				""); return
		}
		//---END VALIDASI--/

		//--CARI_ONGOINGID_UNTUK_PASIENTERSEBUT--/
		const vOngoingArr 	= uDataloadoriObj.ongoing_arr || [];
		const vPasienongoingArr	= vOngoingArr.filter(vItems => 
			(parseInt(vItems.pasien_id)||0) === (parseInt(uDlgformObj.pasien_id)||0)) || [];
		const vOngoingID 	= parseInt((vPasienongoingArr[0]||{}).ongoing_id) || 0;
		//--END CARI_ONGOINGID_UNTUK_PASIENTERSEBUT--/

		if (vOngoingID <= 0) {
			const vNewCaption 	= UFunc.capitalize(uDlgformObj.item_nama);

			const vTmpObj	= {
				id:0,
				pasien_id:uDlgformObj.pasien_id,
				pasien_caption:uDlgformObj.pasien_caption,
				item_id:uDlgformObj.item_id,
				item_caption:vNewCaption,
				item_cetak:vNewCaption,
				item_jenis: uDlgformObj.item_jenis,
				item_qty: uDlgformObj.item_qty,
				item_harga: uDlgformObj.item_harga,
				satuan: uDlgformObj.item_satuan,
				pihaklain_status: uDlgformObj.item_pihaklain,
				pihaklain_lunas: uDlgformObj.item_pihaklainlunas,
				pihaklain_supplierid: uDlgformObj.item_pihaklainsupplierid,
				pihaklain_supplier: uDlgformObj.item_pihaklainsuppliercaption,
				pihaklain_kasid: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainkasid : "",
				pihaklain_ket: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainketbayar : "",
				ongoing_id: 0,
			}
			const vItemArr = uFormdataObj.items_arr || [];
			uFormdataObj.items_arr = [...vItemArr,vTmpObj];

			hdlToggleDlgform();
			return;
		}

		apiTabeltambah()
	}
	const prosesMainsimpan = () => {
		if(uDlgformObj.jenis !== "MAIN") return; 

		//---VALIDASI--/
		let vElsfocus = document.getElementById("inpnilaibayar")
		if((parseFloat(uFormdataObj.nilai_bayar)||0) < 0) {
			vElsfocus && vElsfocus.focus()
			showToast(uBahasaObj.caption_jmlbayar+" "+uBahasaObj.caption_invalid+".."); return
		}
		vElsfocus = document.getElementById("inppaymentcaption")
		if((parseInt(uFormdataObj.payment_id)||0) <= 0 && 
			(parseFloat(uFormdataObj.nilai_bayar)||0) > 0) {
			showToast("<b>Metode Pembayaran Harus Dipilih Dahulu</b>.."+
				"<br/><br/>Mohon Diperbaiki !"+
				""); return
		}
		if((parseInt(uFormdataObj.payment_id)||0) <= 0 && 
			((parseFloat(uFormdataObj.nilai_dppakai)||0) > 0 && 
				(parseFloat(uFormdataObj.nilai_sisa)||0) > 0)
			) {
			showToast("<b>Metode Pembayaran Harus Dipilih Dahulu</b>.."+
				"<br/><br/>Karena Kelebihan NILAI DP, dikembalikan Melalui Metode Tersebut !"+
				""); return
		}
		//alert(parseInt(uFormdataObj.nilai_sisa)||0); return;

		//--CEK_NILAI_ITEM_COMPARE_DG_NILAIBRUTO--/
		const vItemArr 		= uFormdataObj.items_arr||[];
		const vHitungbruto	= vItemArr.reduce((vSUM,vItems)=>{
			return vSUM + ((parseFloat(vItems.item_harga)||0) * (parseInt(vItems.item_qty)||0))
		},0); 

		if(vHitungbruto !== (parseFloat(uFormdataObj.nilai_bruto)||0)) {
			showToast("<b>Terjadi Ketidaksesuaian Antara Nilai Bruto dengan Nilai Item</b>.."+
				"<br/><br/>Klik RESET Tampilan Data Sampai Nilai-nilai Tersebut Sesuai !"+
				"<br/><small>(Atau KEMBALI ke Menu Sebelumnya Terlebih Dahulu.)</small>"+
				"","ERROR"); return;
		}
		//--END CEK_NILAI_ITEM_COMPARE_DG_NILAIBRUTO--/

		//--CEK_ITEM_PIHAKLAIN_LUNAS_DAN_KASIR_NOL--/
		const vFilterpihaklainArr = vItemArr.filter(vItems =>
			(vItems.pihaklain_status||"TIDAK")==="YA"
			&& (vItems.pihaklain_lunas||"BELUM")==="LUNAS"
			&& (parseInt(vItems.pihaklain_kasid)||0) <= 0
			);
		if(vFilterpihaklainArr.length > 0) {
			showToast("<b>Ada Item Terapi PIHAK LAIN Sudah LUNAS, tapi BELUM dicantumkan KAS Pembayaran-nya<b>.."+
				"<br/><br/>Mohon Hapus Terlebih Dahulu Item Tersebut, Kemudian Input Kembali Item PIHAK LAIN Tersebut dengan Benar !"+
				""); return;
		}
		//--END CEK_ITEM_PIHAKLAIN_LUNAS_DAN_KASIR_NOL--/

		if((uFormdataObj.payment_caption||"").toUpperCase() === "TUNAI")
			{ uFormdataObj.payment_ket = ""; setEfekview(!uEfekview); }//-*/
		//---END VALIDASI--/

		apiProsesbayar()
	}
	const prosesCetaknota = () => {
		const vContent 	= contentNotacetak();
		if(vContent === "") return

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	//---END PROSESFUNCTION--/

	//---INIT_CONTENT--/
	const contentHeadersub = () => {
		if(uHTMLError500) return "";

		const vOngoingArr	= uDataloadoriObj.ongoing_arr || [];
		const vItemArr		= uFormdataObj.items_arr || [];

		if(vOngoingArr.length <= 0) return (<></>)

		const vCekkasirArr	= vOngoingArr.filter(vItems => (vItems.isCekkasir||false)===true)

			//<div><small>{uBahasaObj.caption_telphp}: {uFormdataObj.customer_telp||"--"}</small></div>
		return (
		<>
		<div>
			<big className="text-info fw-bolder">{uFormdataObj.customer_caption||""}</big>
			
			{(vCekkasirArr.length > 0) && (
			<>
			<span className="mx-2 d-none d-md-inline">&middot;</span>
			<CTooltip className="d-none d-lg-block" content={"--Kasir Wajib CEK Treatment--"}>
			<CImage className="classikon classikonwarning classblinking d-none d-md-inline"/>
			</CTooltip>
			</>
			)}
		</div>

		<div className="d-none d-md-block">
			<CDropdown className="d-inline">
			<CDropdownToggle color="transparent" className="p-0 m-0" 
				caret={false}>
				<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_datatreatment||"caption_datatreatment"}>
				<CIcon className="classikoncoreui classikon classikondetil" height={25}/>
				</CTooltip>
			</CDropdownToggle>
			<CDropdownMenu>
				{vOngoingArr.map((vItems,vKeys)=>{
				return (
				<CDropdownItem key={vKeys}
					className="p-1 px-2 pe-4 classcursorpointer"
					onClick={()=>hdlKlikViewtreatment(vItems.ongoing_id)}>
					<span className="me-2">&#8627;</span>
					<strong>{vItems.pasien_caption}</strong>:&nbsp;
					<span className="fst-italic">{vItems.layanan_caption||""}</span>
				</CDropdownItem>
				)
				})}
			</CDropdownMenu>
			</CDropdown>
			
			<span className="mx-1">&middot;</span>

			<CTooltip className="d-none d-lg-block" content={uBahasaObj.caption_resetview||"caption_resetview"}>
			<CLink 
				className="classikon classikonreset classcursorpointer"
				onClick={()=>hdlKlikrefresh()}>
			</CLink>
			</CTooltip>

			<span className="mx-1">&middot;</span>

			<small className="align-top ms-1">Rp</small>
			<big className="align-middle fw-bolder text-primary">
			<big><big>{UFunc.formatAngka(uFormdataObj.nilai_bruto)}</big></big>
			</big>
		</div>
		</>
		)//>
	}
	const contentMobile = () => {
		if(uHTMLError500) return "";

		return (
		<CCard className="classcardbg">
		<CCardHeader><b>Maaf,</b></CCardHeader>
		<CCardBody>
			<p>Fitur Ini Hanya Terbatas dalam Mode Tampilan Tertentu saja..</p>
			<p>Mohon Ubah Mode Tampilan Anda dalam Mode HORIZONTAL, atau menggunakan PC / Laptop..</p>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentMain = () => {
		if(uHTMLError500) return ""

		const vItemArr		= uFormdataObj.items_arr || [];
		const vEditidx		= parseInt(uFormtabelObj.init_idx)//||-1);
		const vEditfield	= uFormtabelObj.init_field||"";

		//console.log("(Kasirklinikmain - contentMain) vEditidx => "+vEditidx)
		return (
		<>
		<CCard className="classcardbg mb-3">
			<CCardHeader className="py-1 d-flex justify-content-between align-items-end">
				<b className="text-muted classfontsmaller">
					<small>{uBahasaObj.caption_itemtreatment||"caption_itemtreatment"}</small>
				</b>

				{isMobile ? (
				<CLink className="classcursorpointer classikontabel classikontambah"
					onClick={()=>hdlKliktabeltambah()}/>
				) : (
				<CTooltip className="d-none d-lg-block" content="--Input Tambahan Item Treatment">
				<CLink className="classcursorpointer classikontabel classikontambah"
					onClick={()=>hdlKliktabeltambah()}/>
				</CTooltip>
				)}
			</CCardHeader>
			<CCardBody className="px-0">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="d-none d-md-table-row text-center classfontsmaller">
			<th width="5%" className="align-top px-0">No</th>
			<th className="align-top px-0">
				<CRow className="mx-0">
				<CCol md="2" className="px-0 text-start">{uBahasaObj.word_pasien||"word_pasien"}</CCol>
				<CCol md="4" className="px-0 text-start">{uBahasaObj.caption_itemtreatment||"caption_itemtreatment"}</CCol>
				<CCol md="1" className="px-0">&middot;</CCol>
				<CCol md="1" className="px-0">{uBahasaObj.word_qty||"word_qty"}</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.caption_hsatuan||"caption_hsatuan"} (Rp)</CCol>
				<CCol md="2" className="px-0">{uBahasaObj.word_total||"caption_nilaitotal"} (Rp)</CCol>
				</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={25} className="p-0"/>
				<th className="p-0"/>
			</tr>
			</thead>

			<tbody>
			{vItemArr.map((vItems,vKeys)=>{
				const {
					id,pasien_caption,item_caption,item_cetak,item_harga,item_qty,
					item_satuan,pihaklain_kascaption,pihaklain_kasid,pihaklain_ket,
					pihaklain_status,pihaklain_lunas,pihaklain_supplier,
					initEditfield
				} = vItems

				const vNilaitotal 	= item_harga * item_qty;
				const vColorlunas	= (pihaklain_lunas||"TIDAK")!=="LUNAS"
					? "text-danger" : "text-success";

				return (
				<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+((uTabelRowselect===vKeys && !isMobile)?" classrowselect":"")}>
				<td align="right" className="px-1 pe-0">{vKeys+1}.</td>
				
				<td align="right" className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-md-start">{pasien_caption||"-"}</CCol>

				<CCol md="4" className="px-1 text-md-start ">
				{(vEditidx !== vKeys || vEditfield!=="ITEMCAPTION") ? (
					<>
					<CTooltip className="d-none d-lg-block" content="--Klik utk Ganti Label Item Terapi">
					<CLink onClick={()=>hdlKliktabeledit(vKeys,"ITEMCAPTION")} 
						className="text-info classcursorpointer">
					<span>{item_cetak||"-"}</span>
					</CLink>
					</CTooltip>

					{((item_caption||"").toUpperCase()!==(item_cetak||"").toUpperCase() || 
						(pihaklain_status||"TIDAK")==="YA"
					) && (
					<ul className="classfontsmaller ps-3 my-0">
						{(item_caption||"").toUpperCase()!==(item_cetak||"").toUpperCase() && (
						<li className="fst-italic">
							Nama Ori: <b>{item_caption||"-"}</b>
						</li>
						)}
					
						{(pihaklain_status||"TIDAK")==="YA" && (
						<li className="">
						<span>{pihaklain_supplier||"Supplier P.Lain"}</span>
						<span className="mx-1">&middot;</span>
						<b className={(vColorlunas)+""}>({pihaklain_lunas})</b>

						{(parseInt(pihaklain_kasid)||0) > 0 && (
						<small className="d-block">
							{pihaklain_kascaption||"UNDF Kas"}
							{(pihaklain_ket||"")!=="" && (
							<>
								<span className="mx-1">&middot;</span>
								<span className="fst-italic">{pihaklain_ket}</span>
							</>
							)}
						</small>
						)}
						</li>
						)}
					</ul>
					)}

					</>
				) : (
					<>
					{(uFormtabelObj.isProcessing||false)!==true ? (
					<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={100}
						value={uFormtabelObj.value||""}
						onChange={(e)=>{uFormtabelObj.value=(e.target.value);setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDowntabelproses(vKeys,vEditfield,e)}
						id="inptabelitemcaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKliktabelproses(vKeys,vEditfield)}
						id="btntabelitemcaption">
						<CIcon icon="cilCheckAlt"/>
					</CButton>
					</CInputGroup>
					) : (
						<CSpinner size="sm" color="info"/>
					)}
					</>
				)}
				</CCol>

				<CCol md="1" className="px-1 px-md-0 text-md-center">
				{(vEditidx !== vKeys || vEditfield!=="HAPUS") ? (
				<CTooltip className="d-none d-lg-block" content="--Hapus Item">
				<CLink
					onClick={()=>hdlKlikTabelhapus(vKeys)}
					className="classcursorpointer classikontabel classikonhapus"/>
				</CTooltip>
				):(
					<CSpinner size="sm" color="danger"/>
				)}
				</CCol>

				<CCol md="1" className="px-1 px-md-0 text-md-center fw-bolder">
				{(vEditidx !== vKeys || vEditfield!=="ITEMQTY") ? (
				<CTooltip className="d-none d-lg-block" content="--Klik utk EDIT">
					<CLink onClick={()=>hdlKliktabeledit(vKeys,"ITEMQTY")} 
						className="classcursorpointer text-dark">
					{UFunc.formatAngka(item_qty)} {item_satuan||"UNT"}
					</CLink>
				</CTooltip>
				) : (
					<>
					{(uFormtabelObj.isProcessing||false)!==true ? (
					<CInputGroup size="sm">
						<CFormInput size="sm"
							type="number"
							className="text-end"
							value={uFormtabelObj.value||"0"}
							onChange={(e)=>{uFormtabelObj.value = (e.target.value);setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>hdlKDowntabelproses(vKeys,vEditfield,e)}
							id="inptabelitemqty"/>
						<CButton size="sm" color="dark"
							className="px-1"
							onClick={()=>hdlKliktabelproses(vKeys,vEditfield)}
							id="btntabelitemqty">
						<CIcon icon="cilCheckAlt"/>
						</CButton>
					</CInputGroup>
					) : (
						<CSpinner size="sm" />
					)}
					</>
				)}
				</CCol>

				<CCol md="2" className="px-1">
				{(vEditidx !== vKeys || vEditfield!=="ITEMHARGA") ? (
					<CTooltip className="d-none d-lg-block" content="--Klik utk EDIT">
					<CLink onClick={()=>hdlKliktabeledit(vKeys,"ITEMHARGA")} className="classcursorpointer text-success">
					{UFunc.formatAngka(item_harga)}
					</CLink>
					</CTooltip>
				) : (
					<>
					{(uFormtabelObj.isProcessing||false)!==true ? (
					<CInputGroup size="sm">
						<NumberFormat 
							value={uFormtabelObj.value||"0"}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							allowNegative={false}
							onValueChange={(values) => uFormtabelObj.value = (values.value)}
							renderText={values => (
								<CFormInput size="sm" 
									value={values}
									onFocus={(e)=>e.target.select()}
									onChange={(e)=>{uFormtabelObj.value = (e.target.value);setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDowntabelproses(vKeys,vEditfield,e)}
									className="text-end"
									id="inptabelitemharga"/>
							)} 
						prefix={isMobile?"":"Rp"}/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKliktabelproses(vKeys,vEditfield)}
							id="btntabelitemharga">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
					</CInputGroup>
					) : (
						<CSpinner size="sm" color="success"/>
					)}
					</>
				)}
				</CCol>

				<CCol md="2" className="px-1 text-primary fw-bolder">
					{UFunc.formatAngka(vNilaitotal)}
				</CCol>
				</CRow>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
			</CCardBody>
		</CCard>

		<CForm>
		<CRow className="align-items-stretch">
		<CCol md="6" className="">
		<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller">
				<small className="fw-bolder">{uBahasaObj.caption_paymentmethod||"caption_paymentmethod"}</small>
			</CCardHeader>
			
			<CCardBody>
			<CRow className="my-2 d-none">
			<CCol lg="8" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder={"--"+(uBahasaObj.caption_silahkanpilih||"caption_silahkanpilih")+".."}
					className="classbgdisabled text-uppercase fw-bolder"
					value={uFormdataObj.payment_caption||""}
					onClick={()=>hdlKlikPaymentlookup()}
					onKeyDown={(e)=>hdlKDownPaymentlookup(e)}
					id="inppaymentcaptionx"/>
				<CButton size="sm" color="dark"
					className="border"
					onClick={()=>hdlKlikPaymentlookup()}
					id="btnPaymentlookup">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2 d-none">
			<CCol className="">
				<CFormInput size="sm" 
					maxLength={255}
					value={uFormdataObj.payment_ket||""}
					onChange={(e)=>{uFormdataObj.payment_ket=(e.target.value);setEfekview(!uEfekview)}}
					placeholder={"--"+(uBahasaObj.caption_ketnontunai||"caption_ketnontunai")+".."}
					id="inppaymentketx"/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom d-none"/>

			<div className="classpetunjuk">
			*) <b>Perhatikan Metode Pembayaran Anda</b>..
			<br/>Jika Menggunakan Pembayaran NON TUNAI,{" "} 
			dan Nilai DP Klien Melebihi Transaksi,{" "}
			Maka <b>KEMBALIAN akan DIANGGAP</b> Menggunakan Metode Pembayaran Tersebut.
			</div>

			</CCardBody>
		</CCard>
		</CCol>

		<CCol className="">
		<CCard className="classcardbg h-100">
			<CCardHeader className="py-0 pt-1 text-muted classfontsmaller">
				<small className="fw-bolder">{uBahasaObj.caption_catcustomer||"caption_catcustomer"}</small>
			</CCardHeader>
			<CCardBody>
				<CRow className="my-2">
				<CCol>{uBahasaObj.caption_nilaidpcustomer||"DP Customer"}</CCol>
				<CCol className="text-end text-primary fw-bolder">Rp{UFunc.formatAngka(uFormdataObj.nilai_dppakai)}</CCol>
				</CRow>

				<div className="my-3 classborderbottom"/>

				<CFormTextarea size="sm"
					placeholder="---Catatan Untuk Klien / Customer.."
					value={uFormdataObj.catatan_customer||""}
					onChange={(e)=>{uFormdataObj.catatan_customer=(e.target.value);setEfekview(!uEfekview);}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnBayar")}
					id="inpnotacatatan"/>
			</CCardBody>
		</CCard>
		</CCol>
		</CRow>
		</CForm>
		</>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		if(uDlgformObj.jenis === "MAIN") return contentDlgmain();
		else if(uDlgformObj.jenis === "TAMBAHTABEL") return contentDlgtabel();

		return (<></>)//>
	}
	const contentDlgmain = () => {
		if(uDlgformObj.jenis !== "MAIN") return (<></>)//>

		return (
		<CForm>
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3 mb-lg-0">
		<CCard className="classcardbg h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
			<small>Summary</small>
		</CCardHeader>
		<CCardBody>
			<div className="d-flex justify-content-between my-1">
			<small>{uBahasaObj.caption_jmlbruto}</small>
			<div className="w-50">
			<CFormInput size="sm"
				readOnly={true}
				className="classbgdisabled text-end"
				value={"Rp"+UFunc.formatAngka(uFormdataObj.nilai_bruto)}
				id="inpnilaibruto"/>
			</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<span>
				<small className="me-1">{uBahasaObj.caption_titipandp}</small>
				<span className="text-primary classfontsmaller">
					<small className="fw-bolder">(Rp{UFunc.formatAngka(uFormdataObj.customer_dp)})</small>
				</span>
				</span>

				<div className="w-50 text-end">
				<CFormInput size="sm"
					disabled={true}
					className="classbgdisabled text-end text-danger"
					value={"Rp"+UFunc.formatAngka(uFormdataObj.nilai_dppakai)}
					id="inpnilaidp"/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
			<small>{uBahasaObj.word_diskon}</small>
			<div className="w-50 text-end">
				<NumberFormat 
					value={uFormdataObj.nilai_diskon||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => {uFormdataObj.nilai_diskon=(values.value);}}
					renderText={values => (
						<CFormInput size="sm" 
							value={values}
							onFocus={(e)=>e.target.select()}
							onChange={(e)=>{uFormdataObj.nilai_diskon=(e.target.value);setEfekview(!uEfekview);}}
							className="text-end text-primary"
							id="inpnilaidiskon"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</div>
			</div>

			<div className="my-3 classborderbottom"/>

			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_paymentmethod}</small>
				<div className="w-50 text-end">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						readOnly={true}
						value={uFormdataObj.payment_caption||""}
						placeholder="--Pilih Metode.."
						onClick={()=>hdlKlikPaymentlookup()}
						id="inppaymentcaption"/>
					<CInputGroupText className="bg-dark">
						<CLink onClick={()=>hdlKlikPaymentlookup()} className="classcursorpointer text-white">
						<CIcon icon="cilSearch"/>
						</CLink>
					</CInputGroupText>
					</CInputGroup>
				</div>
			</div>

			{(uFormdataObj.payment_caption||"TUNAI").toUpperCase() !== "TUNAI" && (
			<>
			<div className="d-flex justify-content-between my-1">
				<small>B.Non Tunai (Trf)</small>
				<div className="w-50 text-end">
				<NumberFormat 
					value={uFormdataObj.payment_biaya||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) =>uFormdataObj.payment_biaya=(values.value)}
					renderText={values => (
						<CFormInput size="sm" 
							value={values}
							onFocus={(e)=>e.target.select()}
							onChange={(e)=>{uFormdataObj.payment_biaya=(e.target.value);setEfekview(!uEfekview)}}
							className="text-end text-danger"
							id="inpnilaibtransfer"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				</div>
			</div>
			
			<div className="my-2" style={{borderBottom:"1px dotted #dcdcdc"}}/>

			<div className="my-2">
				<small className="d-block fst-italic">{uBahasaObj.caption_ketpayment||"Ket.Payment"}</small>
				<CFormInput size="sm"
					value={uFormdataObj.payment_ket||""}
					onChange={(e)=>{uFormdataObj.payment_ket=e.target.value;setEfekview(!uEfekview)}}
					id="inppaymentket"/>
			</div>
			</>
			)}
		</CCardBody>
		</CCard>
		</CCol>
		
		<CCol>
		<CCard className="classcardbg h-100">
		<CCardHeader className="py-0 pt-1 classfontsmaller text-muted">
			<small>Pembayaran</small>
		</CCardHeader>

		<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_totalnetto}</small>
				<div className="text-end w-50" style={{}}>
				<CFormInput size="sm"
					disabled={true}
					className="classbgdisabled text-end text-primary fw-bolder"
					value={"Rp"+UFunc.formatAngka(uFormdataObj.nilai_netto)}
					id="inpnilainetto"/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_jmlbayar}</small>
				<div className="text-end w-50">
				<NumberFormat 
					value={uFormdataObj.nilai_bayar||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					allowNegative={false}
					onValueChange={(values) => uFormdataObj.nilai_bayar=(values.value)}
					renderText={values => (
						<CFormInput size="sm" 
							value={values}
							onFocus={(e)=>e.target.select()}
							onChange={(e)=>{uFormdataObj.nilai_bayar = (e.target.value);setEfekview(!uEfekview);}}
							onKeyDown={(e)=>{if(parseInt(uFormdataObj.nilai_sisa) >= 0) UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}}
							className="text-end"
							id="inpnilaibayar"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
				</div>
			</div>

			<div className="my-3 classborderbottom"/>

			<div className="d-flex justify-content-between my-2">
				<span>
				<big className={"fw-bolder "+(parseInt(uFormdataObj.nilai_sisa)<0?"text-danger":"text-success")}>
				{uFormdataObj.caption_sisa||uBahasaObj.caption_kembalian}
				</big>
				</span>

				<div className="text-end" style={{}}>
				<big className={"fw-bolder "+(parseInt(uFormdataObj.nilai_sisa)<0?"text-danger":"text-success")}>
					{"Rp"+UFunc.formatAngka(Math.abs(uFormdataObj.nilai_sisa))}
				</big>
			</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>{uBahasaObj.caption_tgljt}</small>
				
				<div className="text-end w-50" style={{}}>
				{(parseInt(uFormdataObj.nilai_sisa)||0) < 0 ? (
				<CFormInput size="sm" type="date"
					value={uFormdataObj.tgl_jt||""}
					onChange={(e)=>{uFormdataObj.tgl_jt=(e.target.value);setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inptgljatuhtempo"/>
				) : (
					<CIcon icon="cilInfinity" size="lg" className="me-2 fw-bolder"/>
				)}
				</div>
			</div>
		</CCardBody>
		</CCard>

		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgtabel = () => {
		if(uDlgformObj.jenis !== "TAMBAHTABEL") return (<></>)//>

		return (
		<CForm>
		<CRow>
		<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-0"><small>Pilih Pasien</small></CCardHeader>
			<CCardBody>
			<div className="my-1">
				<CInputGroup size="sm"> 
				<CFormInput size="sm"
					placeholder="--Masukkan Keyword Pasien.."
					readOnly={true}
					value={uDlgformObj.pasien_caption}
					onChange={(e)=>{uDlgformObj.pasien_caption = e.target.value;setEfekview(!uEfekview)}}
					onClick={()=>hdlKlikDlgformpasienlookup()}
					id="inpdlgformpasiencaption"/>
				{(parseInt(uDlgformObj.pasien_id)||0) > 0 && (
				<CButton size="sm" color="light" 
					className="border border-danger text-danger"
					onClick={()=>{
						uDlgformObj.pasien_caption 	= "";
						uDlgformObj.pasien_id 		= 0;
						uDlgformObj.item_id 		= 0;
						uDlgformObj.item_caption 	= "";
						uDlgformObj.item_nama 		= "";
						uDlgformObj.item_jenis 		= "";
						uDlgformObj.item_qty		= 0;
						uDlgformObj.item_harga		= 0;
						uDlgformObj.item_satuan		= "";
						uDlgformObj.item_pihaklain 	= undefined;
						uDlgformObj.item_pihaklainlunas= undefined;
						uDlgformObj.item_pihaklainsupplierid= 0;
						uDlgformObj.item_pihaklainsuppliercaption= undefined;
						uDlgformObj.item_pihaklainkasid= 0;
						uDlgformObj.item_pihaklainketbayar= undefined;

						setEfekview(!uEfekview);
					}}
					id="btndlgformpasiencancel">
					<CIcon icon="cilXCircle"/>
				</CButton>
				)}
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikDlgformpasienlookup()}
					id="btndlgformpasienlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup> 

				<div className="classpetunjuk">*) Kosongi Jika Item Tidak Untuk Pasien</div>
			</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="7">
			<CCard className="mb-3">
			<CCardHeader className="py-0"><small>Item Terapi</small></CCardHeader>
			<CCardBody>
			<div className="d-flex justify-content-between my-1">
				<small>Item Terapi</small>
				<div className="text-end" style={{width:"65%"}}>
					<CInputGroup size="sm"> 
					<CFormInput size="sm"
						placeholder="--Keyword Barang-Jasa.."
						value={uDlgformObj.item_caption}
						onChange={(e)=>{uDlgformObj.item_caption=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDownDlgformitemcaption(e)}
						id="inpdlgformitemcaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikDlgformitemlookup()}
						id="btndlgformitemlookup">
						<CIcon icon="cilSearch"/>
					</CButton>
					</CInputGroup> 
				</div>
			</div>

			<div className="classborderbottom my-3"/>

			{(uDlgformObj.item_pihaklain||"TIDAK")==="YA" && (
			<>
			<div className="d-flex justify-content-between my-1">
				<small>Status Pihak Lain</small>
				<div className="text-end" style={{width:"65%"}}>
					<CFormSelect size="sm"
						value={uDlgformObj.item_pihaklainlunas||"HUTANG"}
						onChange={(e)=>{uDlgformObj.item_pihaklainlunas=e.target.value;setEfekview(!uEfekview)}}
						id="inpdlgformitemplainlunas">
					<option value="">--PILIH STATUS PELUNASAN--</option>
					<option value="LUNAS">LUNAS</option>
					<option value="HUTANG">BELUM LUNAS</option>
					</CFormSelect>
				</div>
			</div>
			<div className="d-flex justify-content-between my-1">
				<small>Supplier Pihak Lain</small>
				<div className="text-end" style={{width:"65%"}}>
					<CInputGroup size="sm"> 
					<CFormInput size="sm"
						placeholder="--Keyword Supplier.."
						value={uDlgformObj.item_pihaklainsuppliercaption}
						onChange={(e)=>{uDlgformObj.item_pihaklainsuppliercaption=e.target.value;setEfekview(!uEfekview)}}
						onKeyDown={(e)=>hdlKDownDlgformsuppliercaption(e)}
						id="inpdlgformsuppliercaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikDlgformsupplierlookup()}
						id="btndlgformsupplierlookup">
						<CIcon icon="cilSearch"/>
					</CButton>
					</CInputGroup> 
				</div>
			</div>

			
			{(uDlgformObj.item_pihaklainlunas||"BELUM")==="LUNAS" && (
			<>
				<div className="my-2"/>

				<div className="d-flex justify-content-between my-1">
				<small>Kas Asal<span className="ms-1 d-none d-md-inline">Pembayaran</span></small>
				<div className="text-end" style={{width:"65%"}}>
					<CInputGroup size="sm"> 
					<CFormInput size="sm" readOnly={true}
						placeholder="--Pilih Akun KAS.."
						value={uDlgformObj.item_pihaklainkascaption}
						onChange={(e)=>{uDlgformObj.item_pihaklainkascaption=e.target.value;setEfekview(!uEfekview)}}
						onClick={()=>hdlKlikDlgformkaslookup()}
						id="inpdlgformitemkascaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikDlgformkaslookup()}
						id="btndlgformitemkascaption">
						<CIcon icon="cilSearch"/>
					</CButton>
					</CInputGroup> 
				</div>
				</div>

				<div className="d-flex justify-content-between my-1">
				<small>Ket. Pembayaran</small>
				<div className="text-end" style={{width:"65%"}}>
					<CFormInput size="sm" maxLength={100}
						value={uDlgformObj.item_pihaklainketbayar}
						onChange={(e)=>{uDlgformObj.item_pihaklainketbayar=e.target.value;setEfekview(!uEfekview)}}
						id="inpdlgformitemketbayar"/>
				</div>
				</div>
			</>
			)}

			<div className="classborderbottom my-3"/>
			</>

			)}

			<div className="d-flex justify-content-between my-1">
				<small>QTY(s)</small>
				<div className="text-end" style={{width:"65%"}}>
					<CInputGroup 
						style={{minWidth:80,width:"50%"}}
						size="sm"> 
					<CFormInput size="sm" min={0} step={1}
						type="number"
						className="text-end"
						value={uDlgformObj.item_qty||0}
						onChange={(e)=>{uDlgformObj.item_qty=e.target.value;setEfekview(!uEfekview)}}
						onFocus={(e)=>e.target.select()}
						id="inpdlgformitemqty"/>
					<CInputGroupText>{uDlgformObj.item_satuan || "UNIT"}</CInputGroupText> 
					</CInputGroup> 
				</div>
			</div>

			<div className="d-flex justify-content-between my-1">
				<small>H.Satuan</small>
				<div className="text-end" style={{width:"65%"}}>
					<NumberFormat 
						value={uDlgformObj.item_harga||0}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						allowNegative={false}
						onValueChange={(values) => uDlgformObj.item_harga = (values.value)}
						renderText={values => (
							<CFormInput size="sm" 
								value={values}
								onChange={(e)=>{uDlgformObj.item_harga = (e.target.value);setEfekview(!uEfekview)}}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
								onFocus={(e)=>e.target.select()}
								className="text-end"
								id="inpdlgformitemharga"/>
						)} 
					prefix={isMobile?"":"Rp"}/>
				</div>
			</div>

			<div className="d-flex justify-content-between my-2">
				<small>Total</small>
				<div className="text-start fw-bolder text-success" style={{width:"65%"}}>
					Rp{UFunc.formatAngka(parseInt(uDlgformObj.item_qty||0) * 
						parseFloat(uDlgformObj.item_harga||0))}
				</div>
			</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>
		if((uDataongoingObj||null)===null) return (<></>)//>

		const vOngoingID 	= uDataongoingObj.ongoing_id
		const vOngoingArr 	= uDataloadoriObj.ongoing_arr||[]
		const vOngoingfilter= vOngoingArr.filter(vItems => 
			vItems.ongoing_id.toString()===vOngoingID.toString())

		const vReminderArr	= uDataongoingObj.reminder_arr||[]
		/*[ {tgl_reminder:"2022-10-10",note_reminder:"Reminder 1"},
		{tgl_reminder:"2022-11-10",note_reminder:"Reminder 2"},
		{tgl_reminder:"2022-12-10",note_reminder:"Reminder 3"},
		{tgl_reminder:"2023-01-01",note_reminder:"Reminder 4"},
		]//-*/

		return (
		<>
		<CRow className="align-items-stretch">
			<CCol className="mb-3" lg="5">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>{uBahasaObj.caption_dataanamnesa}</small></CCardHeader>
			<CCardBody className="classfontsmaller">
				<div className="d-flex justify-content-between">
				<div className="">{uBahasaObj.caption_tglmasuk}</div>
				<div className="text-primary text-end">{UFunc.WaktuAngka(uDataongoingObj.tgl_hadir)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="">{uBahasaObj.word_keluhan}</div>
				<div className="text-primary text-end">{uDataongoingObj.keluhan||"-"}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="">{uBahasaObj.word_anamnesa}</div>
				<div className="text-primary text-end">{uDataongoingObj.anamnesa||"-"}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="">{uBahasaObj.caption_suhubadan}</div>
				<div className="text-primary text-end">{UFunc.formatAngka(uDataongoingObj.cek_suhu)}&deg; C</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="">{uBahasaObj.caption_beratbadan}</div>
				<div className="text-primary text-end">{UFunc.formatAngka(uDataongoingObj.berat_badan)} KG</div>
				</div>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>{uBahasaObj.caption_datareminder}</small></CCardHeader>
			<CCardBody>
			{vReminderArr.length <= 0 && (
				<small className="fst-italic">---{uBahasaObj.caption_dataempty}---</small>
			)}
			{vReminderArr.map((vItems,vKeys)=>{
				return (
				<CRow key={vKeys} className="mx-0 my-1 classfontsmaller">
				<CCol className="px-1 text-end" xs="1">{vKeys+1}.</CCol>
				<CCol className="px-1 text-primary fw-bolder" xs="3">{UFunc.TglAngka(vItems.tgl_reminder)}</CCol>
				<CCol className="px-1 fst-italic text-end" xs="8">{vItems.note_reminder||""}</CCol>
				</CRow>
				)
			})}
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>

		<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>{uBahasaObj.caption_notetemplate}</small></CCardHeader>
			<CCardBody className="py-2">
			{UFunc.renderHTML(uDataongoingObj.note_template||"-")}
			</CCardBody>
		</CCard>

		<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller"><small>{uBahasaObj.caption_catatankasir}</small></CCardHeader>
			<CCardBody className="py-2 text-danger fst-italic classfontsmaller">
				{vOngoingfilter.length > 0 && (
				<>{vOngoingfilter[0].catatan_kasir||"-"}</>
				)}
			</CCardBody>
		</CCard>
		</>
		)//>
	}
	const contentNotacetak = () => {
		//console.log("(Kasirklinikmain - contentNotacetak) uDataCetakObj = "+JSON.stringify(uDataCetakObj||{}));
		if(uDataCetakObj === null) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uDatasetting.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= uDatasetting.url_image_nota||uUrllogoDefault;//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDataCetakObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uDataidentitas.telp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				//(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uDataidentitas.telp || "")+
			`</td>
			</tr>
			`;
		//---END TELP--/

		//---TAGLINE--/
		const vTagline	=  uDataidentitas.slogan !== "" 
			? `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				UFunc.capitalize(uDataidentitas.slogan || "")+
			`</td>
			</tr>
			` : ``;
		//---END TAGLINE--/

		const vFooternota = (uDatasetting.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><HR></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uDatasetting.footer_nota||"")+`</td>
			</tr>
			` : ``;
		
		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uDataidentitas.nama_perusahaan || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uDataidentitas.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_FOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/_TRBODYTAGLINE_/g,vTagline);

		return vHTMLs		
	}
	//---END INIT_CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHTMLError500();

		uELBtnsimpanmain && (uELBtnsimpanmain.disabled = true);
		setTabelIDselect(0)
		setTabelRowselect(-1)
		setLoadawal(true)
		setLoading(true)

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false)
			setLoadawal(false)
		},3500); return
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid : uCustomerID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/ld_form";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uELBtnsimpanmain && (uELBtnsimpanmain.disabled = false);
			setLoading(false)
			setLoadawal(false)
			//console.log("("+uComponentname+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Kasirklinikmain-apiLoaddata) output_string.dataitemlist : "+(output_string.dataitemlist));
				setDataloadoriObj(JSON.parse(output_string.dataobject || "{}"))
				setDatapaymentArr(JSON.parse(output_string.paymentlist || "[]"))
				setDataidentitas(JSON.parse(output_string.dataidentitas||"{}"))
				setDatasetting(JSON.parse(output_string.datasetting||"{}"))
				setDataitemArr(JSON.parse(output_string.dataitemlist||"[]"));
				setDatasupplierArr(JSON.parse(output_string.supplierlist||"[]"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirklinikmain - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			//uELBtnsimpanmain && (uELBtnsimpanmain.disabled = false);
			setLoading(false);
			setLoadawal(false);
			console.log("(Kasirklinikmain - apiLoaddata) catch-error : "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadongoing = (_ONGOINGID) => {
		_ONGOINGID = parseInt(_ONGOINGID)||0
		if(_ONGOINGID <= 0) return

		const vOngoingArr 	= uDataloadoriObj.ongoing_arr || []
		const vObjidx		= UFunc.getObjectIndeks(vOngoingArr,_ONGOINGID,"ongoing_id");

		setLoading(true)

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false)
		},2000); return
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_ongoingid : _ONGOINGID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/ld_ongoing";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false)
			if(output_string.tampil) {
				const vTmpObj =  JSON.parse(output_string.ongoinglist||"{}");
				vOngoingArr[vObjidx].ongoing_obj = vTmpObj
				setDataongoingObj(vTmpObj)
				setDlgviewshow(true)
			} else {
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kasirklinikmain - apiLoadongoing) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setLoading(false)

			console.log("(Kasirklinikmain - apiLoadongoing) catch-errors : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiTabelproses = (_IDXTABEL,_ITEMFIELD) => {
		_IDXTABEL = _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL); if(_IDXTABEL < 0) return

		const vItemArr = uFormdataObj.items_arr || []
		const vObjidx	= _IDXTABEL;//UFunc.getObjectIndeks(vItemArr,_ITEMID);
		uFormtabelObj.isProcessing = true;
		setEfekview(!uEfekview)

		let vValue;
		if(_ITEMFIELD==="ITEMCAPTION") 
			vValue = uFormtabelObj.value;
		else if(_ITEMFIELD==="ITEMQTY")
			vValue = uFormtabelObj.value;
		else if(_ITEMFIELD==="ITEMHARGA")
			vValue = uFormtabelObj.value;

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},3000); return
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_itemid : vItemArr[vObjidx].id,
			send_field: _ITEMFIELD,
			send_value: vValue,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/pr_prosesitem";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				if(_ITEMFIELD==="ITEMCAPTION")
					vItemArr[vObjidx].item_cetak = uFormtabelObj.value;
				else if(_ITEMFIELD==="ITEMQTY")
					vItemArr[vObjidx].item_qty = uFormtabelObj.value;
				else if(_ITEMFIELD==="ITEMHARGA")
					vItemArr[vObjidx].item_harga = uFormtabelObj.value;

				prosesTotalnilai();
				uFormdataObj.items_arr = vItemArr
				prosesToggletabel()
			} else {
				uFormtabelObj.isProcessing = false;

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kasirklinikmain - apiTabelproses) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uFormtabelObj.isProcessing = (false)

			console.log("(Kasirklinikmain - apiTabelproses) catch-errors : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiTabelhapus = (_IDXTABEL) => {
		_IDXTABEL = _IDXTABEL===undefined ? -1 : parseInt(_IDXTABEL); if(_IDXTABEL < 0) return

		const vItemArr 	= uFormdataObj.items_arr || []
		const vObjidx	= _IDXTABEL;//UFunc.getObjectIndeks(vItemArr,_ITEMID)
		uFormtabelObj.init_idx 		= _IDXTABEL;
		uFormtabelObj.init_field 	= "HAPUS";
		uFormtabelObj.isProcessing 	= true;
		setEfekview(!uEfekview)

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},3000); return
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_itemid : vItemArr[vObjidx].id,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/pr_hapusitem";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				vItemArr.splice(vObjidx,1)

				prosesTotalnilai()
				uDataloadoriObj.items_arr 	= vItemArr
				uFormdataObj.items_arr 		= vItemArr

				prosesToggletabel()
			} else {
				prosesToggletabel()

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kasirklinikmain - apiTabelhapus) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			prosesToggletabel()

			console.log("(Kasirklinikmain - apiTabelhapus) catch-errors : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiTabeltambah = () => {
		const vElBtnDlgformsimpan = document.getElementById("btnDialogSimpan");
		vElBtnDlgformsimpan && (vElBtnDlgformsimpan.disabled = true);
		setLoading(true);

		/*//---TEST_FRONTEND--/
		console.log("uDataloadoriObj => "+JSON.stringify(uDataloadoriObj));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			vElBtnDlgformsimpan && (vElBtnDlgformsimpan.disabled = false);
			setLoading(false);
		},2000); return
		//---END TEST_FRONTEND--*/

		//--CARI_ONGOINGID_UNTUK_PASIENTERSEBUT--/
		const vOngoingArr 	= uDataloadoriObj.ongoing_arr || [];
		const vPasienongoingArr	= vOngoingArr.filter(vItems => 
			(parseInt(vItems.pasien_id)||0) === (parseInt(uDlgformObj.pasien_id)||0)) || [];
		const vOngoingID 	= (vPasienongoingArr[0]||{}).ongoing_id || 0;
		//--END CARI_ONGOINGID_UNTUK_PASIENTERSEBUT--/

		const vDATAS	= JSON.stringify({
			send_treatmentid : vOngoingID,//uDataloadoriObj.ongoing_id_main || 0,
			send_itemid : uDlgformObj.item_id,
			send_itemcaption: uDlgformObj.item_caption,
			send_itemqty : uDlgformObj.item_qty,
			send_itemhsatuan : uDlgformObj.item_harga,
			send_itemjenis: uDlgformObj.item_jenis,
			send_itempihaklain: uDlgformObj.item_pihaklain,
			send_itempihaklainlunas: uDlgformObj.item_pihaklainlunas,
			send_itempihaklainsupplierid: uDlgformObj.item_pihaklainsupplierid,
			send_itempihaklainkasid: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainkasid : 0,
			send_itempihaklainketbayar: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainketbayar : "",
			send_itemsatuan: uDlgformObj.item_satuan,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_itemtambah";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			vElBtnDlgformsimpan && (vElBtnDlgformsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vNewID		= output_string.tabelid || "255";
				const vNewCaption 	= UFunc.capitalize(uDlgformObj.item_nama);

				const vTmpObj	= {
					id:vNewID,
					pasien_id:uDlgformObj.pasien_id,
					pasien_caption:uDlgformObj.pasien_caption,
					item_id:uDlgformObj.item_id,
					item_caption:vNewCaption,
					item_cetak:vNewCaption,
					item_jenis: uDlgformObj.item_jenis,
					item_qty: uDlgformObj.item_qty,
					item_harga: uDlgformObj.item_harga,
					satuan: uDlgformObj.item_satuan,
					pihaklain_status: uDlgformObj.item_pihaklain,
					pihaklain_lunas: uDlgformObj.item_pihaklainlunas,
					pihaklain_supplierid: uDlgformObj.item_pihaklainsupplierid,
					pihaklain_supplier: uDlgformObj.item_pihaklainsuppliercaption,
					pihaklain_kasid: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainkasid : 0,
					pihaklain_ket: (uDlgformObj.item_pihaklainlunas==="LUNAS") ? uDlgformObj.item_pihaklainketbayar : "",
					ongoing_id: uDataloadoriObj.ongoing_id_main,
				}
				const vItemArr = uDataloadoriObj.items_arr || [];
				uDataloadoriObj.items_arr = [...vItemArr,vTmpObj];
				setFormdataObj({...uDataloadoriObj,customer_id:uCustomerID});

				hdlToggleDlgform();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kasirklinikmain - apiTabeltambah) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vElBtnDlgformsimpan && (vElBtnDlgformsimpan.disabled = false);
			setLoading(false)
			console.log("(Kasirklinikmain - apiTabeltambah) catch-error: "+error)
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiProsesbayar = () => {
		const vItemArr 		= uDataloadoriObj.items_arr || []
		const vOngoingArr 	= uDataloadoriObj.ongoing_arr || []
		const vOngoingIDArr = vOngoingArr.map(vItems => vItems.ongoing_id)

		const vELDlgsimpan	= uELDlgsimpan;
		vELDlgsimpan && (vELDlgsimpan.disabled = true)
		setLoading(true)

		/*//--TEST_FRONTEND--/
		console.log("(Kasirklinikmain - apiProsesbayar)  : "+JSON.stringify(vOngoingIDArr));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false)
		},3000); return
		//--END TEST_FRONTEND--*/

		//console.log("(Kasirklinikmain - apiProsesbayar)  : "+JSON.stringify(uFormdataObj));
		const vDATAS	= JSON.stringify({
			send_ongoingidlist : vOngoingIDArr.join(","),
			send_datalist: JSON.stringify(uFormdataObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/pr_simpanbayar";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false)
			if(output_string.sukses) {
				setDataCetakObj(JSON.parse(output_string.datanota || "{}"))
			} else {
				vELDlgsimpan && (vELDlgsimpan.disabled = false)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kasirklinikmain - apiProsesbayar) output_string.errors : "+output_string.errors);

					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired(); return
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			vELDlgsimpan && (vELDlgsimpan.disabled = false)
			setLoading(false)

			console.log("(Kasirklinikmain - apiProsesbayar) catch-errors : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument)
		return () => {
			document.removeEventListener("keydown",hdlKDownDocument)
		}
	})
	React.useEffect(()=>{
		if(!uIsEOMExecuted) 
			{ uNavigate(uURLbefore,{replace:true}); return }
		uDispatch({type: "set", effectApp: !uEfekapp});

		if(uCustomerID <= 0)
			{ uNavigate(uURLbefore,{replace:true}); return }

		setDlglookupshow(false)
		setIsDlgformshow(false)

		let vTimerrun = setInterval(() => {
			const vDates = new Date();

			const vTimestr	= UFunc.leadZero(vDates.getHours())+":"+
				UFunc.leadZero(vDates.getMinutes())+":"+
				UFunc.leadZero(vDates.getSeconds())+
			"";

			setRunningtime(vTimestr);
		}, 1000)
		
		apiLoaddata();

		uELBtnsimpanmain && (uELBtnsimpanmain.disabled=true)

		return () => {
			setDataloadoriObj({})
			setDatapaymentArr([])
			setDataCetakObj(null)
			setDataidentitas({})
			setDatasetting({})
			setFormdataObj({})
			setFormtabelObj({})
			setDataitemArr([])
			setDatasupplierArr([])
			clearInterval(vTimerrun);
			setDlglookupshow(false); setDlgviewshow(false); setIsDlgformshow(false);
		}
	},[])
	React.useEffect(()=>{
		setFormdataObj({...uDataloadoriObj,customer_id:uCustomerID});
	},[uDataloadoriObj])
	React.useEffect(()=>{
		(uFormdataObj.items_arr||[]).map(vItems => {
			if((parseInt(vItems.pihaklain_kasid)||0) > 0) {
				const vArridx = UFunc.getObjectIndeks(uDataKasGLArr,vItems.pihaklain_kasid,"value");
				vItems.pihaklain_kascaption = (uDataKasGLArr[vArridx]||{}).caption;
			}
		})
	},[uFormdataObj.items_arr,
	(uFormdataObj.items_arr||[]).length])
	React.useEffect(()=>{ prosesTotalnilai() },[uFormdataObj,uFormdataObj.items_arr])
	React.useEffect(()=>{ 
		let vElBtnsimpan	= uELBtnsimpanmain;
		if(!uISDlglookupshow) {
			setDlglookupObj({});
			if(!uIsDlgformshow)
				vElBtnsimpan && (vElBtnsimpan.disabled=false);
			return
		} 

		if(!uIsDlgformshow)
			vElBtnsimpan && (vElBtnsimpan.disabled=true)
	},[uISDlglookupshow])
	React.useEffect(()=>{
		//if((parseInt(uFormdataObj.nilai_bayar)||0) < 0) { uFormdataObj.nilai_bayar(uFormdataObj.nilai_netto); setEfekview(!uEfekview) }
		let vElBtnsimpan	= uELBtnsimpanmain;
		if(!uIsDlgformshow) { 
			vElBtnsimpan && (vElBtnsimpan.disabled=false);
			setDlgformObj({});
			return 
		}

		vElBtnsimpan && (vElBtnsimpan.disabled=true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			
			let vElsfocus;
			if(uDlgformObj.jenis === "MAIN")
				vElsfocus = document.getElementById("inpnilaibayar");
			else if(uDlgformObj.jenis === "TAMBAHTABEL")
				vElsfocus = document.getElementById("inpdlgformitemcaption");

			if(!isMobile) {
				vElsfocus && vElsfocus.focus();
			}
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uIsDlgviewshow) { setDataongoingObj(null); return }
	},[uIsDlgviewshow])
	React.useEffect(()=>{
		uFormdataObj.nilai_dppakai = uFormdataObj.customer_dp;
		setEfekview(!uEfekview)
	},[uFormdataObj,uFormdataObj.customer_dp])
	React.useEffect(()=>{
		if((parseFloat(uFormdataObj.nilai_diskon)||0) > 
			(parseFloat(uFormdataObj.nilai_bruto)||0) - (parseFloat(uFormdataObj.payment_biaya)||0)
		) {
			uFormdataObj.nilai_diskon = (parseFloat(uFormdataObj.nilai_bruto)||0) - (parseFloat(uFormdataObj.payment_biaya)||0);
			setEfekview(!uEfekview); return;
		}

		const vNilaitotal = (parseFloat(uFormdataObj.nilai_bruto)||0)
			+ (parseFloat(uFormdataObj.payment_biaya)||0)
			- (parseFloat(uFormdataObj.nilai_diskon)||0)
		//const vNilaiDP = (parseInt(uFormdataObj.nilai_dppakai)||0)

		//console.log("vNilainetto => "+vNilainetto)
		uFormdataObj.nilai_netto = vNilaitotal; setEfekview(!uEfekview)
	},[uFormdataObj,
	uFormdataObj.nilai_bruto,
	uFormdataObj.nilai_diskon,
	uFormdataObj.payment_biaya,
	uFormdataObj.nilai_dppakai
	])
	/*React.useEffect(()=>{//-TIDAK_DIPAKAI--/
		const vNilaiDP 		= parseInt(uFormdataObj.nilai_dppakai||0);
		const vNilainetto 	= parseInt(uFormdataObj.nilai_netto||0);
		uFormdataObj.nilai_bayar = vNilaiDP > vNilainetto ? 0 : vNilainetto - vNilaiDP;
		setEfekview(!uEfekview)
	},[uFormdataObj,uFormdataObj.nilai_netto])//-*/
	React.useEffect(()=>{
		const vSisa	= (parseInt(uFormdataObj.nilai_bayar)||0) 
			+ (parseInt(uFormdataObj.nilai_dppakai)||0) 
			- (parseInt(uFormdataObj.nilai_netto)||0)

		uFormdataObj.caption_sisa 	= (vSisa>=0 
			? uBahasaObj.caption_kembalian : uBahasaObj.caption_sisapiutang)
		uFormdataObj.nilai_sisa 	= (vSisa);
		setEfekview(!uEfekview);
	},[uFormdataObj,uFormdataObj.nilai_netto,uFormdataObj.nilai_bayar])
	React.useEffect(()=>{
		//console.log("(Kasirklinikmain - [uFormdataObj]) uFormdataObj => "+JSON.stringify(uFormdataObj))
		if((parseInt(uFormdataObj.payment_id)||0) > 0
			|| (parseFloat(uFormdataObj.payment_biaya)||0) > 0
			|| (parseFloat(uFormdataObj.nilai_diskon)||0) > 0
			|| (parseFloat(uFormdataObj.nilai_bayar)||0) > 0
			|| (uFormdataObj.payment_ket||"").trim() !== ""
			|| (uFormdataObj.catatan_customer||"").trim() !== ""
			|| (uFormdataObj.tgl_jt||"") !== ""
			|| (uFormdataObj.items_arr||[]).length !== (uDataloadoriObj.items_arr||[]).length
			) setFormupdated(true)
			else setFormupdated(false)
	},[uFormdataObj,
	uFormdataObj.payment_id,
	uFormdataObj.payment_ket,
	uFormdataObj.payment_biaya,
	uFormdataObj.catatan_customer,
	uFormdataObj.tgl_jt,
	uFormdataObj.nilai_bayar,
	uFormdataObj.nilai_diskon,
	(uFormdataObj.items_arr||[]).length,
	])
	React.useEffect(()=>{
		if(uDataCetakObj===null) return;
		setIsDlgformshow(false)

		//---CETAK_NOTA--/
		prosesCetaknota()
		//---END CETAK_NOTA--/

		uNavigate("/kasirklinik",{replace:true})
	},[uDataCetakObj])
	//---END EFFECT---/

	if(!uTokenObj) { setLoading(true); prosesExpired(); return ""; }
	if(uHTMLError500) return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() =>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
			<b>{uBahasaObj.menus_kasirklinik||"Kasir Klinik"}</b>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
			<CContainer xxl className="">
				<MyPage500 content={uHTMLError500} 
					isWithrefresh={true}
					hdlReset={()=>hdlKlikrefresh()}
					{...props}/>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				iconname="cilCheckAlt"
				centered={true}
				disabled={true}
				id="btnBayar">{uBahasaObj.word_bayar||"word_bayar"} [F9]</MyButton>
		</CFooter>
		</div>
	)//>

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() =>hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
			<b>{uBahasaObj.menus_kasirklinik||"Kasir Klinik"}</b>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<span className="d-none d-md-block classfontsmaller">
					<small>
					<span className="mx-1">&middot;</span>
					{UFunc.TglAngka()} {uRunningtime}
					<span className="mx-1">&middot;</span>
					<b>{(uFormdataObj.no_nota||"UNDF").toUpperCase()}</b>
					<span className="mx-1">&middot;</span>
					</small>
				</span>

				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer style={{maxHeight:52}} className="px-3 px-lg-5 py-2 border-top" fluid>
			{uIsLoadawal ? (
				<div className="mx-auto text-primary text-center classblinking">Loading...</div>
			) : (
			<>{contentHeadersub()}</>
			)}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
			<CContainer xxl className="p-0">
				<div className="d-md-none">{contentMobile()}</div>
				<div className="d-none d-md-block mb-5">{contentMain()}</div>
			</CContainer>
		</div>

		<CFooter position="fixed" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsLoadawal || uHTMLError500}
				onClick={()=>hdlKlikBayar()}
				className="d-none d-md-inline-block"
				id="btnBayar">{uBahasaObj.word_bayar||"word_bayar"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uDlglookupObj.headers||"Header Lookup"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]} 
			onKlikPilihItem={hdlKlikDLglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformObj.headers||"Form Dialog"}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none">
			<CImage src={uDatasetting.url_image_nota||uUrllogoDefault} id="idimglogo" />
		</iframe>
		</>
	)//>
}

export default Kasirklinikmain